import React from 'react';
import { InventoryItem } from '../../../utils/additionalTypes';
import GenericGridItem from '../../GenericItemGrid/GenericGridItem';
import {
  EQUIPMENT_ATTRIBUTES,
  GEAR_RARITY, ITEM_RARITY, ITEM_RARITY_COLOR_MAP, ITEM_TYPES,
} from '../../../utils/consts';

const getItemImageSrc = (item: InventoryItem) => {
  if (item.ItemImage.kind !== 'name') {
    return item.ItemImage.value;
  }

  if (item.ItemType === ITEM_TYPES.Crafting) {
    let quantityBasedVariation = 'small';

    if (item.ItemQuantity >= 35) {
      quantityBasedVariation = 'mid';
    }

    if (item.ItemQuantity >= 100) {
      quantityBasedVariation = 'big';
    }

    return `/inventoryItems/${item.ItemImage.value}_${quantityBasedVariation}.jpeg`;
  }

  return `/inventoryItems/${item.ItemImage.value}.jpeg`;
};

export default ({
  item,
  withGlow,
  isSelected,
  onClick,
}: {
    item: InventoryItem
    withGlow?: boolean
    isSelectable?: boolean
    isSelected?: boolean
    onClick?: () => void
}) => {
  const rarity = ITEM_RARITY[item.ItemName];
  const rarityColor = rarity ? ITEM_RARITY_COLOR_MAP[rarity] : 'white';

  const itemGearType = item.ItemGearType === EQUIPMENT_ATTRIBUTES.Weapon ?
    item.ItemWeaponType :
    item.ItemGearType;

  return (
    <GenericGridItem
      key={item.ItemID}
      title={item.ItemName}
      quantity={item.ItemQuantity > 1 ? item.ItemQuantity : null}
      tooltipContent={
        <div className="flex flex-col max-w-[13rem]">
          <span
            className="font-bold"
            style={{
              // eslint-disable-next-line no-nested-ternary
              color: item.ItemType === 'Equipment' ? rarityColor :
                item.ItemType === 'NFT' ? /* yellow-500 */ '#EAB308' :
                  'white',
            }}
          >{item.ItemName}</span>
          {itemGearType && <span className='text-sm'>{itemGearType}</span>}
          {rarity && rarity !== 'Unique' &&
            <span className='text-sm mt-2' style={{ color: 'darksalmon' }}>Item Score: {GEAR_RARITY[rarity]}</span>
          }
          {item.ItemDescription ? <div className="text-sm">{item.ItemDescription}</div> : null}
          {item.ItemClass && (<div>
            <span className="text-sm">Class: </span>
            <span className="text-sm">{item.ItemClass.toString()}</span>
          </div>)}
          {item.ItemType === ITEM_TYPES.NFT ?
            <div className="text-sm mt-2">
              This item will be delivered to you manually within 48 hours from the purchase time. Open Discord support ticket if you need further assistance.
            </div> :
            null}
        </div>
      }
      imgSrc={getItemImageSrc(item)}
      withGlow={withGlow}
      isSelected={isSelected}
      gradientWrapperAdditionalProps={{ // TODO added for all items, even within inventory outside Armory - good choice?
        shouldChangeBorderColorOnHover: true,
        shouldIncreaseScaleOnHover: isSelected,
        shouldDisplayPointerCursor: true,
        // eslint-disable-next-line no-nested-ternary
        className: typeof (isSelected) !== 'undefined' ? isSelected ? 'scale-110' : 'opacity-50 hover:opacity-100' : null,
        onClick,
      }}
    />
  );
};
