import React, { useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import MissionBoard from './MissionBoard';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../modals/ErrorModal';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';

export default () => {
  const availableMissions = useAppSelector((state) => state.missions.availableMissions);
  const activeMissions = useAppSelector((state) => state.missions.activeMissions);

  useEffect(() => {
    if (!availableMissions || !activeMissions) {
      modalService.pushModal(<ErrorModal error={new Error('empty missions!!')} />); // TODO better error handling
    }
  }, [availableMissions, activeMissions]);

  if (!availableMissions || !activeMissions) {
    return null;
  }

  return (
    <ScaleFadeInWrapper>
      <MissionBoard
        availableMissions={availableMissions}
        activeMissions={activeMissions}
      />
    </ScaleFadeInWrapper>
  );
};
