import React from 'react';
import OverflowWithMaskWrapper from '../OverflowWithMaskWrapper';

export default ({
  itemsToDisplay,
  extraClassname,
  minItemSizeInRem = 6,
  aspectRatio = '1/1',
  withGradientCutoff = true,
  isLoading,
  extraStickyElement,
}: {
    itemsToDisplay: Array<React.ReactNode>
    extraClassname?: string
    minItemSizeInRem?: number
    aspectRatio?: string
    withGradientCutoff?: boolean,
    isLoading?: boolean
    extraStickyElement?: React.ReactNode
    isSelected?: boolean
}) => {
  const shouldDisplayPagination = itemsToDisplay.length > 50;
  const shouldDisplayStickyBottomContainer = shouldDisplayPagination || extraStickyElement;

  return (
    <OverflowWithMaskWrapper
      extraClassname={extraClassname}
      gradientCutoffEnabled={withGradientCutoff}
      withExtraPadding={withGradientCutoff}
    >
      <div
        className={`grid ${isLoading && 'opacity-50 pointer-events-none'}`}
        style={{
          gridTemplateColumns: `repeat(auto-fill, minmax(${minItemSizeInRem}rem, 1fr))`,
          gap: '1rem',
        }}
      >
        {itemsToDisplay.map((itemToDisplay, index) => (
          <div
            key={index}
            className='flex justify-center items-center'
            style={{
              aspectRatio,
            }}
          >
            {itemToDisplay}
          </div>
        ))}
      </div>
      {shouldDisplayStickyBottomContainer && (
        <div className="sticky flex flex-col flex-grow items-center gap-4 justify-end bottom-5 pt-8 w-full z-10">
            {extraStickyElement}
            {/* to be re-enabled */}
            {/* {shouldDisplayPagination && <PaginationContainer />} */}
        </div>
      )}
    </OverflowWithMaskWrapper>
  );
};
