import React from 'react';
import GenericButton from '../../GenericButton';
import modalService from '../../../modalService/ModalService';
import EquipNewItemsModal from '../../modals/EquipNewItemsModal';

export default ({
  shouldDisplayNothingToEquipModal,
  resetBeraNewAttributes,
  equipNewAttributes,
}: {
    shouldDisplayNothingToEquipModal: boolean
    resetBeraNewAttributes: () => void
    equipNewAttributes: () => void
}) => (
  <>
    <GenericButton
      label="Discard changes"
      onClick={() => resetBeraNewAttributes()}
    />
    <GenericButton
      buttonStyle="gold"
      label="Equip items!"
      onClick={() => modalService.pushModal(<EquipNewItemsModal
        equipNewAttributes={equipNewAttributes}
        shouldDisplayNothingToEquipModal={shouldDisplayNothingToEquipModal}
      />)}
    />
  </>
);
