import React from 'react';
import { BeraNewAttributes } from './useBeraAttributes';
import { BeraAttributes, InventoryItem } from '../../../utils/additionalTypes';
import { EQUIPMENT_ATTRIBUTES } from '../../../utils/consts';
import externalItemData from '../../../utils/externalItemData';
import { getEquipmentPreviewUrl } from '../../../utils/endpointUrl';

export default ({
  newAttributes,
  originalAttributes,
  currentlyHoveredItem,
}: {
	newAttributes: BeraNewAttributes
	originalAttributes: BeraAttributes
	currentlyHoveredItem: InventoryItem | null
}) => (
  Object.values(EQUIPMENT_ATTRIBUTES).map((attributeToDisplay) => {
    let itemNameToDisplay;

    const overrideWithCurrentlyHovered = currentlyHoveredItem && attributeToDisplay === currentlyHoveredItem.ItemGearType;

    if (overrideWithCurrentlyHovered) {
      itemNameToDisplay = currentlyHoveredItem.ItemName;
    } else {
      itemNameToDisplay = newAttributes[attributeToDisplay]?.ItemName ?? originalAttributes[attributeToDisplay];
    }

    // eslint-disable-next-line no-unused-vars
    const [itemId] = Object.entries(externalItemData).find(([_, { Name }]) => Name === itemNameToDisplay) ?? [];

    if (!itemId) {
      return null;
    }

    return (
      <div
        key={itemId}
        className={`
            absolute top-0 right-0 bottom-0 left-0 bg-contain lg:bg-cover
            animate-beraEquipmentScaleFadeIn
        `}
        style={{
          backgroundImage: `url(${getEquipmentPreviewUrl(itemId)})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    );
  })
);
