import React from 'react';

import { Link } from 'react-router-dom';
import { useDisconnect } from 'wagmi';
import { uiSlice } from '../../store/slices/ui/uiSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import GenericButton from '../GenericButton';
import { accountSlice } from '../../store/slices/account/accountSlice';
import { CrossIconSvg } from '../SvgIcons';

export default () => {
  const address = useAppSelector((state) => state.account.walletAddress);
  const dispatch = useAppDispatch();
  const { disconnect } = useDisconnect({
    mutation: {
      onSuccess: () => {
        dispatch(accountSlice.actions.logOut());
      },
    },
  });

  return (
    <div className={`
      absolute left-0 top-0 right-0 bottom-0 
      overflow-hidden flex flex-col z-10 bg-mainBgLite
    `}
    >
      <div
        className="flex justify-between items-center px-4 py-2 bg-mainBg bg-opacity-50 w-full"
      >
        <div
          className="min-w-[12rem] h-[3rem] -translate-y-[3px]"
          style={{
            backgroundImage: 'url(\'/logo.png\')',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="aspect-square min-w-fit w-6 h-6 sm:hidden flex">
          <button onClick={() => dispatch(uiSlice.actions.hideMobileMenu())}>
            <CrossIconSvg />
          </button>
        </div>
      </div>
      <div className="flex flex-col h-full w-full justify-center items-center text-white gap-8">
        <div className="flex items-center text-xs bg-mainBg bg-opacity-80 p-4 h-8 rounded-2xl">{address}</div>
        <Link
          onClick={() => dispatch(uiSlice.actions.hideMobileMenu())}
          to="/welcome"
        >
          <GenericButton
            onClick={() => disconnect()}
            label="Logout"
          />
        </Link>
      </div>
    </div>
  );
};
