import React from 'react';
import { useParams } from 'react-router-dom';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import ItemsContainer from './ItemsContainer';
import TeamContainer from './TeamContainer';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';

export const tabs = {
  items: 'items',
  team: 'team',
  disenchanting: 'disenchanting',
};

export default () => {
  const { tab = tabs.items } = useParams();
  const isActiveTabTeam = tab === tabs.team;

  return (
    <ScaleFadeInWrapper>
      <GenericMainScreenContainer
        headerElement={
          <GenericMainScreenHeader
            tabs={[
              {
                label: 'Armory 🛡',
                goTo: '/inventory/team',
              },
              {
                label: 'Inventory 🗡',
                goTo: '/inventory/items',
              },
              {
                label: 'Disenchanting 💎',
                goTo: '/inventory/disenchanting',
              },
            ]}
          />
        }
      >
        {isActiveTabTeam ? <TeamContainer /> : <ItemsContainer />}
      </GenericMainScreenContainer>
    </ScaleFadeInWrapper>
  );
};
