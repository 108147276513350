import { createSlice } from '@reduxjs/toolkit';
import { uiReducers } from './uiReducers';

export type UIState = {
  hasShownSeasonPassModal: boolean
  shouldDisplayMobileMenu: boolean
  shouldPlayBgMusic: boolean
};

export const initialState = {
  hasShownSeasonPassModal: false,
  shouldDisplayMobileMenu: false,
  shouldPlayBgMusic: false,
} as UIState;

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: uiReducers,
});

export default uiSlice.reducer;
