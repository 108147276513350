import React from 'react';
import modalService from '../../../modalService/ModalService';
import MissionPurchaseModal from '../../modals/MissionPurchaseModal';
import { Mission } from '../../../utils/additionalTypes';
import { MISSION_REROLL_PRICES } from '../../../utils/consts';
import TooltipTrigger from '../../TooltipTrigger';
import { RerollIconSvg } from '../../SvgIcons';

export default ({
  mission,
  extraClassname,
}: {
  mission: Mission
  extraClassname?: string
}) => (
  <TooltipTrigger
    content={<div className="flex flex-row gap-2">
      <span className="break-keep">Reroll mission for {MISSION_REROLL_PRICES[mission.type]}</span>
      <img
        src="/gemSm.png"
        alt="Gems"
        className="mr-1 h-6 object-contain drop-shadow-gems"
      />
    </div>}
    extraClassname={`${extraClassname}`}
  >
    <div
      onClick={(e) => {
        e.stopPropagation();
        modalService.pushModal(<MissionPurchaseModal
          missionId={mission.id}
          missionType={mission.type}
        />);
      }}
      className="h-[40px] w-[40px]"
    >
      <RerollIconSvg />
    </div>
  </TooltipTrigger>
);
