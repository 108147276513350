import React from 'react';
import modalService from '../../modalService/ModalService';
import { useAppDispatch } from '../../store/hooks';
import { missionsExtraReducers } from '../../store/slices/missions/missionsReducers';
import { Mission } from '../../utils/additionalTypes';
import MissionTimer from '../MainScreen/MissionBoard/MissionTimer';
import GenericModalLayout from './GenericModalLayout';

export default ({
  mission,
}: {
  mission: Mission
}) => {
  const dispatch = useAppDispatch();

  return (
    <GenericModalLayout
      titleLabel="Retire squad?"
      onConfirm={() => dispatch(missionsExtraReducers.retireFromMission.reducer(mission.id))}
      onDecline={() => modalService.closeModal()}
    >
      <div className="flex flex-row gap-2 items-center">
        <div className="text-lg">All mission progress will be lost!</div>
      </div>
      {/* kinda dirty but works 🤷‍ */}
      <div className="hidden">
        <MissionTimer
          mission={mission}
          onFinishCallback={() => modalService.closeModal()}
        />
      </div>
    </GenericModalLayout>
  );
};
