import React from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';

export default () => (
  <GenericModalLayout
    titleLabel="Mission started!"
    titleRibbonStyle="green"
    onConfirm={() => modalService.closeModal()}
    onConfirmLabel="Continue"
  >
    <div className="text-lg text-center">{'Let\'s see what destiny awaits you, adventurer... good luck!'}</div>
  </GenericModalLayout>
);
