import { createSlice } from '@reduxjs/toolkit';
import { shopExtraReducers } from './shopReducers';
import parseExtraReducersCallbacks from '../../parseExtraReducersCallbacks';

import { ShopItem } from '../../../utils/additionalTypes';

export type ShopState = {
  shopItems: Array<ShopItem> | null
  isFetchingData: boolean
}

export const initialState = {
  shopItems: null,
  isFetchingData: false,
} as ShopState;

const shopReducers = {
  reset: () => initialState,
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: shopReducers,
  extraReducers: parseExtraReducersCallbacks(shopExtraReducers),
});

export default shopSlice.reducer;
