import Cookies from 'js-cookie';
import { TEST_USER_COOKIE } from './consts';
import { Mission } from './additionalTypes';

export default (mission: Mission) => {
  const isTestUser = Cookies.get(TEST_USER_COOKIE);

  const missionDuration = isTestUser ? Math.floor(mission.duration / 2000) : mission.duration;
  // eslint-disable-next-line no-underscore-dangle
  const missionEndTime = new Date((mission.startedTime._seconds + missionDuration) * 1000);

  return Math.ceil((missionEndTime.getTime() - Date.now()) / 1000);
};
