import React from 'react';
import { RenderModalOptions } from './ModalService';
import { CrossIconSvg } from '../components/SvgIcons';

const wrapperBgMap = {
  generic: 'bg-gradient-to-b from-gray-300/30 to-gray-600/30 before:border-gray-500',
  purple: 'bg-gradient-to-b from-violet-300/30 to-violet-600/30 before:border-violet-500',
  gold: 'bg-gradient-to-b from-yellow-300/30 to-yellow-600/30 before:border-yellow-500',
  orange: 'bg-gradient-to-b from-orange-300/30 to-orange-600/30 before:border-orange-500',
};

export default ({
  children,
  closeModal,
  shouldDisplayCloseButton = false,
  canClose = true,
  wrapperStyle = 'generic',
}: {
  children: React.ReactNode
  closeModal: () => void
} & RenderModalOptions) => (
  <div
    className="fixed top-0 right-0 bottom-0 left-0 z-10 flex flex-col justify-start items-center overflow-y-auto"
    style={{
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    }}
    {...(canClose && {
      onClick: closeModal,
    })}
  >
    <div
      key={crypto.randomUUID()}
      className={`
        relative max-w-full
        ${wrapperBgMap[wrapperStyle]} 
        rounded-md shadow-lg
        my-auto
        before:absolute
        before:left-2 before:top-2 before:right-2 before:bottom-2
        before:-z-10 before:rounded
        before:shadow-genericButton before:opacity-50 before:border before:border-[2px]
        before:bg-black before:bg-opacity-50
        
        text-white bg-opacity-70 backdrop-blur-md p-10 flex flex-col
        
        animate-scaleFadeIn
      `}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {(canClose && shouldDisplayCloseButton) && (
        <button
          className="absolute self-end -m-6 w-6 h-6 z-10"
          onClick={closeModal}
        >
          <CrossIconSvg />
        </button>
      )}
      {children}
    </div>
  </div>
);
