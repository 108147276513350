// const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default (timestamp: number) => {
  const parsedDate = new Date(timestamp * 1000);

  return `
    ${months[parsedDate.getMonth()]}
    ${parsedDate.getDate()}${{
  1: 'st', 2: 'nd', 3: 'rd', 21: 'st', 22: 'nd', 23: 'rd',
}[parsedDate.getDate()] || 'th'}
  `;
};
