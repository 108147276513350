import { Bera, Mission, MissionChallenge } from './additionalTypes';
import {
  MISSION_TYPE, RegularSpec, UniqueSpec,
} from './consts';
import { CounteredChallenges } from './getCounteredChallenges';

import isUniqueSpecType from './isUniqueSpecType';
import isRegularSpecType from './isRegularSpecType';

// NOTE: Keep in sync with backend; this was copied more or less as-is
type MissionType = keyof typeof MISSION_TYPE;
/**
 * Get the flat bonus to success rate for a mission type and a number of challenges fully countered.
 * @param missionType A mission type to get the flat bonus for
 * @param vulnerabilityCount How many vulnerabilities are fully countered
 * @returns Flat percentage bonus to success rate
 */
export function CounterFlatBonus(
  missionType: MissionType,
  vulnerabilityCount: 0 | 1 | 2 | 3 | 4,
): number {
  const BONUSES = {
    Quest: [25],
    EliteQuest: [0, 25],
    GroupQuest: [0, 5, 25],
    Dungeon: [0, 5, 10, 25],
    Raid: [0, 5, 10, 15, 25],
  } as const satisfies Record<MissionType, readonly number[]>;

  if (BONUSES[missionType].length <= vulnerabilityCount) {
    return BONUSES[missionType].slice(-1)[0];
  }
  return BONUSES[missionType][vulnerabilityCount]!;
}

/**
 * Calculate the chance of success for a mission based on the gear score and classes of the party.
 * @param mission A mission to calculate the chance of success for
 * @param gearScore Cumulative base gear score of the party
 * @param classes A list of classes in the party
 * @returns The chance of success for the mission (0-100)
 */
export function CalculateChanceOfSuccess(
  mission: {
    Challenges: MissionChallenge[];
    type: MissionType;
    successModifier: number;
  },
  gearScore: number,
  classes: Array<RegularSpec | UniqueSpec>,
): number {
  const fullCounters = CounteredChallenges(mission, classes);

  const vulnerabilityCount = fullCounters
    .map((idx) => mission.Challenges[idx].counters)
    // A fully countered double-challenge counts as 2 vulnerabilities
    .reduce((a, b) => a + b.length, 0);

  if (vulnerabilityCount > 4) {
    throw new Error('Unreachable: vulnerabilityCount > 4');
  }

  const flatBonus = CounterFlatBonus(
    mission.type,
    vulnerabilityCount as 0 | 1 | 2 | 3 | 4,
  );

  const chance = Math.round(flatBonus + gearScore * mission.successModifier);
  return Math.min(chance, 100);
}

export default ({
  mission,
  selectedBeras,
}: {
  mission: Mission;
  selectedBeras: Array<Bera>;
}): number => {
  const selectedBeraClasses = selectedBeras.map((bera) => {
    const value = bera.attributes['Spec'];
    if (!isUniqueSpecType(value) && !isRegularSpecType(value)) {
      throw new Error(`Invalid spec: ${value}`);
    }

    return value;
  });
  const selectedBeraTotalGs = selectedBeras.reduce(
    (acc, bera) => acc + bera.gearScore,
    0,
  );

  return CalculateChanceOfSuccess(
    mission,
    selectedBeraTotalGs,
    selectedBeraClasses,
  );
};
