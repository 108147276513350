import React from 'react';
import modalService from '../../modalService/ModalService';
import {
  MISSION_LABEL, MISSION_TYPE, MISSION_REROLL_PRICES,
} from '../../utils/consts';
import { useAppDispatch } from '../../store/hooks';
import { missionsExtraReducers } from '../../store/slices/missions/missionsReducers';
import GenericModalLayout from './GenericModalLayout';
import useGetMissionPrice from '../../utils/useGetMissionPrice';

export default ({
  missionType,
  missionId,
  isPurchase,
}: {
	missionType: keyof typeof MISSION_TYPE
    missionId?: string
    isPurchase?: boolean
}) => {
  const dispatch = useAppDispatch();

  const rerollCost = MISSION_REROLL_PRICES[missionType];
  const purchaseCost = useGetMissionPrice({
    missionType,
  });

  const onConfirm = () => {
    if (isPurchase) {
      dispatch(missionsExtraReducers.buyMission.reducer({
        missionType,
        cost: purchaseCost,
      }));
    } else {
      dispatch(missionsExtraReducers.refreshMission.reducer({
        missionId,
        cost: rerollCost,
      }));
    }
  };

  return (
    <GenericModalLayout
      titleLabel={isPurchase ? `Buy new ${MISSION_LABEL[missionType]} ?` : `Reroll ${MISSION_LABEL[missionType]} ?`}
      onConfirm={onConfirm}
      onDecline={() => modalService.closeModal()}
    >
      <div className="flex flex-row gap-2 items-center">
        <div className="text-lg">Cost: {isPurchase ? purchaseCost : rerollCost} gems</div>
        <img
          src="/gemSm.png"
          alt="Gems"
          className="h-6 object-contain drop-shadow-gems"
        />
      </div>
    </GenericModalLayout>
  );
};
