import React from 'react';
import GenericModalTitle, { RibbonStyle } from './GenericModalTitle';
import ConfirmDeclineButtonRow from './GenericConfirmDeclineButtonRow';
import GenericButton, { GenericButtonStyle } from '../GenericButton';

export default ({
  children,
  titleLabel,
  titleRibbonStyle,
  onConfirm,
  isConfirmDisabled,
  onConfirmLabel,
  onConfirmStyle,
  onDecline,
  extraClassname,
}: {
	children: React.ReactNode
	titleLabel: string
	titleRibbonStyle?: RibbonStyle
	onConfirm: () => void
	onConfirmLabel?: string
	isConfirmDisabled?: boolean
	onDecline?: () => void
	extraClassname?: string
	onConfirmStyle?: GenericButtonStyle
}) => (
  <div className="flex flex-col items-center h-full min-h-[12rem]">
    <GenericModalTitle
      label={titleLabel}
      ribbonStyle={titleRibbonStyle}
    />
    <div className={`
    	flex flex-col items-center w-full my-10
    	${extraClassname}
    `}
    >
      {children}
    </div>
    {onDecline ? (
      <ConfirmDeclineButtonRow
        onConfirm={onConfirm}
        onDecline={onDecline}
        isConfirmDisabled={isConfirmDisabled}
      />
    ) : (
      <GenericButton
        onClick={onConfirm}
        label={onConfirmLabel as string}
        buttonStyle={onConfirmStyle}
      />
    )}
  </div>
);
