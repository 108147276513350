// https://gist.github.com/SimeonGriggs/7071958b8a629faf9137734aec713a0c

import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

const findKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

const getDeviceConfig = (width) => {
  const screens = { // !!warning, naive approach, should be set from tailwind.config.js!!
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  };

  const bpSizes = Object.keys(screens).map((screenSize) => parseInt(screens[screenSize], 10));

  const bpShapes = bpSizes.map((size, index) => ({
    min: !index ? 0 : bpSizes[index - 1],
    max: size,
    key: findKeyByValue(screens, `${size}px`),
  }));

  let breakpoint = null;

  bpShapes.forEach((shape) => {
    if (!shape.min && width < shape.max) {
      breakpoint = shape.key;
    } else if (width >= shape.min && width < shape.max) {
      breakpoint = shape.key;
    } else if (!shape.max && width >= shape.max) {
      breakpoint = shape.key;
    }
  });

  return breakpoint;
};

const useTailwindBreakpoint = () => {
  let width = typeof window !== 'undefined' ? window.innerWidth : 0;

  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(width));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      width = typeof window !== 'undefined' ? window.innerWidth : 0;

      setBrkPnt(getDeviceConfig(width));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useTailwindBreakpoint;
