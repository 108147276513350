export const AUTH_COOKIE = 'auth';
export const TEST_USER_COOKIE = 'testUser';
export const LOG_OBJECT_COOKIE = 'logObject';
export const PLAY_BG_MUSIC_ON_INIT_COOKIE = 'bgMusicOnInit';

export const eventOpts: AddEventListenerOptions & EventListenerOptions = { passive: false };

export const seasonOneEndDate = 1917869600;

export const MISSION_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
} as const;

export const MISSION_TYPE = {
  Quest: 'Quest',
  EliteQuest: 'EliteQuest',
  GroupQuest: 'GroupQuest',
  Dungeon: 'Dungeon',
  Raid: 'Raid',
} as const;

export const MISSION_LABEL = {
  [MISSION_TYPE.Quest]: 'Quest',
  [MISSION_TYPE.EliteQuest]: 'Elite Quest',
  [MISSION_TYPE.GroupQuest]: 'Group Quest',
  [MISSION_TYPE.Dungeon]: 'Dungeon',
  [MISSION_TYPE.Raid]: 'Raid',
} as const;

export const BERA_NUMBER_REGEX = /Beramonium #(\d+)/g;

export const MISSION_CHALLENGES = {
  screechingHarpy: 'Screeching Harpy',
  terracottaTitans: 'Terracotta Titans',
  chainWarden: 'Chain Warden',
  thunderingBehemoth: 'Thundering Behemoth',
  arcaneTempest: 'Arcane Tempest',
  ironcladColossus: 'Ironclad Colossus',
  barricadeGuardian: 'Barricade Guardian',
  rockTitan: 'Rock Titan',
  infernoWielder: 'Inferno Wielder',
  frostLich: 'Frost Lich',
  whirlingReaver: 'Whirling Reaver',
  marauderChieftain: 'Marauder Chieftain',
  frenziedJuggernaut: 'Frenzied Juggernaut',
  plaguebringer: 'Plaguebringer',
  puppeteerSorcerer: 'Puppeteer Sorcerer',
  swarmingHiveQueen: 'Swarming Hive Queen',
  venomousStalker: 'Venomous Stalker',
  cursedWoundbringer: 'Cursed Woundbringer',
  arcaneBarrierGuardian: 'Arcane Barrier Guardian',
  enchantedArmorBehemoth: 'Enchanted Armor Behemoth',
  sandstormSentinel: 'Sandstorm Sentinel',
  arcaneBarrierWarden: 'Arcane Barrier Warden',
  shadowstalkerAssassin: 'Shadowstalker Assassin',
  labyrinthShadowbeast: 'Labyrinth Shadowbeast',
  deceptiveLabyrinthWeaver: 'Deceptive Labyrinth Weaver',
  skyborneTerror: 'Skyborne Terror',
  phantomLegionCommander: 'Phantom Legion Commander',
} as const;

export const MISSION_CHALLENGE_FALLBACK_BORDER_COLOR = '#a2b9b9';

export const LOOT_IMAGE_MAP = {
  'Small Lockbox': 'Loot_Pouch.png',
  'Bronze Casket': 'Bronze_Casket.png',
  'Golden Trove': 'Golden_Trove.png',
  'Grand Chest': 'Grand_Chest.png',
  'Enchanted Chest': 'Enchanted_Chest.png',
} as const;

export const MESSAGE_VALUES = {
  success: 'Success',
} as const;

export const MISSION_PURCHASE_PRICES = {
  Quest: [30, 35, 40, 45, 50, 55, 60, 65, 85, 95, 105, 115, 125],
  EliteQuest: [145, 165, 190, 210, 230, 250, 365, 430, 465],
  GroupQuest: [750, 1200, 1900, 2250],
  Dungeon: [3500, 6000, 7000],
  Raid: [28500, 35000, 40000],
} as const;

export const MISSION_REROLL_PRICES = {
  Quest: 2,
  EliteQuest: 10,
  GroupQuest: 65,
  Dungeon: 300,
  Raid: 1000,
};

export const ITEM_TYPES = {
  NFT: 'NFT',
  BeramoniumNFT: 'BeramoniumNFT',
  Collectible: 'Collectable',
  Equipment: 'Equipment',
  Crafting: 'Crafting',
} as const;

export const SEARCH_PARAMS = {
  filter: 'filter',
  sortBy: 'sortBy',
};

export const GEAR_RARITY_TYPES = {
  Common: 'Common',
  Rare: 'Rare',
  Epic: 'Epic',
  Legendary: 'Legendary',
};

export const GEAR_RARITY = {
  [GEAR_RARITY_TYPES.Common]: 1,
  [GEAR_RARITY_TYPES.Rare]: 3,
  [GEAR_RARITY_TYPES.Epic]: 5,
  [GEAR_RARITY_TYPES.Legendary]: 8,
} as const;

export const BERA_SPECS = {
  tank: 'Tank',
  berserker: 'Berserker',
  spellcaster: 'Spellcaster',
  rogue: 'Rogue',
  healer: 'Healer',
  marksman: 'Marksman',
  unique: 'Unique',
} as const;

export const WEAPON_TYPES = {
  Book: 'Book',
  Staff: 'Staff',
  '2HSword': '2HSword',
  'Sword&Shield': 'Sword&Shield',
  Bow: 'Bow',
  Dagger: 'Dagger',
};

export const WEAPON_SPECS_MAP = {
  [WEAPON_TYPES['Sword&Shield']]: BERA_SPECS.tank,
  [WEAPON_TYPES['2HSword']]: BERA_SPECS.berserker,
  [WEAPON_TYPES.Staff]: BERA_SPECS.healer,
  [WEAPON_TYPES.Book]: BERA_SPECS.spellcaster,
  [WEAPON_TYPES.Bow]: BERA_SPECS.marksman,
  [WEAPON_TYPES.Dagger]: BERA_SPECS.rogue,
};

export const NFT_COLLECTIONS_SEASON_1 = [
  'Yeet',
  'Bera Punk',
];

export type UniqueSpec = 'Unique';

export type RegularSpec = (typeof BERA_SPECS)[keyof typeof BERA_SPECS];

export const BERA_SPEC_COLOR_MAP = {
  [BERA_SPECS.tank]: '#c28436',
  [BERA_SPECS.berserker]: '#c40b2a',
  [BERA_SPECS.spellcaster]: '#56cae8',
  [BERA_SPECS.rogue]: '#d9cc2c',
  [BERA_SPECS.healer]: '#3b3bc9',
  [BERA_SPECS.marksman]: '#188a76',
  [BERA_SPECS.unique]: '#be69e5',
};

export const BERA_CLASS = {
  warrior: 'Warrior',
  mage: 'Mage',
  hunter: 'Hunter',
};

export const BERA_CLASS_ICON_MAP = {
  [BERA_CLASS.mage]: '/pngIcons/chaos.png',
  [BERA_CLASS.warrior]: '/pngIcons/hammer.png',
  [BERA_CLASS.hunter]: '/pngIcons/bow.png',
};

export const BERA_UNIQUE_CLASS_ICON = '/pngIcons/boss.png';

export const MISSION_CARD_BG_MAP = {
  [MISSION_TYPE.Quest]: '/questBg/questBgNew.jpg',
  [MISSION_TYPE.EliteQuest]: '/questBg/eliteQuestBgNew.jpg',
  [MISSION_TYPE.GroupQuest]: '/questBg/groupQuestBgNew.jpg',
  [MISSION_TYPE.Dungeon]: '/questBg/dungeonBgNew.jpg',
  [MISSION_TYPE.Raid]: '/questBg/raidBgNew.jpg',
};

export const ITEM_RARITY_COLOR_MAP = {
  Common: '#76E841',
  Rare: '#2E61E2',
  Epic: '#603aea',
  Legendary: '#FF5C00',
  Unique: '#be69e5',
};

export const BERA_ATTRIBUTES = {
  Class: 'Class',
  Background: 'Background',
  Aura: 'Aura',
  Body: 'Body',
  Expression: 'Expression',
  Pants: 'Pants',
  Boots: 'Boots',
  Gauntlets: 'Gauntlets',
  Chest: 'Chest',
  Helmet: 'Helmet',
  Weapon: 'Weapon',
  GearScore: 'Gear Score',
  Spec: 'Spec',
} as const;

export const EQUIPMENT_ATTRIBUTES = {
  [BERA_ATTRIBUTES.Helmet]: BERA_ATTRIBUTES.Helmet,
  [BERA_ATTRIBUTES.Pants]: BERA_ATTRIBUTES.Pants,
  [BERA_ATTRIBUTES.Chest]: BERA_ATTRIBUTES.Chest,
  [BERA_ATTRIBUTES.Gauntlets]: BERA_ATTRIBUTES.Gauntlets,
  [BERA_ATTRIBUTES.Boots]: BERA_ATTRIBUTES.Boots,
  [BERA_ATTRIBUTES.Weapon]: BERA_ATTRIBUTES.Weapon,
} as const;

export const DUST_TYPE = {
  'Green Dust': 'Green Dust',
  'Blue Dust': 'Blue Dust',
  'Purple Dust': 'Purple Dust',
  'Orange Dust': 'Orange Dust',
} as const;

export const ITEM_RARITY: { readonly [index: string]: 'Common' | 'Rare' | 'Epic' | 'Legendary' | 'Unique' | undefined } = {
  'Small Staff': 'Common',
  'Slaughtered Dragon Pants': 'Legendary',
  "Purple Monk's Chest": 'Rare',
  'Old Mage Boots': 'Common',
  'Battle Chest': 'Rare',
  'Black Leather Helm': 'Common',
  'Helm of Harmony': 'Epic',
  'Book of Energy': 'Common',
  'Viper Poison Pants': 'Rare',
  'Training Daggers': 'Common',
  'Bearfang Boots': 'Common',
  'Crying Elements Boots': 'Rare',
  'Stalker Pants': 'Rare',
  'Crying Elements Chest': 'Rare',
  'Incomplete Steel Pants': 'Common',
  'Seli Lusha': 'Epic',
  'Shadow Rise Boots': 'Epic',
  'Shadow Rise Gloves': 'Epic',
  'Shadow Rise Hat': 'Epic',
  Abominio: 'Epic',
  'Elementalist Boots': 'Rare',
  'Bow of the Fakeglass': 'Epic',
  'Bronze and Iron Chest': 'Common',
  'Wild Hunt Head': 'Rare',
  'Fashion Leather Chest': 'Common',
  'Apprentice Chest': 'Common',
  'Abyss Boots': 'Epic',
  'Silent Armor Chest': 'Rare',
  'Novice Staff': 'Common',
  'Poisoned Bow': 'Rare',
  'Stalker Hat': 'Rare',
  'Wild Hunt Boots': 'Rare',
  'Black Leather Gloves': 'Common',
  'Dawn of Justice': 'Epic',
  'Abyss Gauntlets': 'Epic',
  'Elementalist Chest': 'Rare',
  'Dragonrider Chest': 'Epic',
  'Pants of Harmony': 'Epic',
  'Slaughtered Dragon Chest': 'Legendary',
  'Old Mage Gloves': 'Common',
  'Wild Hunt Gauntlets': 'Rare',
  'Boots of Coraline Lion': 'Legendary',
  'Mystic Chest': 'Common',
  'Viper Poison Helm': 'Rare',
  'Bearfang Gloves': 'Common',
  Hologram: 'Unique',
  'Zelemor the Golden Necromancer': 'Unique',
  "Purple Monk's Pants": 'Rare',
  'Viper Poison Chest': 'Rare',
  'Knee Arrow Boots': 'Common',
  'Fashion Pants': 'Common',
  'Arcane Boots': 'Common',
  'Knee Arrow Forearms': 'Common',
  'Silent Boots': 'Rare',
  'Elementalist Pants': 'Rare',
  'Battle Helmet': 'Rare',
  'Blade of the Ancient': 'Epic',
  'Tooth Eye': 'Epic',
  'Gloves of Agony': 'Epic',
  'Battle Pants': 'Rare',
  'Abyss Chest': 'Epic',
  'Old Mage Pants': 'Common',
  'Grace of the Wonder': 'Epic',
  'Chest of Harmony': 'Epic',
  // TODO: Remove once OS cache propagates
  Commando: 'Rare',
  'Commando Knife': 'Rare',
  'Commando Hacksaw': 'Rare',
  'Arcane Gloves': 'Common',
  "Angel Protector's Staff": 'Rare',
  Bloodtaste: 'Epic',
  'Abyss Pants': 'Epic',
  'Iron Golem Helm': 'Rare',
  'Dragonrider Boots': 'Epic',
  'The Dark Knight': 'Unique',
  'Incomplete Chest': 'Common',
  'Blood Whisperer': 'Rare',
  "Purple Monk's Boots": 'Rare',
  'Mystic Pants': 'Common',
  'Silent Pants': 'Rare',
  'Crying Elements Helm': 'Rare',
  "Alastor's Gift": 'Epic',
  'Black Leather Chest': 'Common',
  'Battle Gauntlets': 'Rare',
  'Deep Bed Magma Boots': 'Epic',
  'Ikarus the Golden Paladin': 'Unique',
  'Olgrem of Thunders': 'Unique',
  'Knee Arrow Pants': 'Common',
  'Wooden Helmet': 'Common',
  'Deep Bed Magma Gloves': 'Epic',
  'Kishant the Golden Druid': 'Unique',
  'Knee Arrow Helmet': 'Common',
  'Black Leather Pants': 'Common',
  'Bearfang Helm': 'Common',
  'Stolen Blade': 'Common',
  'Seven Hit': 'Rare',
  'Stalker Gloves': 'Rare',
  'Healing Globe Staff': 'Common',
  "Purple Monk's Gloves": 'Rare',
  'Deep Bed Magma Chest': 'Epic',
  'Incomplete Gaunlets': 'Common',
  'Slaughtered Dragon Boots': 'Legendary',
  Erethical: 'Legendary',
  'Crying Elements Pants': 'Rare',
  'Apprentice Gloves': 'Common',
  'Gauntlets of Coraline Lion': 'Legendary',
  Powerchord: 'Common',
  'Apprentice Pants': 'Common',
  'Old Book': 'Common',
  "Book of Magic's Secrets": 'Rare',
  'Arcane Helm': 'Common',
  'Book of Elements': 'Common',
  'Knee Arrow Chest': 'Common',
  Warhammer: 'Common',
  Eyebow: 'Rare',
  'Ornamental Bow': 'Rare',
  Sharpened: 'Rare',
  'Deep Bed Magma Hat': 'Epic',
  'Slaughtered Dragon Helm': 'Legendary',
  'Arcane Chest': 'Common',
  'Lightspirit Pants': 'Legendary',
  'Pants of Coraline Lion': 'Legendary',
  'Waiku the Golden Fighter': 'Unique',
  'Old Mage Chest': 'Common',
  'Mystic Hat': 'Common',
  'Rough Steel': 'Common',
  'Hundred Pages': 'Rare',
  'Training Set': 'Common',
  'Old Mage Hat': 'Common',
  'Iron Golem Gauntlets': 'Rare',
  Necronomicon: 'Epic',
  'Missing Parts Armguards': 'Common',
  'Iron Golem Chest': 'Rare',
  'Dragonrider Pants': 'Epic',
  'Bearfang Pants': 'Common',
  Battleaxe: 'Common',
  'Shadow Rise Pants': 'Epic',
  'Black Leather Boots': 'Common',
  'Missing Parts Boots': 'Common',
  'Pants of Agony': 'Epic',
  'Decline of the Order': 'Legendary',
  'Outlined Cover': 'Rare',
  'Death Note': 'Epic',
  'Lightspirit Helm': 'Legendary',
  'Wooden Gauntlets': 'Common',
  'Silent Helmet': 'Rare',
  'Chest of Agony': 'Epic',
  'Deep Bed Magma Pants': 'Epic',
  'The Void Mage': 'Unique',
  'Elementalist Tiara': 'Rare',
  'Blacksmith Warhammer': 'Rare',
  'Nerol the Golden Sorcerer': 'Unique',
  'Helm of Agony': 'Epic',
  'Crying Elements Gloves': 'Rare',
  'Sword of the Caller': 'Rare',
  'Iron Golem Boots': 'Rare',
  'Helmet of Coraline Lion': 'Legendary',
  'Incomplete Boots': 'Common',
  'Missing Parts Chest': 'Common',
  "Soldier's Set": 'Common',
  'Wooden Set': 'Common',
  'Missing Parts Pants': 'Common',
  'Black Dagger Murder': 'Epic',
  'Stalker Chest': 'Rare',
  'Abyss Helmet': 'Epic',
  'Elementalist Gloves': 'Rare',
  'Viper Poison Boots': 'Rare',
  'Black Iron Set': 'Rare',
  'Mystic Gloves': 'Common',
  'Bronze and Iron Boots': 'Common',
  'Bronze and Iron Gauntlets': 'Common',
  'Daggers of Eternal Lament': 'Legendary',
  'Fashion Leather Hat': 'Common',
  'Ulhal the Golden Rogue': 'Unique',
  'Divine Staff': 'Rare',
  'Lightspirit Gauntlet': 'Legendary',
  'Wooden Bow': 'Common',
  'Hunter Bow': 'Common',
  'Stalker Boots': 'Rare',
  'Fashion Boots': 'Common',
  'Incomplete Helmet': 'Common',
  'Wild Hunt Pants': 'Rare',
  'Wooden Staff': 'Common',
  "Purple Monk's Hat": 'Rare',
  'Lightspirit Boots': 'Legendary',
  'Lights of Total Amnesty': 'Legendary',
  'Mystic Boots': 'Common',
  'Slaughtered Dragon Gloves': 'Legendary',
  'Bronze and Iron Helmet': 'Common',
  'Bronze and Iron Pants': 'Common',
  'Novice Book': 'Common',
  'Good Healer Staff': 'Rare',
  'Iron Golem Pants': 'Rare',
  'Poison Daggers': 'Common',
  'Silent Gloves': 'Rare',
  'Shadow Rise Chest': 'Epic',
  'Boots of Harmony': 'Epic',
  'Gloves of Harmony': 'Epic',
  'Bow of the Poison God': 'Legendary',
  'Lightspirit Chest': 'Legendary',
  'Green Black Blades': 'Rare',
  'Infinite Harmony': 'Legendary',
  'The Holy Dark Spirit': 'Unique',
  "Villager's Blade": 'Common',
  'Bronze Daggers': 'Common',
  'Apprentice Boots': 'Common',
  'Arcane Pants': 'Common',
  'Apprentice Helm': 'Common',
  'Broken Iron': 'Common',
  'Viper Poison Gloves': 'Rare',
  'Pants of the Wood': 'Common',
  'Dragonrider Gauntlest': 'Epic',
  'Wild Hunt Chest': 'Rare',
  'The Holy Light Spirit': 'Unique',
  'Fashion Leather Armguards': 'Common',
  'Battle Boots': 'Rare',
  'Chest of Coraline Lion': 'Legendary',
  'Wooden Chest': 'Common',
  'Killi Rio the Golden Archer': 'Unique',
  'Training Bow': 'Common',
  'Wooden Halfshoes': 'Common',
  'Boots of Agony': 'Epic',
  'Dragonrider Helmet': 'Epic',
  'Bearfang Chest': 'Common',
  'Missing Parts Bandana': 'Common',
} as const;
