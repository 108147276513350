import React from 'react';
import ShopGridItem from './ShopGridItem';
import GenericGridItem from '../../GenericItemGrid/GenericGridItem';
import { useAppSelector } from '../../../store/hooks';
import { ShopItem } from '../../../utils/additionalTypes';

export default () => {
  const shopItems = useAppSelector((state) => state.shop.shopItems) as Array<ShopItem>;

  const shopItemMainPrize = shopItems.filter((shopItem) => shopItem.IsMainShopPrize)[0];

  if (!shopItemMainPrize) {
    return (
      <div className="relative w-40 h-40">
        <div className={`
          absolute border border-[2px] -left-4 -rotate-[30deg] border-red-500 text-red-500 rounded font-title text-2xl px-3 bg-white z-10
          shadow-md
          pointer-events-none
        `}
        >SOLD!</div>
        <GenericGridItem
          title="BeraTone Founder's Sailcloth #557"
          tooltipContent={
            <div className="flex flex-col max-w-[12rem]">
              <span className="font-bold">{'BeraTone Founder\'s Sailcloth #557'}</span>
              <span className="text-sm">BeraTone Sails. We just wanna sail. Ahoy!</span>
            </div>
         }
          imgSrc="https://i.seadn.io/s/raw/files/cca8cd090aaa175c3beab570185bf44d.gif?auto=format&dpr=1&w=200"
        />
      </div>
    );
  }

  return (
    <div className="relative w-40 h-40">
      <ShopGridItem
        shopItem={shopItemMainPrize}
      />
    </div>
  );
};
