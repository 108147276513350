import React from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';

// eslint-disable-next-line react/prop-types
export default ({
  message,
}: {
	message: string
}) => (
  <GenericModalLayout
    titleLabel="Notification"
    onConfirm={() => modalService.closeModal()}
    onConfirmLabel="Close"
  >
    <div className="text-center max-w-[30rem]">{message}</div>
  </GenericModalLayout>
);
