import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default ({
  tabs,
  goBackTo,
  goBackToLabel = 'Go back',
  goBackToOnClick,
}: {
	goBackTo?: string | null
	goBackToLabel?: string
	goBackToOnClick?: () => void
    tabs: Array<{
        label: string
        goTo?: string
        onClick?: () => void
    }>
}) => {
  const { pathname } = useLocation();

  return (
    <div className="flex flex-row items-end">
      <div
        className={` 
        relative w-2 sm:w-4 h-[8px] translate-y-[6px] min-w-[.5rem] sm:min-w-[1rem] 
        
        before:absolute before:w-full before:h-full 
        before:left-0 before:top-0 
        before:border-t-2 before:rounded-tl-lg before:border-mainBg before:border-opacity-60 
        
        after:absolute after:w-full after:h-full 
        after:left-0 after:top-0 
        after:border-t-2 after:rounded-tl-lg after:border-white after:border-opacity-20 
      `}
      />
      {goBackTo ? (
        <Link to={goBackTo}>
          <button
            onClick={goBackToOnClick}
            className={` 
            font-title sm:text-lg text-white font-bold bg-mainBg 
            border border-2 border-white border-b-white border-opacity-20 rounded-t-lg bg-opacity-60 
            px-2 
            min-w-fit w-max 
          `}
          >
            <div className="translate-y-0.5 border-2 border-transparent sm:hover:drop-shadow-inheritColorExtraSmall">
              {goBackToLabel}
            </div>
          </button>
        </Link>
      ) : <div /> }
      <div className={`
        ${goBackTo ? 'min-w-[.25rem]' : 'min-w-0'}
        sm:min-w-[.5rem] flex-1 h-[2px] bg-mainBg border-b-2 border-white border-opacity-20 bg-opacity-60
      `}
      />
      {tabs.map(({
        label,
        goTo,
        onClick,
      }, index) => (
        <div
          className="flex items-end min-w-0"
          key={label}
        >
          {index > 0 && (
            <div className="w-1 sm:w-3 min-w-[.25rem] sm:min-w-[.75rem] h-[2px] border-b-2 border-white border-opacity-20 bg-opacity-60 bg-mainBg"/>
          )}
          <Link
            className={`flex flex-col overflow-x-hidden group translate-y-[4px] ${!goTo && 'cursor-auto'}`}
            to={goTo as string}
            onClick={onClick}
          >
            <div className="h-[8px] border-l-2 border-r-2 border-t-2 rounded-t-lg border-white border-opacity-20 bg-opacity-60 bg-mainBg"/>
            <div className={` 
                font-title text-lg ${tabs.length > 2 ? 'sm:text-xl' : 'sm:text-2xl'} text-white font-bold 
                flex-grow 
                border-l-2 border-r-2 border-white border-opacity-20 truncate ...
                bg-opacity-60 bg-mainBg
                px-2 pt-1
              `}
            >
              {label}
            </div>
            <div className={`
                h-[4px] border-opacity-20 mx-4
                ${goTo && 'group-hover:border-b-2 group-hover:border-b-white/50'}
                ${goTo && pathname.includes(goTo.split('/')[goTo.split('/').length - 1]) && 'border-b-2 group-hover:border-b-white'}
              `}
            />
          </Link>
        </div>
      ))}
      <div
        className={`
            relative w-2 sm:w-4 h-[8px] translate-y-[6px] min-w-[.5rem] sm:min-w-[1rem]
    
            before:absolute before:w-full before:h-full
            before:left-0 before:top-0
            before:border-t-2 before:rounded-tr-lg before:border-mainBg before:border-opacity-60
    
            after:absolute after:w-full after:h-full
            after:left-0 after:top-0
            after:border-t-2 after:rounded-tr-lg after:border-white after:border-opacity-20
          `}
      />
    </div>
  );
};
