import React from 'react';
import {
  BERA_SPEC_COLOR_MAP,
  EQUIPMENT_ATTRIBUTES, GEAR_RARITY, ITEM_RARITY_COLOR_MAP, WEAPON_SPECS_MAP,
} from '../../../utils/consts';
import { BeraAttributes, InventoryItem } from '../../../utils/additionalTypes';
import { BeraNewAttributes } from './useBeraAttributes';
import externalItemData from '../../../utils/externalItemData';
import HorizontalDivider from '../../HorizontalDivider';

export default ({
  getBeraOriginalAttributes,
  getBeraNewAttributes,
  currentlyHoveredItem,
}: {
  getBeraOriginalAttributes: () => BeraAttributes
  getBeraNewAttributes: () => BeraNewAttributes
  currentlyHoveredItem: InventoryItem | null
}) => {
  const originalAttributes = getBeraOriginalAttributes();
  const newAttributes = getBeraNewAttributes();

  return (
    <div className={`
      flex flex-col items-start gap-2 rounded-xl lg:rounded-l-none lg:rounded-tr-3xl lg:rounded-br-3xl 
      bg-gradient-to-l from-mainBgLite to-mainBg
      lg:border-r-2 border-armoryDetailBorder
      flex-shrink-0 lg:w-[16rem] py-4 lg:p-0 mb-4 lg:m-0
    `}
    >
      <span className="px-4 pb-2 lg:p-8 lg:pb-4 text-3xl text-white whitespace-nowrap">Stats</span>
      {Object.values(EQUIPMENT_ATTRIBUTES).map((attributeToDisplay) => {
        const shouldDisplayCurrentlyHoveredItem = currentlyHoveredItem && attributeToDisplay === currentlyHoveredItem.ItemGearType;

        // eslint-disable-next-line no-unused-vars
        const { newItemValue, baseItemValue } = Object.entries(externalItemData).reduce((acc, [_, itemValue]) => {
          if (itemValue.Name === (shouldDisplayCurrentlyHoveredItem ?
            currentlyHoveredItem?.ItemName :
            newAttributes[attributeToDisplay]?.ItemName
          )) {
            return {
              ...acc,
              newItemValue: itemValue,
            };
          }

          if (itemValue.Name === originalAttributes[attributeToDisplay]) {
            return {
              ...acc,
              baseItemValue: itemValue,
            };
          }

          return acc;
        }, {} as {
          newItemValue: {Name: string, Rarity: string, WeaponType?: string}
          baseItemValue: {Name: string, Rarity: string, WeaponType?: string}
        });

        // eslint-disable-next-line no-unreachable
        const { Name, Rarity, WeaponType } = newItemValue ?? baseItemValue;

        const specByWeaponAssignment = WEAPON_SPECS_MAP[WeaponType as keyof typeof WEAPON_SPECS_MAP];
        const originalSpec = originalAttributes.Spec;
        const shouldDisplayParameterOverride = shouldDisplayCurrentlyHoveredItem || newAttributes[attributeToDisplay];
        const currentGearScore = GEAR_RARITY[Rarity as keyof typeof GEAR_RARITY];
        // baseItemValue will be empty if these are the same
        const baseGearScore = GEAR_RARITY[(baseItemValue ?? newItemValue).Rarity as keyof typeof GEAR_RARITY];

        const isNewGearScoreDifferent = currentGearScore !== baseGearScore;
        const isNewGearScoreBetter = currentGearScore > baseGearScore;

        return (
          <div
            key={attributeToDisplay}
            className="relative flex flex-col pl-4 pr-8 lg:px-8 w-full gap-2 min-h-[5rem]"
          >
            <HorizontalDivider
              variantSmall
              label={attributeToDisplay}
            />
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between">
                <div className="text-xs text-white">{shouldDisplayParameterOverride ?
                  '⚡️New:' : 'Base:'
                }</div>
                <div className="text-xs text-white break-keep">Item Score:</div>
              </div>
              <div className="flex flex-row justify-between">
                <div
                  className="font-bold text-sm flex-grow-0 max-w-[10rem] truncate ..."
                  style={{
                    color: ITEM_RARITY_COLOR_MAP[Rarity as keyof typeof ITEM_RARITY_COLOR_MAP],
                  }}
                >{Name}</div>
                <div
                  className="relative flex font-bold text-sm text-white text-right items-center mr-1"
                >
                  {shouldDisplayParameterOverride && (
                  <div className="absolute -left-3.5 -bottom-[.4rem] flex flex-col items-center">
                    <div className="text-[.8rem] leading-[.7rem]">{baseGearScore}</div>
                    <div className="text-[.8rem] leading-[1rem] -translate-x-0.5">↪</div>
                  </div>
                  )}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  <div className={`font-bold ${isNewGearScoreDifferent ? isNewGearScoreBetter ? 'text-green-500' : 'text-rose-500' : 'text-white'}`}>{currentGearScore}</div>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {shouldDisplayParameterOverride && isNewGearScoreDifferent ? isNewGearScoreBetter ?
                    <div className="absolute -right-4 text-green-500">⬆</div> :
                    <div className="absolute -right-4 text-rose-500">⬇</div> :
                    <div
                      className="absolute -right-4 w-3 h-3"
                      style={{
                        backgroundImage: 'url(\'/pngIcons/crossedSwords.png\')',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  }
                </div>
              </div>
              {attributeToDisplay === EQUIPMENT_ATTRIBUTES.Weapon ? (
                <>
                  <div className="text-xs text-white">{shouldDisplayParameterOverride && originalSpec !== specByWeaponAssignment ?
                    '⚡️New spec assignment:' : 'Base spec assignment:'
                  }</div>
                  <div className="flex items-center gap-1.5">
                    {shouldDisplayParameterOverride && originalSpec !== specByWeaponAssignment && (
                    <>
                      <div
                        className="w-3 h-3 aspect-square rounded-full border-[1px] border-gray-600"
                        style={{
                          backgroundColor: BERA_SPEC_COLOR_MAP[originalSpec as keyof typeof BERA_SPEC_COLOR_MAP],
                        }}
                      />
                      <div className="text-white text-sm">➡</div>
                    </>
                    )}
                    <div className="text-white font-bold text-sm flex-grow-0 max-w-[9rem] truncate ...">{specByWeaponAssignment}</div>
                    <div
                      className="w-3 h-3 aspect-square rounded-full border-[1px] border-gray-600"
                      style={{
                        backgroundColor: BERA_SPEC_COLOR_MAP[specByWeaponAssignment],
                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};
