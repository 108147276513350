import React from 'react';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import GenericSerchParamsTabs from '../../GenericSerchParamsTabsWithSort';
import {
  BERA_ATTRIBUTES,
  EQUIPMENT_ATTRIBUTES,
} from '../../../utils/consts';
import PerClassInventoryItemsGridContainer from './PerClassInventoryItemsGridContainer';
import BeraPreviewContainer from './BeraPreviewContainer';
import useBeraAttributes from './useBeraAttributes';
import EquipmentStats from './EquipmentStats';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';

export default () => {
  const {
    getBeraOriginalAttributes,
    getBeraNewAttributes,
    getBeraId,
    setCurrentlyHoveredItem,
    currentlyHoveredItem,
    setBeraNewAttribute,
    resetBeraNewAttributes,
    equipNewAttributes,
    shouldRender,
  } = useBeraAttributes();

  if (!shouldRender) {
    return null;
  }

  return (
    <ScaleFadeInWrapper>
      <GenericMainScreenContainer
        headerElement={<GenericMainScreenHeader
          goBackToLabel="Go back"
          goBackTo="/inventory/team?sortBy=gearScore.descending"
          tabs={[{
            label: 'ARMORY',
          }]}
        />}
        extraClassName="lg:!flex-row"
      >
        <BeraPreviewContainer
          getBeraOriginalAttributes={getBeraOriginalAttributes}
          getBeraNewAttributes={getBeraNewAttributes}
          getBeraId={getBeraId}
          currentlyHoveredItem={currentlyHoveredItem}
          setBeraNewAttribute={setBeraNewAttribute}
          resetBeraNewAttributes={resetBeraNewAttributes}
          equipNewAttributes={equipNewAttributes}
        />
        <EquipmentStats
          getBeraOriginalAttributes={getBeraOriginalAttributes}
          getBeraNewAttributes={getBeraNewAttributes}
          currentlyHoveredItem={currentlyHoveredItem}
        />
        <div className="flex flex-col lg:basis-2/5">
          <span className="px-4 lg:p-8 lg:pb-0 text-3xl text-white whitespace-nowrap">Equipment</span>
          <GenericSerchParamsTabs
            extraClassname="mt-4 lg:px-4"
            tabsToDisplay={Object.values(EQUIPMENT_ATTRIBUTES).map(((equipmentAttribute) => ({
              label: equipmentAttribute,
              searchParam: equipmentAttribute.toLowerCase(),
            })))}
            sortByTabsToDisplay={[
              {
                label: 'Rarity',
                searchParam: 'gearScore',
              },
            ]}
          />
          <PerClassInventoryItemsGridContainer
            beraClass={getBeraOriginalAttributes()[BERA_ATTRIBUTES.Class]}
            withOverflowVisibleOnMobile
            onInventoryItemHover={setCurrentlyHoveredItem}
            onInventoryItemClick={(itemToEquip) => {
              setBeraNewAttribute({
                type: itemToEquip.ItemGearType,
                value: itemToEquip,
              });
            }}
            getBeraNewAttributes={getBeraNewAttributes}
          />
        </div>
      </GenericMainScreenContainer>
    </ScaleFadeInWrapper>
  );
};
