import { createSlice } from '@reduxjs/toolkit';
import { Bera } from '../../../utils/additionalTypes';
import { berasReducers, berasExtraReducers } from './berasReducers';
import parseExtraReducersCallbacks from '../../parseExtraReducersCallbacks';

export type BerasState = {
  ownedBeras: Bera[] | null
  berasInQuest: Array<string> | null
};

export const initialState = {
  ownedBeras: null,
  berasInQuest: null,
} as BerasState;

export const berasSlice = createSlice({
  name: 'beras',
  initialState,
  reducers: berasReducers,
  extraReducers: parseExtraReducersCallbacks(berasExtraReducers),
});

export default berasSlice.reducer;
