import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BerasToDisplay, Bera,
} from '../../../utils/additionalTypes';
import GenericItemGrid from '../../GenericItemGrid/GenericItemGrid';
import BeraCardBig from '../MissionBriefing/BeraCardBig';
import { BERA_SPECS } from '../../../utils/consts';
import GenericSerchParamsTabs from '../../GenericSerchParamsTabsWithSort';
import filterArrayBySearchParams from '../../../utils/filterArrayBySearchParams';
import sortArrayBySearchParams from '../../../utils/sortArrayBySearchParams';
import EmptyBerasOpenseaTip from '../MissionBriefing/EmptyBerasOpenseaTip';
import { useAppSelector } from '../../../store/hooks';

export default () => {
  const navigate = useNavigate();

  const ownedBeras = useAppSelector<Array<Bera> | null>((state) => state.beras.ownedBeras);
  const berasInQuest = useAppSelector((state) => state.beras.berasInQuest);

  if (!ownedBeras || !berasInQuest) {
    return null;
  }

  const berasToDisplay: BerasToDisplay = ownedBeras.reduce((acc, bera) => [
    ...acc,
    {
      ...bera,
      inMission: berasInQuest.includes(bera.id),
    },
  ], [] as BerasToDisplay);

  const filteredBerasToSort = filterArrayBySearchParams<BerasToDisplay>(berasToDisplay, {
    filterBy: ['attributes.Spec'],
  });

  const sortedBerasToSort = sortArrayBySearchParams<BerasToDisplay>(filteredBerasToSort, {
    sortBy: 'gearScore',
  });

  const availableBerasFirstToDisplay = sortedBerasToSort.sort((a, b) => {
    if (a.inMission && !b.inMission) {
      return 1;
    } if (!a.inMission && b.inMission) {
      return -1;
    }
    return 0;
  });

  const shouldDisplayEmptyBerasInfo = !availableBerasFirstToDisplay.length;

  return (
    <>
      <GenericSerchParamsTabs
        extraClassname="mt-4 lg:px-4"
        tabsToDisplay={[ // TODO move these to external definition?
          ...Object.entries(BERA_SPECS).map(([key, value]) => (
            {
              label: value,
              searchParam: key,
            }
          )),
        ]}
        sortByTabsToDisplay={[
          {
            label: 'Gear score',
            searchParam: 'gearScore',
          },
        ]}
      />
      {shouldDisplayEmptyBerasInfo ? (
        <EmptyBerasOpenseaTip />
      ) : (
        <GenericItemGrid
          extraClassname="mt-2 lg:mb-8 px-4 lg:px-8"
          aspectRatio={'1/1.5'}
          minItemSizeInRem={9.5}
          withGradientCutoff
          itemsToDisplay={availableBerasFirstToDisplay.map((bera) => {
            const isUnique = bera.attributes.Spec === 'Unique';
            const isInMission = bera.inMission;

            return (
              <BeraCardBig
                key={bera.id}
                bera={{
                  ...bera,
                  isUnique,
                }}
                {...(!isUnique && !isInMission && {
                  additionalProps: {
                    onClick: () => navigate(`/armory/${bera.id}/?sortBy=gearScore.descending`),
                  },
                })}
              />
            );
          })}
        />
      )}
    </>
  );
};
