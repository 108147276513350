import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from './consts';

export const matchFilterSearchParam =
 (filterString: string, filterSearchParamsValue: string) => filterString.split('.')
   .reduce((acc, val, index) => {
     const filterSearchParamValuePart = filterSearchParamsValue.split('.')[index];
     if (filterSearchParamValuePart && filterSearchParamValuePart !== val) {
       return false;
     }

     return acc;
   }, true);

export default function <T extends Array<Record<string, any>>> (arrayToFilter: T, {
  filterBy,
}: {
  filterBy: Array<string>
}): T {
  const [filterSearchParams] = useSearchParams();

  const filterSearchParamsValue = filterSearchParams.get(SEARCH_PARAMS.filter);

  if (filterSearchParamsValue) {
    return arrayToFilter.filter((element) => {
      const filterString = filterBy.map((key) => key.split('.')
        .reduce((acc, val) => (acc ? acc[val] : element[val]), undefined))
        .filter((value) => !!value)
        .join('.')
        .toLowerCase();

      return matchFilterSearchParam(filterString, filterSearchParamsValue);
    }) as T;
  }

  return arrayToFilter;
}
