import React from 'react';
import NftGridItem from '../../NftGridItem';
import { Prize } from '../../../utils/additionalTypes';
import LeaderboardPrizeItem from './LeaderboardPrizeItem';

const leaderboardPrizePool: Array<Prize> = [
  {
    name: 'Yeet NFT + Grand Chest',
    rankLabel: '1st 🥇',
    imageLink: './leaderboardPrizesSm/top1.png',
    description: 'Yeetard #3632 and a Grand Chest containing... probably nothing?',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/3632',
  },
  {
    name: 'Yeet NFT + Medium Chest',
    rankLabel: '2nd 🥈',
    imageLink: './leaderboardPrizesSm/top2.png',
    description: 'Yeetard #3651 and a Medium Chest containing... probably nothing?',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/3651',
  },
  {
    name: 'Yeet NFT + Small Chest',
    rankLabel: '3rd 🥉',
    imageLink: './leaderboardPrizesSm/top3.png',
    description: 'Yeetard #3665 and a Small Chest containing... probably nothing?',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/3665',
  },
  {
    name: 'Small Chest',
    rankLabel: 'Places 4-10',
    imageLink: './leaderboardPrizesSm/treasureSmall.png',
    description: 'Small chest containing... probably nothing?',
  },
  {
    name: 'Big Sack of Coins',
    rankLabel: 'Places 11-50',
    imageLink: './leaderboardPrizesSm/coinSackBig.png',
    description: 'What are these coins anyway? Who knows!',
  },
  {
    name: 'Small Sack of Coins',
    rankLabel: 'Places 51-200',
    imageLink: './leaderboardPrizesSm/coinSackSmall.png',
    description: 'What are these coins anyway? Who knows!',
  },
];

const PrizePillar = ({
  classname,
  children,
  prize,
}: {
    classname: string
    children: React.ReactNode
    prize: Prize
}) => (
  <div className={`
    ${classname}
    relative flex flex-col items-center
  `}
  >
    <div className="translate-y-1.5 h-24 w-24">
      {'openSeaLink' in prize ? (
        <NftGridItem
          title={prize.name}
          description={prize.description}
          imgSrc={prize.imageLink}
          openSeaLink={prize.openSeaLink}
        />
      ) : (
        <LeaderboardPrizeItem
          title={prize.name}
          description={prize.description}
          imgSrc={prize.imageLink}
        />
      )}
    </div>
    <div className={`
        h-0 w-[125px]
        border
        border-t-0
        border-mainBgSuperLite border-b-[10px]
        border-l-transparent border-l-[15px]
        border-r-transparent border-r-[15px]
    `}
    />
    <div className='text-xl text-white flex justify-center items-center h-16 w-[125px] bg-gradient-to-b from-mainBgLite to-mainBg border-l-[2px] border-r-[2px] border-mainBgLite'>
      {children}
    </div>
    <div className="h-8 w-[125px] bg-mainBg border-l-[2px] border-r-[2px] border-mainBgLite" />
  </div>
);

export default ({
  extraClassname,
}: {
    extraClassname?: string
}) => (
  <div className={extraClassname}>
    <div
      className="w-full flex flex-row justify-center py-8 drop-shadow-seasonPrizes"
      style={{
        maskImage: 'linear-gradient(0deg, transparent 15%, rgb(0, 0, 0) 25%)',
      }}
    >
      {[{
        classname: 'translate-x-[20px] translate-y-[10px]',
        child: <div className="-translate-x-2">{leaderboardPrizePool[1].rankLabel}</div>,
        index: 1,
      },
      {
        classname: 'scale-110 z-10',
        child: <div className="text-2xl">{leaderboardPrizePool[0].rankLabel}</div>,
        index: 0,
      },
      {
        classname: 'translate-x-[-20px] translate-y-[10px]',
        child: <div className="translate-x-2">{leaderboardPrizePool[2].rankLabel}</div>,
        index: 2,
      }].map(({ classname, child, index }) => (
        <PrizePillar
          key={index}
          classname={classname}
          prize={leaderboardPrizePool[index]}
        >
          {child}
        </PrizePillar>
      ))}
    </div>
    <div className="flex flex-col items-center -mt-8">
      <div className="w-[345px] flex flex-row justify-center flex-wrap gap-4">
        {leaderboardPrizePool.slice(3, 10).map((prize, index) => (
          <div
            key={index}
            className="h-fit w-24"
          >
            {'openSeaLink' in prize ? (
              <NftGridItem
                title={prize.name}
                description={prize.description}
                imgSrc={prize.imageLink}
                openSeaLink={prize.openSeaLink}
              />
            ) : (
              <LeaderboardPrizeItem
                title={prize.name}
                description={prize.description}
                imgSrc={prize.imageLink}
              />
            )}
            <div className="text-md text-center text-white mt-2">{prize.rankLabel}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
