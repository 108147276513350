import { eventOpts } from './consts';

const MIN_VOLUME = 0;
const MAX_VOLUME = 0.5;
const VOLUME_CUTOFF = 0.1;
const FADE_SPEED = 0.025;

const BgMusicService = () => {
  const playBgMusic = () => {
    const bgMusicElement = document.getElementById('bgMusic') as HTMLAudioElement;

    if (bgMusicElement) {
      bgMusicElement.volume = MIN_VOLUME;

      const lerpToMaxVolume = () => {
        bgMusicElement.volume += FADE_SPEED;

        if (bgMusicElement.volume < MAX_VOLUME - VOLUME_CUTOFF) {
          requestAnimationFrame(lerpToMaxVolume);
        }
      };

      lerpToMaxVolume();

      return bgMusicElement?.play();
    }

    return null;
  };

  const stopBgMusic = () => {
    const bgMusicElement = document.getElementById('bgMusic') as HTMLAudioElement;

    bgMusicElement.pause();

    // TODO doesnt work on iOS 🤷‍
    // if (bgMusicElement) {
    //   bgMusicElement.volume = MAX_VOLUME;
    //
    //   const lerpToMinVolume = () => {
    //     bgMusicElement.volume -= FADE_SPEED;
    //
    //     if (bgMusicElement.volume > MIN_VOLUME + VOLUME_CUTOFF) {
    //       requestAnimationFrame(lerpToMinVolume);
    //     } else {
    //       bgMusicElement.pause();
    //     }
    //   };
    //
    //   lerpToMinVolume();
    // }
  };

  const resumeBgMusicOnInteraction = () => {
    const playPromise = playBgMusic();

    if (playPromise) {
      playPromise.then(() => {
        // eslint-disable-next-line no-console
        console.log('bg music playing!');

        window.removeEventListener('click', resumeBgMusicOnInteraction, true);
        window.removeEventListener('touchstart', resumeBgMusicOnInteraction, eventOpts);
      });
    }
  };

  return {
    playBgMusic: () => {
      const playPromise = playBgMusic();

      if (playPromise) {
        playPromise.then(() => {
          // eslint-disable-next-line no-console
          console.log('bg music playing!');
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);

          window.addEventListener('click', resumeBgMusicOnInteraction, true);
          window.addEventListener('touchstart', resumeBgMusicOnInteraction, eventOpts);
        });
      }
    },
    stopBgMusic,
  };
};

const bgMusicService = BgMusicService();

export default bgMusicService;
