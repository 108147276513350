import { ShopItem } from './additionalTypes';

export default [
  {
    ItemName: 'Yeet #3678',
    ItemDescription: 'Just YEEEET it',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/bad87133e78d5c9b45f0b6785c6f82e9.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/3678',
    ItemCollectionName: 'Yeet',
    ItemPrice: 3000000,
    Unlimited: false,
  },
  {
    ItemName: 'Yeet #3685',
    ItemDescription: 'Just YEEEET it',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/7f4bc07dd1c8f7e5e64c06582461c22d.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/3685',
    ItemCollectionName: 'Yeet',
    ItemPrice: 3000000,
    Unlimited: false,
  },
  {
    ItemName: 'Yeet #1870',
    ItemDescription: 'Just YEEEET it',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/998fd61193166d18d414c56bb0f6d900.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/1870',
    ItemCollectionName: 'Yeet',
    ItemPrice: 3000000,
    Unlimited: false,
  },
  {
    ItemName: 'Yeet #1916',
    ItemDescription: 'Just YEEEET it',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/b997b44f9993748f84bc6c5247a95007.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/1916',
    ItemCollectionName: 'Yeet',
    ItemPrice: 3000000,
    Unlimited: false,
  },
  {
    ItemName: 'Yeet #1917',
    ItemDescription: 'Just YEEEET it',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/eb900939aa27f13f9429732b314150a6.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/1917',
    ItemCollectionName: 'Yeet',
    ItemPrice: 3000000,
    Unlimited: false,
  },
  {
    ItemName: 'Yeet #1951',
    ItemDescription: 'Just YEEEET it',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/6b58a40db52d9e404f4665018072928d.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/1951',
    ItemCollectionName: 'Yeet',
    ItemPrice: 3000000,
    Unlimited: false,
  },
  {
    ItemName: 'Yeet #1952',
    ItemDescription: 'Just YEEEET it',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/baf092bcdadd4a3008550f8a4ab6ceaf.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0x2e660787bceccd39f67b8190a5bc4fc3ad3b64f7/1952',
    ItemCollectionName: 'Yeet',
    ItemPrice: 3000000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3548',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/6a4c75f787f1eba25848682361532f75.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3548',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 1500000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3556',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/485a52810fe866491cfa6b02958e07fd.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3556',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 1500000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3559',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/aa0470607617d259ededa2aa5d392e30.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3559',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 1500000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3563',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/67171560638b6aa4ac78880b329f578f.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3563',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 1500000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3564',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/71284ea0a02d64e406e3f432401f0823.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3564',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 1500000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3586',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/80e2f27d894ccd05f0f1f042174a8e5e.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3586',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 1500000,
    Unlimited: false,
  },
  {
    ItemName: 'Bera Punk #3590',
    ItemDescription: 'Arbitrum OG Bera Punks',
    ItemImage: {
      kind: 'link',
      value:
       'https://i.seadn.io/s/raw/files/24484a24919e30ad2bb7df7d277ce3a5.png?auto=format&dpr=1&w=1000',
    },
    ItemType: 'NFT',
    OpenSeaLink:
     'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3590',
    ItemCollectionName: 'Bera Punk',
    ItemPrice: 1500000,
    Unlimited: false,
  },
] as ShopItem[];
