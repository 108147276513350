import React, { useEffect, useState } from 'react';
import GradientBorderWrapper from '../../GradientBorderWrapper';
import { MISSION_LABEL } from '../../../utils/consts';
import MissionChallengeItem from '../MissionChallengeItem';
import MissionTimer from './MissionTimer';
import { missionsExtraReducers } from '../../../store/slices/missions/missionsReducers';
import getMissionRemainingSeconds from '../../../utils/getMissionRemainingSeconds';
import { useAppDispatch } from '../../../store/hooks';
import { Mission } from '../../../utils/additionalTypes';
import GenericButton from '../../GenericButton';
import modalService from '../../../modalService/ModalService';
import RetireSquadModal from '../../modals/RetireSquadModal';
import getMissionBgUrl from '../../../utils/getMissionBgUrl';

const textBgStyle = 'absolute left-0 top-0 right-0 bottom-0 bg-black -z-10 blur-lg opacity-30';

export default ({
  mission,
}: {
	mission: Mission
}) => {
  const dispatch = useAppDispatch();

  const [shouldDisplayMissionCompleteButton, setShouldDisplayMissionCompleteButton] = useState(getMissionRemainingSeconds(mission) <= 0);

  useEffect(() => {
    setShouldDisplayMissionCompleteButton(getMissionRemainingSeconds(mission) <= 0);
  }, [mission]);

  const missionBgImage = getMissionBgUrl(mission.title);

  return (
    <div className='flex flex-col gap-2 md:gap-4 md:flex-row'>
      <GradientBorderWrapper extraClassname='w-full'>
        <div
          className={`
			relative flex flex-col flex-grow rounded-md p-5 text-white sm:gap-14 gap-10
			border-transparent min-h-[148px]
			bg-cover bg-center hover:bg-bottom transition-[background-position] duration-700 
			before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 before:bg-black before:bg-opacity-20
			before:rounded-md
	      `}
          style={{
            backgroundImage: `url(${missionBgImage})`,
          }}
        >
          <div className="flex justify-between items-center z-10">
            <div className="relative">
              <span className="font-title font-bold text-2xl md:text-3xl mr-1 uppercase">{MISSION_LABEL[mission.type]}:</span>
              <span className="font-title text-2xl md:text-3xl pr-2"> {mission.title}</span>
              <div className={textBgStyle}/>
            </div>
          </div>
          <div className="flex gap-6 flex-col sm:flex-row justify-between items-center z-10">
            <div className="flex gap-4 sm:gap-2 md:gap-4">
              {mission.Challenges.map((missionChallenge, index) => (
                <MissionChallengeItem
                  key={index}
                  missionChallenge={missionChallenge}
                  extraClassname="w-14 h-14 sm:w-10 sm:h-10 md:w-14 md:h-14 lg:h-20 lg:w-20"
                />
              ))}
            </div>
            <div className="relative text-[.95rem] justify-center max-w-2xl">
              <div className="line-clamp-3">
                {mission.description}
              </div>
              <div className={textBgStyle}/>
            </div>
          </div>
        </div>
      </GradientBorderWrapper>
      <div className="flex flex-col text-center min-w-fit md:min-w-0 md:w-44 gap-1 justify-center items-center">
        {shouldDisplayMissionCompleteButton ? (
          <GenericButton
            onClick={() => dispatch(missionsExtraReducers.completeMission.reducer(mission))}
            label="Complete now!"
            extraClassname="mt-4 mb-6"
            buttonStyle="gold"
          />
        ) : (
          <div className="flex flex-col gap-2">
            <div className="mt-2 text-lg font-bold text-white whitespace-nowrap">
              Time remaining
            </div>
            <MissionTimer
              mission={mission}
              onFinishCallback={() => setShouldDisplayMissionCompleteButton(true)}
            />
            <GenericButton
              onClick={() => modalService.pushModal(<RetireSquadModal mission={mission} />)}
              label="Retire Squad"
              extraClassname="mt-2 mb-6"
              buttonStyle="red"
            />
          </div>
        )}
      </div>
    </div>
  );
};
