import React from 'react';
import Cookies from 'js-cookie';
import modalService from '../modalService/ModalService';
import { LOG_OBJECT_COOKIE, MISSION_TYPE } from './consts';
import { MissionOutcome } from './additionalTypes';

type LogObject = {
	missionsCompleted: {
		[MISSION_TYPE.Quest]: number
		[MISSION_TYPE.EliteQuest]: number
		[MISSION_TYPE.GroupQuest]: number
		[MISSION_TYPE.Raid]: number
		[MISSION_TYPE.Dungeon]: number
	}
	winCount: number
	failCount: number
	totalGemsEarned: number
	totalGemsSpent: number
}

const initLogObject: LogObject = {
  missionsCompleted: {
    [MISSION_TYPE.Quest]: 0,
    [MISSION_TYPE.EliteQuest]: 0,
    [MISSION_TYPE.GroupQuest]: 0,
    [MISSION_TYPE.Raid]: 0,
    [MISSION_TYPE.Dungeon]: 0,
  },
  winCount: 0,
  failCount: 0,
  totalGemsEarned: 0,
  totalGemsSpent: 0,
};

const LogObjectModal = ({
  logObject,
}: {
  logObject: LogObject
}) => (
  <div className="flex flex-col gap-2">
    {Object.entries(logObject).map(([key, value]) => (
      <div key={key}>
        {typeof value === 'object' ? (
          <div>
            {key.toString()}
            {Object.entries(value).map(([key2, value2]) => (
              <div
                className="ml-2"
                key={key2}
              >
                <span className="font-bold">{key2.toString()}: </span>
                <span>{value2.toString()}</span>
              </div>
            ))}
          </div>
        ) : (
          <div key={key}>
            <span className="font-bold">{key.toString()}: </span>
            <span>{value.toString()}</span>
          </div>
        )}
      </div>
    ))}
  </div>
);

const DebugLoggerService = () => {
  const getLogObject = () => {
    let persistedLogString = Cookies.get(LOG_OBJECT_COOKIE) as string | undefined;

    if (!persistedLogString) {
      Cookies.set(LOG_OBJECT_COOKIE, JSON.stringify(initLogObject), { expires: 365 });

      persistedLogString = JSON.stringify(initLogObject);
    }

    return JSON.parse(persistedLogString);
  };

  return {
    displayLogs: () => modalService.pushModal(<LogObjectModal logObject={getLogObject()} />),
    removeLogs: () => {
      Cookies.remove(LOG_OBJECT_COOKIE);
    },
    onMissionComplete: (missionType: keyof typeof MISSION_TYPE, outcome: MissionOutcome) => {
      const logObject: LogObject = getLogObject();

      logObject.missionsCompleted[missionType]++;

      if (outcome.success) {
        logObject.winCount++;
        logObject.totalGemsEarned += outcome.gemsEarned;
      } else {
        logObject.failCount++;
      }

      Cookies.set(LOG_OBJECT_COOKIE, JSON.stringify(logObject), { expires: 365 });
    },
    incrementTotalGemsSpent: (cost: number) => {
      const logObject: LogObject = getLogObject();

      logObject.totalGemsSpent += cost;

      Cookies.set(LOG_OBJECT_COOKIE, JSON.stringify(logObject), { expires: 365 });
    },
  };
};

const debugLoggerService = DebugLoggerService();

export default debugLoggerService;
