import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Mission } from '../../../utils/additionalTypes';
import { MISSION_LABEL, MISSION_TYPE } from '../../../utils/consts';
import parseSecondsToHours from '../../../utils/parseSecondsToHours';
import GradientBorderWrapper from '../../GradientBorderWrapper';
import MissionChallengeItem from '../MissionChallengeItem';
import MissionRerollButton from './MissionRerollButton';
import MissionTimer from './MissionTimer';
import { useAppSelector } from '../../../store/hooks';
import getMissionBgUrl from '../../../utils/getMissionBgUrl';

const textBgStyle = 'absolute left-0 top-0 right-0 bottom-0 bg-black -z-10 blur-lg opacity-30';

const AvailableMissionCard = ({ mission }: {
  mission: Mission
}) => {
  const navigate = useNavigate();

  const missionDuration = parseSecondsToHours(mission.duration);
  const missionBgImage = getMissionBgUrl(mission.title);

  const missionsTimers = useAppSelector((state) => state.missions.missionsTimers);
  const missionTimer = missionsTimers && missionsTimers[mission.type];

  return (
    <GradientBorderWrapper
      shouldChangeBorderColorOnHover
      extraClassname='w-full'
    >
      <div
        className={`
            relative flex flex-col flex-grow rounded-md p-5 text-white 
            border-transparent min-h-[148px] cursor-pointer
            bg-cover bg-center hover:bg-bottom transition-[background-position] duration-700
            before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 before:bg-black before:bg-opacity-20
            before:rounded-md
            ${missionTimer ? 'gap-6' : 'sm:gap-14 gap-6'}
          `}
        style={{
          backgroundImage: `url(${missionBgImage})`,
        }}
        onClick={() => {
          navigate(`/mission/${mission.id}?sortBy=gearScore.descending`); // TODO better default sort param
        }}
      >
        <div className="flex justify-between items-start z-10">
          <div className="relative">
            <span className="font-title font-bold text-2xl md:text-3xl mr-1 uppercase">{MISSION_LABEL[mission.type]}:</span>
            <span className="font-title text-2xl md:text-3xl pr-2"> {mission.title}</span>
            <div className={textBgStyle}/>
          </div>
          <div className="hidden sm:inline font-title flex flex-col whitespace-nowrap">
            <div className="relative flex flex-col items-end">
              <div>duration: </div>
              <div className="font-bold text-2xl self-end">{missionDuration}h</div>
              <div className={textBgStyle}/>
            </div>
            {missionTimer && (
              <div className="relative flex flex-col items-end">
                <div>Mission and price reset in: </div>
                <MissionTimer
                  missionTimer={missionTimer}
                  extraClassname="!text-2xl"
                />
                <div className={textBgStyle}/>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between sm:hidden z-10">
          <div className="sm:hidden font-title flex flex-col whitespace-nowrap">
            <div className="relative flex flex-col items-start">
              <div>duration: </div>
              <div className="font-bold text-2xl">{missionDuration}</div>
              <div className={textBgStyle}/>
            </div>
            {missionTimer && (
            <div className="relative flex flex-col items-start">
              <div>Mission and price reset in: </div>
              <MissionTimer
                missionTimer={missionTimer}
                extraClassname="!text-2xl"
              />
              <div className={textBgStyle}/>
            </div>
            )}
          </div>
          <MissionRerollButton
            mission={mission}
            extraClassname={`sm:hidden ${mission.type === MISSION_TYPE.Quest && 'pointer-events-none opacity-0'}`}
          />
        </div>
        <div className="flex gap-6 flex-col sm:flex-row justify-between items-center z-10">
          <div className="flex gap-4 sm:gap-2 md:gap-4">
            {mission.Challenges.map((missionChallenge, index) => (
              <MissionChallengeItem
                key={index}
                missionChallenge={missionChallenge}
                extraClassname="w-14 h-14 sm:w-10 sm:h-10 md:w-14 md:h-14 lg:h-20 lg:w-20"
              />
            ))}
          </div>
          <div className="relative text-[.95rem] justify-center max-w-2xl">
            <div className="line-clamp-3">
              {mission.description}
            </div>
            <div className={textBgStyle}/>
          </div>
          <MissionRerollButton
            mission={mission}
            extraClassname={`hidden sm:inline ${mission.type === MISSION_TYPE.Quest && 'pointer-events-none opacity-0'}`}
          />
        </div>
      </div>
    </GradientBorderWrapper>
  );
};

export default AvailableMissionCard;
