import React from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';

export default ({
  acquiredMaterials,
}: {
  acquiredMaterials: Record<string, number>
}) => (
  <GenericModalLayout
    titleLabel="Disenchanting complete!"
    onConfirm={() => modalService.closeModal()}
    onConfirmLabel="Close"
    titleRibbonStyle="purple"
  >
    <div className="mb-7">
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      You've acquired following materials:
    </div>
    {Object.entries(acquiredMaterials).map(([key, value]) => (
      <div
        className="flex flex-row items-center"
        key={key}
      >
        <div className="mr-2">{key}: </div>
        <div className="mr-4 font-bold">{value}</div>
        <img
          src={`/inventoryItems/Results_${key.split(' ')[0]}_Dust.png`}
          alt="Dust"
          className="h-12 w-12 translate-y-[-11px]"
        />
      </div>
    ))}
    <div className="mt-7 font-bold">
      New materials will appear in your inventory!
    </div>
  </GenericModalLayout>
);
