import React from 'react';

export default ({
  gradientCutoffEnabled = true,
  withExtraPadding,
  extraClassname,
  children,
  cutoffHeight = 2, // 0-100
}: {
  gradientCutoffEnabled?: boolean,
  withExtraPadding?: boolean
  extraClassname?: string
  children: React.ReactNode
  cutoffHeight?: number
}) => (
  <div
    onScroll={({ currentTarget }) => {
      if (gradientCutoffEnabled) {
        const scrollPercentage = Number((currentTarget.scrollTop / (currentTarget.scrollHeight - currentTarget.clientHeight)).toFixed(3));
        const gradientCutoffThreshold = Math.abs(scrollPercentage < 0.5 ? scrollPercentage : scrollPercentage - 1).clamp(0, cutoffHeight / 100) * 100;

        if (currentTarget.getAttribute('data-gradientCutoffThreshold') !== gradientCutoffThreshold.toString()) {
          currentTarget.setAttribute('data-gradientCutoffThreshold', gradientCutoffThreshold.toString());

          if (scrollPercentage < 0.5) {
            currentTarget.style.maskImage = `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1)
            ${gradientCutoffThreshold}%, rgba(0,0,0,1) 97%, rgba(255,255,255,0) 100%)`;
          } else {
            currentTarget.style.maskImage = `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1)
            3%, rgba(0,0,0,1) ${100 - gradientCutoffThreshold}%, rgba(255,255,255,0) 100%)`;
          }
        }
      }
    }}
    className={`overflow-auto h-full flex flex-col ${extraClassname}`}
    style={{
      maskImage: gradientCutoffEnabled ?
        'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 97%, rgba(255,255,255,0) 100%)' :
        'none',
    }}
  >
    {withExtraPadding && <div className="w-full h-[3%] min-h-[3%]" />}
    {children}
    {withExtraPadding && <div className="w-full h-[3%] min-h-[3%]" />}
  </div>
);
