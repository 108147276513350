import { createSlice } from '@reduxjs/toolkit';
import parseExtraReducersCallbacks from '../../parseExtraReducersCallbacks';
import { InventoryItem } from '../../../utils/additionalTypes';
import { inventoryExtraReducers } from './inventoryReducers';

export type InventoryState = {
  ownedItems: Array<InventoryItem> | null
}

export const initialState = {
  ownedItems: null,
} as InventoryState;

const inventoryReducers = {
  reset: () => initialState,
};

export const accountSlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: inventoryReducers,
  extraReducers: parseExtraReducersCallbacks(inventoryExtraReducers),
});

export default accountSlice.reducer;
