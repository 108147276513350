import React from 'react';

export type RibbonStyle = 'generic' | 'purple' | 'green' | 'red' | 'gold' | 'orange'

const ribbonMap = {
  generic: 'p-4 bg-gradient-to-b from-gray-100/40 to-gray-600/40',
  purple: 'p-4 bg-gradient-to-b from-violet-400/50 to-violet-900/50',
  green: 'p-4 bg-gradient-to-b from-emerald-400/50 to-emerald-900/50',
  red: 'p-4 bg-gradient-to-b from-rose-400/50 to-rose-900/50',
  gold: 'p-4 bg-gradient-to-b from-yellow-400/50 to-yellow-900/50',
  orange: 'p-4 bg-gradient-to-b from-orange-400/50 to-orange-900/50',
};

const backdropMap = {
  generic: 'before:bg-gray-600 before:opacity-50',
  purple: 'before:bg-violet-600 before:opacity-50',
  green: 'before:bg-emerald-500 before:opacity-50',
  red: 'before:bg-rose-500 before:opacity-50',
  gold: 'before:bg-yellow-500 before:opacity-50',
  orange: 'before:bg-orange-500 before:opacity-50',
};

export default ({
  label,
  ribbonStyle = 'generic',
}: {
	label: string
    ribbonStyle?: RibbonStyle
}) => (
  <div className={`
    relative flex flex-row
  `}
  >
    <div
      className={ribbonMap[ribbonStyle]}
      style={{
        maskImage: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%',
      }}
    />
    <div
      className={`
        before:absolute before:w-full before:h-full before:left-0 before:top-0 before:blur-md
        ${backdropMap[ribbonStyle]}
        ${ribbonMap[ribbonStyle]}
        font-title text-xl uppercase
        z-10
      `}
    >
      <div className="text-center scale-150 drop-shadow-inheritColorExtraSmall max-w-[20rem]">{label}</div>
    </div>
    <div
      className={ribbonMap[ribbonStyle]}
      style={{
        maskImage: 'linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%',
      }}
    />
  </div>
);
