import { Prize } from '../../../utils/additionalTypes';

const rafflePrizeProol: Array<Prize | Array<Prize>> = [
  {
    name: 'Beradoge #102',
    rankLabel: '1',
    imageLink: 'https://i.seadn.io/s/raw/files/786530288cbb767119ac5a4b2749085a.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x75395f24644f1e65f66e346a414ff3412f131dd5/102',
    description: 'Beradoge. It\'s a Bera but it\'s also a Dog. Nothing confirmed.',
  },
  [{
    name: 'Honey Comb #6235',
    rankLabel: '2',
    imageLink: 'https://raw.seadn.io/files/f903171253f9df3c304226e9edb75896.jpg',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xcb0477d1af5b8b05795d89d59f4667b59eae9244/6235',
    description: 'THJ - the OGs of Berachain. Probably one of the best ways to get eco beta exposure.',
  },
  {
    name: 'Tour de Berance #6098',
    rankLabel: '2',
    imageLink: 'https://i.seadn.io/gcs/files/17660ae6e6ddfc44e422600c5165b5e2.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x17798ba794ad94dad0b7d3f4b9bc2e9f6486d4b9/6098',
    description: 'Beras on Bikes, get a helmet',
  },
  {
    name: 'Hungrybera #689',
    rankLabel: '2',
    imageLink: 'https://i.seadn.io/s/raw/files/9e4df1cb9e45604780e6edec4022774c.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xac59f7e7e5da0dc4f416a7aeff7a49ac284f10ca/689',
    description: 'Hungry Bera from Ramen Finance. The most yummy launchpad of Berachain!',
  },
  {
    name: 'Beramonium #75',
    rankLabel: '2',
    imageLink: 'https://i.seadn.io/s/raw/files/88064cbeb80eb531cc705f9763163b62.jpg?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xb44adddbf9b19bcdd12f978df8b36723488b9b8b/75',
    description: 'Play Beramonium and get more Beramoniums 👀',
  },
  {
    name: 'THC #825',
    rankLabel: '2',
    imageLink: 'https://i.seadn.io/s/raw/files/a72be258a52c25fbf2bdb657a169d555.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xd65f95a40b6de26f6c390fcb8e3377d382fe678b/825',
    description: 'The OG Berachain Podcast NFTs',
  },
  {
    name: 'Cubhub #333',
    rankLabel: '2',
    imageLink: 'https://i.seadn.io/s/raw/files/66a9f5fc130150c822ae4df8edaff5b2.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x648cc6abcf7fe24afb76e6c15cdd9dfb41e9bcdb/333',
    description: 'AR & VR! But also cute Bera Cubs',
  },
  {
    name: 'Baka Bears #7362',
    rankLabel: '2',
    imageLink: 'https://i.seadn.io/s/raw/files/ddcb02e291b0c53fffc9f8ad55ea6318.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xe5b68556c12f37fa12f15ae87ea32f15a8c4e7b9/5',
    description: 'Anime on Berachain? Yes please',
  },
  {
    name: 'Bera Punk #3560',
    rankLabel: '2',
    imageLink: 'https://i.seadn.io/s/raw/files/6a982fa4a786b114d74947045b793452.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3560',
    description: 'Arbitrum OG Bera Punks',
  }],
  [{
    name: 'Honey Comb #6278',
    rankLabel: '3',
    imageLink: 'https://raw.seadn.io/files/f903171253f9df3c304226e9edb75896.jpg',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xcb0477d1af5b8b05795d89d59f4667b59eae9244/6278',
    description: 'THJ - the OGs of Berachain. Probably one of the best ways to get eco beta exposure.',
  },
  {
    name: 'Beramonium #73',
    rankLabel: '3',
    imageLink: 'https://i.seadn.io/s/raw/files/da3cf510609ccd0efffc0afdd94c95fe.jpg?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xb44adddbf9b19bcdd12f978df8b36723488b9b8b/73',
    description: 'Play Beramonium and get more Beramoniums 👀',
  },
  {
    name: 'Beramonium #78',
    rankLabel: '3',
    imageLink: 'https://i.seadn.io/s/raw/files/28907eba7387f9850cdf3b53e2298f10.jpg?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xb44adddbf9b19bcdd12f978df8b36723488b9b8b/78',
    description: 'Play Beramonium and get more Beramoniums 👀',
  }],
  [{
    name: 'Honey Comb #6667',
    rankLabel: '4',
    imageLink: 'https://raw.seadn.io/files/f903171253f9df3c304226e9edb75896.jpg',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xcb0477d1af5b8b05795d89d59f4667b59eae9244/6667',
    description: 'THJ - the OGs of Berachain. Probably one of the best ways to get eco beta exposure.',
  },
  {
    name: 'Hungrybera #704',
    rankLabel: '4',
    imageLink: 'https://i.seadn.io/s/raw/files/149e5e3a4fc5d2597099746c30bf7421.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xac59f7e7e5da0dc4f416a7aeff7a49ac284f10ca/704',
    description: 'Hungry Bera from Ramen Finance. The most yummy launchpad of Berachain!',
  }],
  {
    name: 'Honey Comb #6668',
    rankLabel: '5',
    imageLink: 'https://raw.seadn.io/files/f903171253f9df3c304226e9edb75896.jpg',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xcb0477d1af5b8b05795d89d59f4667b59eae9244/6668',
    description: 'THJ - the OGs of Berachain. Probably one of the best ways to get eco beta exposure.',
  },
  [{
    name: 'Tour de Berance #6760',
    rankLabel: '6',
    imageLink: 'https://i.seadn.io/gcs/files/572dc039e8c654ff0f5491850af79cb5.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x17798ba794ad94dad0b7d3f4b9bc2e9f6486d4b9/6760',
    description: 'Beras on Bikes, get a helmet',
  },
  {
    name: 'Bera Punk #3639',
    rankLabel: '6',
    imageLink: 'https://i.seadn.io/s/raw/files/c832af33af643eec2e06dae438163cb8.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3639',
    description: 'Arbitrum OG Bera Punks',
  }],
  [{
    name: 'Hungrybera #6468',
    rankLabel: '7',
    imageLink: 'https://i.seadn.io/s/raw/files/006bcb53a3bb446a28dc8ece05b849a4.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xac59f7e7e5da0dc4f416a7aeff7a49ac284f10ca/6468',
    description: 'Hungry Bera from Ramen Finance. The most yummy launchpad of Berachain!',
  },
  {
    name: 'Bera Punk #3650',
    rankLabel: '7',
    imageLink: 'https://i.seadn.io/s/raw/files/55f3cca9e8279ccd1ac00631b54af59c.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3650',
    description: 'Arbitrum OG Bera Punks',
  }],
  [{
    name: 'THC #397',
    rankLabel: '8',
    imageLink: 'https://i.seadn.io/gcs/files/2589a4326c869ec17eb07b6738b8f556.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xd65f95a40b6de26f6c390fcb8e3377d382fe678b/397',
    description: 'The OG Berachain Podcast NFTs',
  },
  {
    name: 'Beramonium #97',
    rankLabel: '8',
    imageLink: 'https://i.seadn.io/s/raw/files/249f38ba81203d6099d0a9bd76724f36.jpg?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xb44adddbf9b19bcdd12f978df8b36723488b9b8b/97',
    description: 'Play Beramonium and get more Beramoniums 👀',
  },
  {
    name: 'Bera Punk #3637',
    rankLabel: '8',
    imageLink: 'https://i.seadn.io/s/raw/files/0ef625f5e18c6b3a0b3b24d66fb0f5f0.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3637',
    description: 'Arbitrum OG Bera Punks',
  }],
  [{
    name: 'Beramonium #89',
    rankLabel: '9',
    imageLink: 'https://i.seadn.io/s/raw/files/e5f14da58a33df10e01cba388ad342db.jpg?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/ethereum/0xb44adddbf9b19bcdd12f978df8b36723488b9b8b/89',
    description: 'Play Beramonium and get more Beramoniums 👀',
  },
  {
    name: 'Bera Punk #3640',
    rankLabel: '9',
    imageLink: 'https://i.seadn.io/s/raw/files/6674c0e737cd76cae57430ce2f936945.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3640',
    description: 'Arbitrum OG Bera Punks',
  }],
  [{
    name: 'Cubhub #933',
    rankLabel: '10',
    imageLink: 'https://i.seadn.io/s/raw/files/c9e820f8753012975787a9aed4284a2d.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0x648cc6abcf7fe24afb76e6c15cdd9dfb41e9bcdb/933',
    description: 'AR & VR! But also cute Bera Cubs',
  },
  {
    name: 'Bera Punk #3644',
    rankLabel: '10',
    imageLink: 'https://i.seadn.io/s/raw/files/51de519ef47c394f7161af930ed25d84.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3644',
    description: 'Arbitrum OG Bera Punks',
  }],
  {
    name: 'Bera Punk #3646',
    rankLabel: '11',
    imageLink: 'https://i.seadn.io/s/raw/files/436e23bfcd1fe2f8b996a9000553ed3e.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3646',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3698',
    rankLabel: '12',
    imageLink: 'https://i.seadn.io/s/raw/files/6a74572288f12c9ae3d33812b76b1e8e.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3698',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3702',
    rankLabel: '13',
    imageLink: 'https://i.seadn.io/s/raw/files/5de53188e656b95101a2f1b3b56b9242.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3702',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3656',
    rankLabel: '14',
    imageLink: 'https://i.seadn.io/s/raw/files/e3edc6afd31529c5a6e3c9579fea4098.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3656',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3673',
    rankLabel: '15',
    imageLink: 'https://i.seadn.io/s/raw/files/81a9c3277df482f4f3f9236dd2f92cef.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3673',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3652',
    rankLabel: '16',
    imageLink: 'https://i.seadn.io/s/raw/files/451aa0f7c68eb6fa5099f44f21b5204e.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3652',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3690',
    rankLabel: '17',
    imageLink: 'https://i.seadn.io/s/raw/files/65618f28151d55659014fe925b345304.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3690',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3685',
    rankLabel: '18',
    imageLink: 'https://i.seadn.io/s/raw/files/70463c9d50b5bb438dfd4d1af7e169c0.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3685',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3621',
    rankLabel: '19',
    imageLink: 'https://i.seadn.io/s/raw/files/e4e56ae57fa0ea46a3ff80398e3a451b.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3621',
    description: 'Arbitrum OG Bera Punks',
  },
  {
    name: 'Bera Punk #3611',
    rankLabel: '20',
    imageLink: 'https://i.seadn.io/s/raw/files/120ae00fb0ca6f1dd5639e4c4a0422ae.png?auto=format&dpr=1&w=300',
    openSeaLink: 'https://opensea.io/assets/arbitrum/0xc46233dd3775c858a926b2b2d398bea7ca69499c/3611',
    description: 'Arbitrum OG Bera Punks',
  },
];

export default rafflePrizeProol;
