import React from 'react';
import GradientBorderWrapper from '../GradientBorderWrapper';
import AbsoluteBottomGradient from '../AbsoluteBottomGradient';
import TooltipTrigger from '../TooltipTrigger';
import ImageWithLoader from '../ImageWithLoader';

export default ({
  tooltipContent,
  title,
  imgSrc,
  additionalProps,
  gradientWrapperAdditionalProps,
  quantity,
  titleSmall,
  withGlow,
  imageLoaderExtraClassname,
  isSelected,
}: {
  tooltipContent: React.ReactNode
  title: string
  imgSrc: string
  additionalProps?: React.ComponentProps<any>
  gradientWrapperAdditionalProps?: React.ComponentProps<any>
  quantity?: number | null
  titleSmall?: boolean
  withGlow?: boolean
  imageLoaderExtraClassname?: string
  isSelected?: boolean
}) => (
  <TooltipTrigger
    content={tooltipContent}
    extraClassname="w-full h-full"
  >
    <GradientBorderWrapper
      extraClassname={`
        w-full h-full relative
        ${gradientWrapperAdditionalProps?.className}
      `}
      {...(withGlow && {
        withImageBgGlow: imgSrc,
      })}
      {...gradientWrapperAdditionalProps}
    >
      <div
        {...additionalProps}
        className={`
          w-full h-full bg-gray-900 relative flex justify-center items-end overflow-hidden rounded-md 
          ${additionalProps?.className}
        `}
      >
        <ImageWithLoader
          src={imgSrc}
          extraClassName={imageLoaderExtraClassname}
        />
        <span className={`absolute font-bold xw
          ${titleSmall ? 'text-[0.5rem]' : 'text-[0.6rem]'} 
          text-white pb-0.5 z-10 w-full text-center truncate
        `}
        >{title}</span>
        <AbsoluteBottomGradient heightInRem={2} />
      </div>
      {!!quantity && (
      <div
        className={`
          absolute -bottom-2 -left-2
          z-[1] rounded-full font-bold flex justify-center items-center p-2.5 w-4 h-4
          border-dashed border-[1px] border-transparent border-b-yellow-500 border-l-yellow-500
          text-white bg-black bg-opacity-20 backdrop-blur-md
        `}
      >
        <span className="text-xs rotate-12">
          {quantity}
        </span>
      </div>
      )}
      {/* clashes with quantity! */}
      {isSelected && (
      <div
        className={`
          absolute -bottom-[3px] -left-[3px]
          z-[1] rounded-full font-bold flex justify-center items-center p-2 w-4 h-4
          border-dashed border-[1px] border-transparent border-b-yellow-500 border-l-yellow-500
          text-white bg-black bg-opacity-20 backdrop-blur-md
        `}
      >
        <span className="text-xs rotate-12">
          ✓
        </span>
      </div>
      )}
    </GradientBorderWrapper>
  </TooltipTrigger>
);
