export default {
  '011ffb67-5b43-4023-a912-a68c9d984a3d': {
    Name: 'Wooden Helmet',
    Image: 'Gear_Common_Warrior_Head_Wooden Helmet',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '0163c1cb-c57c-4dab-9c29-a2f539481956': {
    Name: 'Pants of Agony',
    Image: 'Gear_Epic_Hunter_Pants_Pants of Agony',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Epic',
  },
  '0285b610-3191-4bea-91fd-b04a68711c7e': {
    Name: 'Bow of the Fakeglass',
    Image: 'Weapon_Epic_Hunter_Bow_Bow of the Fakeglass',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '0335ffdf-cef1-4d31-b5da-af4ded5b7813': {
    Name: 'Knee Arrow Boots',
    Image: 'Gear_Common_Warrior_Boots_Knee Arrow Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '033799cc-7327-411f-b5b8-5e579632ef1c': {
    Name: 'Mystic Chest',
    Image: 'Gear_Common_Mage_Chest_Mystic Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  '0456053e-2d31-4c4a-861c-77379af062d9': {
    Name: 'Hunter Bow',
    Image: 'Weapon_Common_Hunter_Bow_Hunter Bow',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '053af2d3-147b-4597-821a-7591c1efa869': {
    Name: 'Fashion Pants',
    Image: 'Gear_Common_Hunter_Pants_Fashion Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '056cf3b0-06d9-4bf4-8aae-ac6f3902e3bd': {
    Name: 'Deep Bed Magma Chest',
    Image: 'Gear_Epic_Mage_Chest_Deep Bed Magma Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Epic',
  },
  '0702f557-40c0-4a36-b344-621d29d7f825': {
    Name: 'Black Leather Boots',
    Image: 'Gear_Common_Hunter_Boots_Black Leather Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '079550d1-d5f1-48c7-9574-50438232a961': {
    Name: 'Tooth Eye',
    Image: 'Weapon_Epic_Warrior_Sword&Shield_Tooth Eye',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '089098c6-b058-4b05-b174-91d68e6affe5': {
    Name: 'Book of Elements',
    Image: 'Weapon_Common_Mage_Book_Book of Elements',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '095b0fc2-c984-4443-b911-9ba3c09223a1': {
    Name: 'Elementalist Tiara',
    Image: 'Gear_Rare_Mage_Head_Elementalist Tiara',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '09829ded-73a6-485c-ae55-8e67a4af1037': {
    Name: 'Powerchord',
    Image: 'Weapon_Common_Hunter_Bow_Powerchord',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '0d55cab4-9e3c-42a4-9136-33885d93fef0': {
    Name: 'Stalker Gloves',
    Image: 'Gear_Rare_Hunter_Gloves_Stalker Gloves',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  '0da517ad-56f3-4c9d-b045-bdc3efcdc5ff': {
    Name: 'Mystic Hat',
    Image: 'Gear_Common_Mage_Head_Mistyc Hat',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '0f2f6c0e-d58b-42bc-b06c-a9208cd3d6b8': {
    Name: 'Mystic Pants',
    Image: 'Gear_Common_Mage_Pants_Mystic Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '111dd535-01a0-4e79-9e9c-c2c88bf87813': {
    Name: 'Missing Parts Bandana',
    Image: 'Gear_Common_Hunter_Head_Missing Parts Bandana',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '14871a9b-48e3-4f53-8441-cabb9ac28611': {
    Name: 'Incomplete Gaunlets',
    Image: 'Gear_Common_Warrior_Gloves_Incomplete Gaunlets',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '1508e8fb-3444-4b3d-9289-2020220d5b00': {
    Name: 'Crying Elements Chest',
    Image: 'Gear_Rare_Mage_Chest_Crying Elements Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  '15f241c0-717b-452e-9c56-2b7bbf76e575': {
    Name: 'Commando Knife',
    Image: 'Weapon_Rare_Warrior_Sword&Shield_Commando2',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '1631c3b1-8bd4-4ec4-ab38-2e1b34914c34': {
    Name: 'Dragonrider Pants',
    Image: 'Gear_Epic_Warrior_Pants_Dragonrider Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Epic',
  },
  '1893c56d-a0c9-44b3-85df-a600042aaaa9': {
    Name: 'Iron Golem Chest',
    Image: 'Gear_Rare_Warrior_Chest_Iron Golm Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  '18facfd7-8cc2-4597-8321-fcd22dd22f05': {
    Name: 'Knee Arrow Chest',
    Image: 'Gear_Common_Warrior_Chest_Knee Arrow Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  '19031491-f344-4253-93b4-cf1e79f0b8c2': {
    Name: 'Sword of the Caller',
    Image: 'Weapon_Rare_Warrior_2-Handed_Sword of the Caller',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '1bfeb447-ad46-4628-a25f-d8d27c73b105': {
    Name: 'Deep Bed Magma Pants',
    Image: 'Gear_Epic_Mage_Pants_Deep Bed Magma Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Epic',
  },
  '1d1bce67-b11a-412e-84fc-200d7c331db7': {
    Name: 'Viper Poison Boots',
    Image: 'Gear_Rare_Hunter_Boots_Viper Poison Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  '1dc53d23-8a93-4a38-8da0-9b265b7a7572': {
    Name: 'Arcane Gloves',
    Image: 'Gear_Common_Mage_Gloves_Arcane Gloves',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '1dd07369-36f1-48ef-a7a5-57db28f1cd76': {
    Name: 'Healing Globe Staff',
    Image: 'Weapon_Common_Mage_Staff_Healing Globe Staff',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '1e86c999-5527-4fd9-b028-8ffa6ed96747': {
    Name: 'Wooden Set',
    Image: "Weapon_Common_Warrior_Sword&Shield_Soldier's Set",
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '1fc53619-7640-4c69-b22d-ec27232f9d2d': {
    Name: 'Crying Elements Gloves',
    Image: 'Gear_Rare_Mage_Gloves_Crying Elements Gloves',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  '20c95f01-e06f-4da8-aa43-cb03f1f42caf': {
    Name: 'Viper Poison Chest',
    Image: 'Gear_Rare_Hunter_Chest_Viper Poison Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  '23051db4-29be-439e-ab47-b446d7504586': {
    Name: 'Bronze Daggers',
    Image: 'Weapon_Common_Hunter_Daggers_Bronze Daggers',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '2334dc31-9f47-43d0-9f5c-a210f5095d61': {
    Name: 'Bronze and Iron Boots',
    Image: 'Gear_Common_Warrior_Boots_Bronze and Iron Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '247c9d35-61f6-4c9f-a71b-fdccef1c188b': {
    Name: 'Iron Golem Helm',
    Image: 'Gear_Rare_Warrior_Head_Iron Golem Helm',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '2509080d-9d60-4bca-91b9-d26da07155c0': {
    Name: 'Broken Iron',
    Image: 'Weapon_Common_Warrior_2-Handed_Broken Iron',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '251d230e-ef2a-4f66-b77b-1f84cbf5743c': {
    Name: "Alastor's Gift",
    Image: "Weapon_Epic_Mage_Staff_Alastor's Gift",
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '291ba535-667b-418a-8196-308ec47e2bc9': {
    Name: 'Silent Boots',
    Image: 'Gear_Rare_Hunter_Boots_Silent Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  '2982fd1f-e8ea-4677-9d8b-c9117f660291': {
    Name: 'Battle Helmet',
    Image: 'Gear_Rare_Warrior_Head_Battle Helmet',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '2c0d28b7-e7da-44de-a7e5-28ea4f9bf308': {
    Name: 'Training Bow',
    Image: 'Weapon_Common_Hunter_Bow_Training Bow',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '2c7e9166-0c80-4695-b8b1-3dd9d406d531': {
    Name: 'Stalker Pants',
    Image: 'Gear_Rare_Hunter_Pants_Stalker Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  '2d39b7a6-c71e-4bf9-8b98-48614e26ce4d': {
    Name: 'Shadow Rise Boots',
    Image: 'Gear_Epic_Hunter_Boots_Shadow Rise Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Epic',
  },
  '2d781d2c-e08b-49bf-aa14-47ea64499046': {
    Name: 'Old Mage Chest',
    Image: 'Gear_Common_Mage_Chest_Old Mage Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  '2d9b9179-e8f5-4e1f-88e5-a071920d7910': {
    Name: 'Old Mage Pants',
    Image: 'Gear_Common_Mage_Pants_Old Mage Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '2f3dc28f-5163-4641-847c-e02e5f6b1c15': {
    Name: 'Helm of Agony',
    Image: 'Gear_Epic_Hunter_Head_Helm of Agony',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Epic',
  },
  '301be83c-e22e-42c8-bc31-f45533f01ace': {
    Name: 'Chest of Agony',
    Image: 'Gear_Epic_Hunter_Chest_Chest of Agony',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Epic',
  },
  '3135d17f-f356-4ea7-9292-6c00160d13b4': {
    Name: 'Wooden Gauntlets',
    Image: 'Gear_Common_Warrior_Gloves_Wooden Gauntlets',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '31b0831a-5e7f-4c99-9dae-d60b9f2d1e71': {
    Name: 'Elementalist Chest',
    Image: 'Gear_Rare_Mage_Chest_Elementalist Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  '34a5ac2c-1fa9-4667-bc0d-7195a7f0674c': {
    Name: "Purple Monk's Hat",
    Image: "Gear_Rare_Mage_Head_Purple Monk's Hat",
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '34d0d6f8-a405-41c3-a1ea-6a776985abc4': {
    Name: 'Wooden Bow',
    Image: 'Weapon_Common_Hunter_Bow_Wooden Bow',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '3537f3c5-2af6-45d5-b41d-e1314ea21c4c': {
    Name: 'Eyebow',
    Image: 'Weapon_Rare_Hunter_Bow_Eyebow',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '354a3d95-c749-454e-8767-174866db3753': {
    Name: 'Erethical',
    Image: 'Weapon_Legendary_Warrior_2-Handed_Erethical',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Legendary',
  },
  '3582200d-1309-4bf9-b801-2f880ed62958': {
    Name: 'Dawn of Justice',
    Image: 'Weapon_Epic_Warrior_Sword&Shield_Dawn Justice',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '378385cc-1397-4e29-8f57-63468b7ed24e': {
    Name: 'Boots of Agony',
    Image: 'Gear_Epic_Hunter_Boots_Boots of Agony',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Epic',
  },
  '37ae6c68-4773-460b-a531-69ca33265c0e': {
    Name: 'Crying Elements Helm',
    Image: 'Gear_Rare_Mage_Head_Crying Elements Helm',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '38025f73-4f2e-4d49-9a11-817645cee842': {
    Name: 'Knee Arrow Forearms',
    Image: 'Gear_Common_Warrior_Gloves_Knee Arrow Forearms ',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '3876e1ad-949e-4239-9adb-43dd8f8e2676': {
    Name: 'Black Leather Pants',
    Image: 'Gear_Common_Hunter_Pants_Black Leather Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '39f9369d-c2b4-4785-a87a-ef3906db51f2': {
    Name: 'Mystic Boots',
    Image: 'Gear_Common_Mage_Boots_Mystic Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '3c9760b3-9aea-41e7-a8dd-3213242737a8': {
    Name: 'Necronomicon',
    Image: 'Weapon_Epic_Mage_Book_Necronomicon',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '3e3955c9-2f06-4b0f-8103-02800af7e427': {
    Name: 'Shadow Rise Gloves',
    Image: 'Gear_Epic_Hunter_Gloves_Shadow Rise Gloves',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Epic',
  },
  '413f5115-5904-4fb1-8dcf-0ce975cfafbc': {
    Name: 'Bronze and Iron Pants',
    Image: 'Gear_Common_Warrior_Pants_Bronze and Iron Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '41abebf8-8e98-4861-9198-83f4266b4065': {
    Name: 'Boots of Coraline Lion',
    Image: 'Gear_Legendary_Warrior_Boots_Boots of Coraline Lion',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Legendary',
  },
  '41be2455-c068-482d-8729-deedbc02dcc8': {
    Name: 'Stalker Hat',
    Image: 'Gear_Rare_Hunter_Head_Stalker Hat',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '4218b6bd-25a3-40dd-924a-5960f60c04fe': {
    Name: 'Bronze and Iron Chest',
    Image: 'Gear_Common_Warrior_Chest_Bronze and Iron Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  '42bb3e3f-a6ca-45f5-a227-1d9170220d37': {
    Name: 'Apprentice Boots',
    Image: 'Gear_Common_Mage_Boots_Apprentice Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '42c00ce4-3533-45ab-b193-9c31d2e400fa': {
    Name: 'Old Mage Gloves',
    Image: 'Gear_Common_Mage_Gloves_Old Mage Gloves',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '443814e6-f748-4f8f-9394-25c09cf55687': {
    Name: 'Poisoned Bow',
    Image: 'Weapon_Rare_Hunter_Bow_Poisoned Bow',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '45613e7f-7cad-4e71-bddf-6f80a15d9d33': {
    Name: 'Bearfang Gloves',
    Image: 'Gear_Common_Hunter_Gloves_Bearfang Gloves',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '4679f921-6ec8-4e39-b95c-20e8954aca11': {
    Name: 'Fashion Leather Chest',
    Image: 'Gear_Common_Hunter_Chest_Fashion Leather Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  '4915c5bf-1405-4db7-8122-bb61878b8766': {
    Name: 'Novice Book',
    Image: 'Weapon_Common_Mage_Book_Novice Book',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '4a2d305d-1a12-4961-9cfc-cab46091eb73': {
    Name: 'Incomplete Steel Pants',
    Image: 'Gear_Common_Warrior_Pants_Incomplete Steel Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '4a4e49d9-0f3c-46e9-9b26-859f2e74cb53': {
    Name: 'Bloodtaste',
    Image: 'Weapon_Epic_Warrior_2-Handed_Bloodtaste',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '4b459a5f-1eed-4af3-9aaf-0c7b86297aba': {
    Name: 'Book of Energy',
    Image: 'Weapon_Common_Mage_Book_Book of Energy',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '4bc4797c-1f58-4f57-863b-a22ccd634ff3': {
    Name: 'Viper Poison Pants',
    Image: 'Gear_Rare_Hunter_Pants_Viper Poison Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  '4d3429d5-b7fa-4dc3-a973-6e7506c5ec7e': {
    Name: 'Wild Hunt Boots',
    Image: 'Gear_Rare_Warrior_Boots_Wild Hunt Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  '4df0e950-eed4-49b3-8920-05c92f49186e': {
    Name: 'Arcane Boots',
    Image: 'Gear_Common_Mage_Boots_Arcane Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '512a168e-e7ee-4800-9b47-26737883ff36': {
    Name: 'Black Leather Helm',
    Image: 'Gear_Common_Hunter_Head_Black Leather Helm',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '515d01b7-0399-4d47-96e9-a188a5f50fc5': {
    Name: 'Decline of the Order',
    Image: 'Weapon_Legendary_Mage_Book_Decline of the Order',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Legendary',
  },
  '520a5387-f232-495b-b045-8e5d6d4c9acd': {
    Name: 'Stolen Blade',
    Image: 'Weapon_Common_Warrior_2-Handed_Stolen Blade',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '5254a705-d57c-4cc3-a25a-3b97849d3932': {
    Name: 'Silent Armor Chest',
    Image: 'Gear_Rare_Hunter_Chest_Silent Armor Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  '52fd3742-b20c-4122-8374-ede3d69f7883': {
    Name: 'Incomplete Chest',
    Image: 'Gear_Common_Warrior_Chest_Incomplete Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  '5318e2ac-00df-4b84-97c3-3ca9b7985a4a': {
    Name: 'Poison Daggers',
    Image: 'Weapon_Common_Hunter_Daggers_Poison Daggers',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '5503be71-b335-4b6e-b350-7da94d0375a8': {
    Name: 'Abyss Helmet',
    Image: 'Gear_Epic_Warrior_Head_Abyss Helmet',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Epic',
  },
  '5531619b-0fe5-40e1-9119-6dcb12ab50fe': {
    Name: 'Viper Poison Helm',
    Image: 'Gear_Rare_Hunter_Pants_Viper Poison Helm',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '56c7d7ac-5dbc-40e3-b592-075a7d3827e8': {
    Name: 'Slaughtered Dragon Pants',
    Image: 'Gear_Legendary_Hunter_Pants_Slaughtered Dragon Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Legendary',
  },
  '573bc41c-011a-4764-86d7-4765fba44498': {
    Name: 'Iron Golem Boots',
    Image: 'Gear_Rare_Warrior_Boots_Iron Golem Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  '577e709a-73a3-497b-8281-e3a87ea29202': {
    Name: 'Seven Hit',
    Image: 'Weapon_Rare_Hunter_Daggers_Seven Hit',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '60cf5705-95b5-401d-b810-2651ae0d378c': {
    Name: 'Daggers of Eternal Lament',
    Image: 'Weapon_Legendary_Hunter_Daggers_Daggers of Eternal Lament',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Legendary',
  },
  '6247ee28-4c7f-4d09-b157-8b7fcc87afd7': {
    Name: 'Seli Lusha',
    Image: 'Weapon_Epic_Hunter_Bow_Seli Lusha',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '62ee0cb3-4432-487f-a7be-377c1552ce17': {
    Name: 'Pants of the Wood',
    Image: 'Gear_Common_Warrior_Pants_Pants of the Wood',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '63c3913b-61d6-42c7-ac6b-bd3948851482': {
    Name: 'Apprentice Gloves',
    Image: 'Gear_Common_Mage_Gloves_Apprentice Gloves',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '670480fd-4ab6-44c5-8bb2-e8670f979d5a': {
    Name: "Purple Monk's Chest",
    Image: "Gear_Rare_Mage_Chest_Purple Monk's Chest",
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  '681e5203-36b1-46d4-a23c-d6f7f64e09a2': {
    Name: 'Knee Arrow Pants',
    Image: 'Gear_Common_Warrior_Pants_Knee Arrow Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '6979d1ff-8e52-4995-9a6f-7a610af138bd': {
    Name: 'Stalker Boots',
    Image: 'Gear_Rare_Hunter_Boots_Stalker Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  '6ed20b25-34fd-455a-9ed1-bd20fe3c2bed': {
    Name: 'Bearfang Helm',
    Image: 'Gear_Common_Hunter_Head_Bearfang Helm',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '6f426bd7-d618-4077-af97-56c12509e73c': {
    Name: 'Sharpened',
    Image: 'Weapon_Rare_Warrior_Sword&Shield_Rare_Sharpened',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '6f5ca35e-e6a4-4183-9709-0e10dbfd45b6': {
    Name: 'Shadow Rise Chest',
    Image: 'Gear_Epic_Hunter_Chest_Shadow Rise Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Epic',
  },
  '719e5edb-6526-4a32-b699-ea9c165cd90a': {
    Name: 'Slaughtered Dragon Chest',
    Image: 'Gear_Legendary_Hunter_Chest_Slaughtered Dragon Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Legendary',
  },
  '72045b3c-2fff-4bc5-ad8f-9e710a19af2d': {
    Name: 'Fashion Leather Hat',
    Image: 'Gear_Common_Hunter_Head_Fashion Leather Hat',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '748ce486-2732-4830-b230-42391a2a1aa0': {
    Name: 'Elementalist Boots',
    Image: 'Gear_Rare_Mage_Boots_Elementalist Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  '755d0b7e-c1fa-4767-be9b-2caa636d4c07': {
    Name: 'Wild Hunt Gauntlets',
    Image: 'Gear_Rare_Warrior_Gloves_Wild Hunt Gauntlets',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  '756bcdfc-e3a6-46bf-a469-ad3a1e55c75b': {
    Name: 'Lightspirit Pants',
    Image: 'Gear_Legendary_Mage_Pants_Lightspirit Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Legendary',
  },
  '78b24123-7895-47ca-b2b1-9c9c81fd2e4d': {
    Name: 'Abyss Boots',
    Image: 'Gear_Epic_Warrior_Boots_Abyss Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Epic',
  },
  '7a5d5fbf-7eda-4141-a240-a0281068c80c': {
    Name: 'Missing Parts Pants',
    Image: 'Gear_Common_Hunter_Pants_Missing Parts Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '7ae5be19-f71d-4bb4-9ec1-e2b7a9bf82d2': {
    Name: 'Incomplete Helmet',
    Image: 'Gear_Common_Warrior_Head_Incomplete Helmet',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '7c8a02f9-d069-4a8f-a109-2ec6d851a955': {
    Name: 'Helmet of Coraline Lion',
    Image: 'Gear_Legendary_Warrior_Head_Helmet of Coraline Lion',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Legendary',
  },
  '7e03b077-9d64-4eb2-a98a-62b701913e18': {
    Name: 'Black Dagger Murder',
    Image: 'Weapon_Epic_Hunter_Daggers_Black Dagger Murder',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '7f643b37-21ba-4209-bc9f-8535a3aa414f': {
    Name: 'Knee Arrow Helmet',
    Image: 'Gear_Common_Warrior_Head_Knee Arrow Helmet',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '7f6b2302-ed57-45b1-a4a6-6ddcfff17bbc': {
    Name: 'Pants of Coraline Lion',
    Image: 'Gear_Legendary_Warrior_Pants_Pants of Coraline Lion',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Legendary',
  },
  '7fba2a1b-ac7c-41e5-b2b6-f9fc3e066fb2': {
    Name: 'Missing Parts Boots',
    Image: 'Gear_Common_Hunter_Boots_Missing Parts Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '7fc6cf62-3e83-470d-ac6b-e5213ba20eae': {
    Name: 'Fashion Leather Armguards',
    Image: 'Gear_Common_Hunter_Gloves_Fashion Leather Armguards',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  '81ad06c4-4329-4da3-ae2f-40db8e789c15': {
    Name: 'Death Note',
    Image: 'Weapon_Epic_Mage_Book_Death Note',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  '81b61342-40d9-48e1-b5f8-79d54545ca7d': {
    Name: 'Wild Hunt Pants',
    Image: 'Gear_Rare_Warrior_Pants_Wild Hunt Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  '83dd609f-e20a-4d26-8c9d-127d4722d075': {
    Name: 'Pants of Harmony',
    Image: 'Gear_Epic_Mage_Pants_Pants of Harmony',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Epic',
  },
  '8465b3e3-b036-4822-9dd6-2f8679d5f023': {
    Name: 'Bow of the Poison God',
    Image: 'Weapon_Legendary_Hunter_Bow_Bow of the Poisonal God',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Legendary',
  },
  '84b88699-00bb-4567-b8d9-7b9399118c86': {
    Name: "Purple Monk's Gloves",
    Image: "Gear_Rare_Mage_Gloves_Purple Monk's Gloves",
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  '859a0c4a-675b-4d29-9a24-1805c2ec96cf': {
    Name: 'Bearfang Boots',
    Image: 'Gear_Common_Hunter_Boots_Bearfang Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '86def45e-a761-4e9c-be2a-60bcb82cbd1a': {
    Name: 'Novice Staff',
    Image: 'Weapon_Common_Mage_Staff_Novice Staff',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '872d3abf-3665-4589-90f6-3f2ea7f3d124': {
    Name: 'Arcane Pants',
    Image: 'Gear_Common_Mage_Pants_Arcane Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  '884bc4a9-e338-4252-b2f8-9cf817fe6b84': {
    Name: 'Wooden Staff',
    Image: 'Weapon_Common_Mage_Staff_Wooden Staff',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '88f4f693-51a7-41ae-bd42-0c2f1ced40f3': {
    Name: 'Dragonrider Helmet',
    Image: 'Gear_Epic_Warrior_Head_Dragonrider Helmet',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Epic',
  },
  '8919b335-d821-4015-b202-e395a228ee69': {
    Name: 'Gauntlets of Coraline Lion',
    Image: 'Gear_Legendary_Warrior_Gloves_Gauntlets of Coraline Lion',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Legendary',
  },
  '892d2ece-1815-4182-a46d-50fca1c5f78d': {
    Name: 'Wooden Chest',
    Image: 'Gear_Common_Warrior_Chest_Wooden Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  '898510aa-3480-4c38-8642-76a9ac10b472': {
    Name: 'Black Iron Set',
    Image: 'Weapon_Rare_Warrior_Sword&Shield_Black Iron Set',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '8ef0629e-469f-49bb-88b6-071f0871eb3d': {
    Name: 'Viper Poison Gloves',
    Image: 'Gear_Rare_Hunter_Gloves_Viper Poison Gloves',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  '8f1b68bf-c940-4939-b5c1-0aabe4d189c5': {
    Name: 'Abyss Gauntlets',
    Image: 'Gear_Epic_Warrior_Gloves_Abyss Gauntlets',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Epic',
  },
  '8f24f70b-5be6-44e6-bf12-e2ca7da32db2': {
    Name: 'Training Set',
    Image: 'Weapon_Common_Warrior_Sword&Shield_Training Set',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '8f5b3709-a7bf-4cd6-94f1-de074d376305': {
    Name: 'Dragonrider Chest',
    Image: 'Gear_Epic_Warrior_Chest_Dragonrider Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Epic',
  },
  '908971d7-253f-425e-9a4e-543353d84ce8': {
    Name: "Soldier's Set",
    Image: "Weapon_Common_Warrior_Sword&Shield_Soldier's Set2",
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  '9580c156-72dc-4c73-bb10-1957d104416c': {
    Name: 'Bronze and Iron Helmet',
    Image: 'Gear_Common_Warrior_Head_Bronze and Iron Helmet',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  '96b2f1bb-7653-44cc-88bf-e4748a9946dc': {
    Name: 'Blacksmith Warhammer',
    Image: 'Weapon_Rare_Warrior_2-Handed_Blacksmith Warhammer',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '970d2848-7352-47a8-ad65-5a6ab5524344': {
    Name: 'Wooden Halfshoes',
    Image: 'Gear_Common_Warrior_Boots_Wooden Halfshoes',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '985da133-c971-4dd7-9126-91df478e114a': {
    Name: 'Hundred Pages',
    Image: 'Weapon_Common_Mage_Book_Hundred Pages',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  '9884ea87-d36c-47d6-b9de-eaf7a41141a2': {
    Name: 'Crying Elements Boots',
    Image: 'Gear_Rare_Mage_Boots_Crying Elements Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  '992c47b7-c209-4584-a2d2-b7628d596bfa': {
    Name: 'Abyss Chest',
    Image: 'Gear_Epic_Warrior_Chest_Abyss Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Epic',
  },
  '997b33d6-72b6-4a4c-8bb7-3cd2a4fe4fb2': {
    Name: 'Dragonrider Boots',
    Image: 'Gear_Epic_Warrior_Boots_Dragonrider Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Epic',
  },
  '9b0400b6-8a30-41b3-80b7-f46547d527a2': {
    Name: 'Fashion Boots',
    Image: 'Gear_Common_Hunter_Boots_Fashion Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  '9b2591af-bb9e-42ce-b715-cbd5032f6492': {
    Name: 'Silent Helmet',
    Image: 'Gear_Rare_Hunter_Head_Silent Helmet',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  '9b63207e-b318-4c94-b0a9-ad523548f603': {
    Name: 'Silent Pants',
    Image: 'Gear_Rare_Hunter_Pants_Silent Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  '9c678643-67c7-403c-ba5f-b003ba2a8a83': {
    Name: 'Blood Whisperer',
    Image: 'Weapon_Rare_Hunter_Daggers_Blood Whisperer',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'a323e6a4-e57e-4dd7-a438-2eb09bb6b973': {
    Name: 'Good Healer Staff',
    Image: 'Weapon_Rare_Mage_Staff_Good Healer Staff',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'a368dd31-c6b1-48bc-a992-1d628f3c1c56': {
    Name: 'Rough Steel',
    Image: 'Weapon_Common_Warrior_Sword&Shield_Rough Steel',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  'a3f583c9-de90-4c3b-bc95-e2b8441a159d': {
    Name: 'Grace of the Wonder',
    Image: 'Weapon_Epic_Mage_Staff_Grace of the Wonder',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  'a48c1ee3-2021-4b78-abbf-78a8a791645b': {
    Name: 'Mystic Gloves',
    Image: 'Gear_Common_Mage_Gloves_Mystic Gloves',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  'a6856bed-960d-4e87-89c5-a0c5c42c08ce': {
    Name: 'Dragonrider Gauntlest',
    Image: 'Gear_Epic_Warrior_Gloves_Dragonrider Gauntlest',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Epic',
  },
  'a70ddecb-17c4-4da6-82f1-e0167d9c3f2f': {
    Name: 'Incomplete Boots',
    Image: 'Gear_Common_Warrior_Boots_Incomplete Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  'a7ad5164-0172-4882-848b-23e829381b48': {
    Name: 'Battle Boots',
    Image: 'Gear_Rare_Warrior_Boots_Battle Boots',
    Class: 'Warrior',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  'a95f88eb-7ffd-4be1-9c11-5c5dcc924982': {
    Name: 'Apprentice Pants',
    Image: 'Gear_Common_Mage_Pants_Apprentice Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  'a966975b-288e-4b7e-9612-d5522829e39d': {
    Name: 'Battle Chest',
    Image: 'Gear_Rare_Warrior_Chest_Battle Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  'aaac49e3-6df0-4164-ba04-69270908b800': {
    Name: 'Wild Hunt Head',
    Image: 'Gear_Rare_Warrior_Head_Wild Hunt Head',
    Class: 'Warrior',
    GearType: 'Helmet',
    Rarity: 'Rare',
  },
  'ab809ba0-6483-4221-8d23-ee6b2ee64a38': {
    Name: 'Missing Parts Armguards',
    Image: 'Gear_Common_Hunter_Gloves_Missing Parts Armguards',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  'abc9b766-29f5-48fb-91b5-c26706b69833': {
    Name: 'Green Black Blades',
    Image: 'Weapon_Rare_Hunter_Daggers_Green Black Blades',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'ac36af64-4b61-4e66-a16b-d1cea43d2472': {
    Name: 'Infinite Harmony',
    Image: 'Weapon_Legendary_Mage_Staff_Infinite Harmony',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Legendary',
  },
  'ac947858-6e98-49f6-bcb6-5d002b72a44d': {
    Name: 'Slaughtered Dragon Helm',
    Image: 'Gear_Legendary_Hunter_Head_Slaughtered Dragon Helm',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Legendary',
  },
  'ae9f3f21-81cc-4323-98e8-f17d004937b9': {
    Name: 'Lightspirit Boots',
    Image: 'Gear_Legendary_Mage_Boots_Lightspirit Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Legendary',
  },
  'b03457fe-79a7-4cee-bb27-f47738299acd': {
    Name: 'Small Staff',
    Image: 'Weapon_Common_Mage_Staff_Small Staff',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  'b233ff72-f288-41f2-aade-add602a96310': {
    Name: 'Bearfang Chest',
    Image: 'Gear_Common_Hunter_Chest_Bearfang Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  'b3de5b20-13ad-408e-99d9-7a790b4e156e': {
    Name: 'Helm of Harmony',
    Image: 'Gear_Epic_Mage_Head_Helm of Harmony',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Epic',
  },
  'b3f1565e-fc24-4b66-8ae7-00c3b5d09571': {
    Name: 'Shadow Rise Pants',
    Image: 'Gear_Epic_Hunter_Pants_Shadow Rise Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Epic',
  },
  'b6816e41-aefa-4f01-bb4b-2d9edf8be824': {
    Name: 'Old Book',
    Image: 'Weapon_Common_Mage_Book_Old Book',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  'b98a5e29-4145-49e5-8ecd-66adb81f834a': {
    Name: "Angel Protector's Staff",
    Image: "Weapon_Rare_Mage_Staff_Angel Protector's Staff",
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'bcf3f9b6-f0da-43d5-b230-7844237f17d9': {
    Name: 'Bronze and Iron Gauntlets',
    Image: 'Gear_Common_Warrior_Gloves_Bronze and Iron Gauntlets',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  'bcf5c027-d273-4ca8-aa78-c5078339c3db': {
    Name: 'Deep Bed Magma Hat',
    Image: 'Gear_Epic_Mage_Head_Deep Bed Magma Hat',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Epic',
  },
  'be9f53f4-739c-4e45-aa71-d913005ebc21': {
    Name: 'Boots of Harmony',
    Image: 'Gear_Epic_Mage_Boots_Boots of Harmony',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Epic',
  },
  'bfe18844-47de-4ed6-9cbb-8e43ab09c481': {
    Name: 'Black Leather Chest',
    Image: 'Gear_Common_Hunter_Chest_Black Lesther Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  'bfe7da8b-f449-4601-bb23-b193fddcf253': {
    Name: 'Divine Staff',
    Image: 'Weapon_Rare_Mage_Staff_Divine Staff',
    Class: 'Mage',
    WeaponType: 'Staff',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'c09d8173-6a88-4c89-8eaa-d26d0b79ebf5': {
    Name: 'Battle Gauntlets',
    Image: 'Gear_Rare_Warrior_Gloves_Battle Gauntlets',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  'c0c38c9c-4368-4d30-85f7-3482b153e9fd': {
    Name: 'Deep Bed Magma Boots',
    Image: 'Gear_Epic_Mage_Boots_Deep Bed Magma Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Epic',
  },
  'c0f8ecd6-abf5-45f8-990c-d162a625ae6f': {
    Name: 'Missing Parts Chest',
    Image: 'Gear_Common_Hunter_Chest_Missing Parts Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  'c2ca91c0-5a6b-4969-bf05-8262d7c45fe5': {
    Name: 'Black Leather Gloves',
    Image: 'Gear_Common_Hunter_Gloves_Black Leather Gloves',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Common',
  },
  'c4673547-8bb3-4052-abe9-bd27b407a4fb': {
    Name: 'Commando Hacksaw',
    Image: 'Weapon_Rare_Warrior_2-Handed_Commando',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'c959e420-bb76-4402-832e-d7932a3738fe': {
    Name: 'Lights of Total Amnesty',
    Image: 'Weapon_Legendary_Warrior_Sword&Shield_Lights of Total Amnesty',
    Class: 'Warrior',
    WeaponType: 'Sword&Shield',
    GearType: 'Weapon',
    Rarity: 'Legendary',
  },
  'c9e599dc-2433-4c82-9c0b-895304ffba2e': {
    Name: 'Shadow Rise Hat',
    Image: 'Gear_Epic_Hunter_Head_Shadow Rise Hat',
    Class: 'Hunter',
    GearType: 'Helmet',
    Rarity: 'Epic',
  },
  'ca7aca4e-17c2-481a-bc93-7b53e6836190': {
    Name: 'Crying Elements Pants',
    Image: 'Gear_Rare_Mage_Pants_Crying Elements Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  'cb914bc0-8468-4186-b51e-2184d01d9c28': {
    Name: 'Gloves of Agony',
    Image: 'Gear_Epic_Hunter_Gloves_Gloves of Agony',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Epic',
  },
  'cc8132f4-2f4c-401a-ae5f-acbca9d66040': {
    Name: 'Lightspirit Helm',
    Image: 'Gear_Legendary_Mage_Head_Lightspirit Helm',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Legendary',
  },
  'd008413b-6c30-438f-a6ad-1b8c72e8804d': {
    Name: 'Arcane Helm',
    Image: 'Gear_Common_Mage_Head_Arcane Helm',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  'd11a0c47-5ff1-4b3b-8128-9784d2c58614': {
    Name: 'Apprentice Helm',
    Image: 'Gear_Common_Mage_Head_Apprentice Helm',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  'd488650d-af20-47db-b9a0-7944211277c9': {
    Name: 'Chest of Harmony',
    Image: 'Gear_Epic_Mage_Chest_Chest of Harmony',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Epic',
  },
  'd4ab177b-a3e4-4206-a8b6-d7ba46f24f4c': {
    Name: 'Old Mage Hat',
    Image: 'Gear_Common_Mage_Head_Old Mage Hat',
    Class: 'Mage',
    GearType: 'Helmet',
    Rarity: 'Common',
  },
  'd4f40d46-b28c-4da4-840b-fe406b3e2074': {
    Name: 'Training Daggers',
    Image: 'Weapon_Common_Hunter_Daggers_Training Daggers',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  'd5d8d88e-a5a4-4ce0-b3ab-1c96e08d350b': {
    Name: 'Slaughtered Dragon Gloves',
    Image: 'Gear_Legendary_Hunter_Gloves_Slaughtered Dragon Gloves',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Legendary',
  },
  'da5063bf-f090-4a31-9fa0-d0e3a55dce63': {
    Name: 'Deep Bed Magma Gloves',
    Image: 'Gear_Epic_Mage_Gloves_Deep Bed Magma Gloves',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Epic',
  },
  'da876636-70a8-402c-a6d7-16d997d3a295': {
    Name: 'Stalker Chest',
    Image: 'Gear_Rare_Hunter_Chest_Stalker Chest',
    Class: 'Hunter',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
  'dcfe4740-22b2-4626-acc6-c4d131e9a05a': {
    Name: 'Old Mage Boots',
    Image: 'Gear_Common_Mage_Boots_Old Mage Boots',
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Common',
  },
  'df677329-a63d-4bc8-9817-6be866adc63c': {
    Name: 'Apprentice Chest',
    Image: 'Gear_Common_Mage_Chest_Apprentice Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  'e08b1bbf-72e7-4449-92c8-56003a626f8f': {
    Name: 'Iron Golem Pants',
    Image: 'Gear_Rare_Warrior_Pants_Iron Golem Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  'e20cf10e-8033-4aa3-9b26-dff8a53b3236': {
    Name: 'Outlined Cover',
    Image: 'Weapon_Rare_Mage_Book_Outlined Cover',
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'e5c386c5-3105-492c-ab6b-2ad41d578577': {
    Name: "Purple Monk's Pants",
    Image: "Gear_Rare_Mage_Pants_Purple Monk's Pants",
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  'e75b86d7-23cc-49aa-a95a-5b393087891e': {
    Name: "Book of Magic's Secrets",
    Image: "Weapon_Rare_Mage_Book_Book of Magic's Secrets",
    Class: 'Mage',
    WeaponType: 'Book',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'e855d86d-aeb1-4d9a-a35a-8fb3bad6c778': {
    Name: "Purple Monk's Boots",
    Image: "Gear_Rare_Mage_Boots_Purple Monk's Boots",
    Class: 'Mage',
    GearType: 'Boots',
    Rarity: 'Rare',
  },
  'e9309ced-68c8-4544-856c-ac3b7f27bd2f': {
    Name: 'Lightspirit Gauntlet',
    Image: 'Gear_Legendary_Mage_Gloves_Lightspirit Gauntlet',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Legendary',
  },
  'e95ce582-dfd2-47e1-8059-a03437039ecd': {
    Name: 'Blade of the Ancient',
    Image: 'Weapon_Epic_Hunter_Daggers_Blade of the Ancient',
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  'eab0934d-5ec0-4396-8b0a-1891ff775ffa': {
    Name: 'Silent Gloves',
    Image: 'Gear_Rare_Hunter_Gloves_Silent Gloves',
    Class: 'Hunter',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  'ead52be9-476a-4b00-b3d7-e6ab36a25e2e': {
    Name: 'Battleaxe',
    Image: 'Weapon_Common_Warrior_2-Handed_Battleaxe',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  'edfe4cc2-818a-469a-b48b-2132114101f5': {
    Name: 'Iron Golem Gauntlets',
    Image: 'Gear_Rare_Warrior_Gloves_Iron Golem Gauntlets',
    Class: 'Warrior',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  'ee10874b-14d6-44ac-8d02-86aa3b0acd07': {
    Name: "Villager's Blade",
    Image: "Weapon_Common_Hunter_Daggers_Villager's Blade",
    Class: 'Hunter',
    WeaponType: 'Dagger',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  'ee8ce883-696b-4173-8028-586677d761db': {
    Name: 'Gloves of Harmony',
    Image: 'Gear_Epic_Mage_Gloves_Gloves of Harmony',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Epic',
  },
  'f111b980-0423-480d-b7c7-5b54e2f1da0b': {
    Name: 'Chest of Coraline Lion',
    Image: 'Gear_Legendary_Warrior_Chest_Chest of Coraline Lion',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Legendary',
  },
  'f1729bde-1014-414a-af61-51a8882c530e': {
    Name: 'Slaughtered Dragon Boots',
    Image: 'Gear_Legendary_Hunter_Boots_Slaughtered Dragon Boots',
    Class: 'Hunter',
    GearType: 'Boots',
    Rarity: 'Legendary',
  },
  'f2a0bdc5-8581-43ee-8022-358d46c3e8dc': {
    Name: 'Abyss Pants',
    Image: 'Gear_Epic_Warrior_Pants_Abyss Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Epic',
  },
  'f4d71da7-70e3-47c7-8582-671b1e8021d8': {
    Name: 'Arcane Chest',
    Image: 'Gear_Common_Mage_Chest_Arcane Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Common',
  },
  'f53285f7-b14a-402e-b587-200d1cdd40d4': {
    Name: 'Battle Pants',
    Image: 'Gear_Rare_Warrior_Pants_Battle Pants',
    Class: 'Warrior',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  'f5d475dd-9648-46a5-842c-bc560b3003e6': {
    Name: 'Warhammer',
    Image: 'Weapon_Common_Warrior_2-Handed_Warhammer',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Common',
  },
  'f76f0bf8-e684-4b96-9b97-8cc8720eb153': {
    Name: 'Ornamental Bow',
    Image: 'Weapon_Rare_Hunter_Bow_Ornamental Bow',
    Class: 'Hunter',
    WeaponType: 'Bow',
    GearType: 'Weapon',
    Rarity: 'Rare',
  },
  'f8467e3e-9f69-46e8-80f4-7ee5178288ec': {
    Name: 'Lightspirit Chest',
    Image: 'Gear_Legendary_Mage_Chest_Lightspirit Chest',
    Class: 'Mage',
    GearType: 'Chest',
    Rarity: 'Legendary',
  },
  'f8c2de45-d438-43f3-b20f-e0f739a6ee23': {
    Name: 'Elementalist Pants',
    Image: 'Gear_Rare_Mage_Pants_Elementalist Pants',
    Class: 'Mage',
    GearType: 'Pants',
    Rarity: 'Rare',
  },
  'f931b1a9-e907-49b8-9695-0e1be0bd61e8': {
    Name: 'Bearfang Pants',
    Image: 'Gear_Common_Hunter_Pants_Bearfang Pants',
    Class: 'Hunter',
    GearType: 'Pants',
    Rarity: 'Common',
  },
  'fac7c7d2-0699-4009-8dbc-c28d196f89bf': {
    Name: 'Abominio',
    Image: 'Weapon_Epic_Warrior_2-Handed_Abominio',
    Class: 'Warrior',
    WeaponType: '2HSword',
    GearType: 'Weapon',
    Rarity: 'Epic',
  },
  'fcbfbaad-3aa8-4630-b170-b0a1268394e9': {
    Name: 'Elementalist Gloves',
    Image: 'Gear_Rare_Mage_Gloves_Elementalist Gloves',
    Class: 'Mage',
    GearType: 'Gauntlets',
    Rarity: 'Rare',
  },
  'fed8aca6-65b0-4de6-ba39-181238073d51': {
    Name: 'Wild Hunt Chest',
    Image: 'Gear_Rare_Warrior_Chest_Wild Hunt Chest',
    Class: 'Warrior',
    GearType: 'Chest',
    Rarity: 'Rare',
  },
} as Record<string, {
  Name: string,
  Rarity: string,
  WeaponType?: string
}>;
