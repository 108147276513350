import React from 'react';
import GenericGridItem from '../../GenericItemGrid/GenericGridItem';

export default ({
  title,
  imgSrc,
  description,
  additionalTooltipContent,
}: {
	title: string
	imgSrc: string
	description: string
	additionalTooltipContent?: React.ReactNode
}) => (
  <div className="relative w-full h-full">
    <GenericGridItem
      title={title}
      tooltipContent={
        <div className="flex flex-col max-w-[12rem]">
          <span className="font-bold">{title}</span>
          <span className="text-sm">{description}</span>
          {additionalTooltipContent}
        </div>}
      imgSrc={imgSrc ?? './inventoryItems/Chest.jpeg'}
    />
  </div>
);
