import React from 'react';
import { Outlet } from 'react-router-dom';
import MobileMenu from './MainScreen/MobileMenu';
import { useAppSelector } from '../store/hooks';
import ResponsiveBackground from './ResponsiveBackground';
import useMissionRefresh from '../utils/useMissionRefresh';
import useNotifications from '../utils/useNotifications';
import IncorrectNetworkModalListener from './IncorrectNetworkModalListener';

export default () => {
  const shouldDisplayMobileMenu = useAppSelector((state) => state.ui.shouldDisplayMobileMenu);

  useMissionRefresh();
  useNotifications();

  return (
    <>
      {shouldDisplayMobileMenu && <MobileMenu />}
      <Outlet />
      <ResponsiveBackground />
      <IncorrectNetworkModalListener />
    </>
  );
};
