import { useEffect } from 'react';

import Cookies from 'js-cookie';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { MissionsTimers } from './additionalTypes';

import { TEST_USER_COOKIE } from './consts';
import { missionsExtraReducers } from '../store/slices/missions/missionsReducers';
import modalService from '../modalService/ModalService';

const isFinishDateOverCurrentDate = (startSeconds: number, finishSeconds: number) => {
  const isTestUser = Cookies.get(TEST_USER_COOKIE);

  const missionDuration = isTestUser ? Math.floor((finishSeconds - startSeconds) / 2000) : finishSeconds - startSeconds;

  return new Date() > new Date((startSeconds + missionDuration) * 1000);
};

export default () => {
  const dispatch = useAppDispatch();
  const missionsTimers = useAppSelector((state) => state.missions.missionsTimers);
  const stringifiedMissionTimers = JSON.stringify(missionsTimers); // TODO better deep comparison

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (missionsTimers && Object.keys(missionsTimers).length) {
      const evalFinishedTimers = (missionsTimersArg: MissionsTimers) => {
        const hasTimerFinished = Object.values(missionsTimers).reduce((acc, { start, finish }) => {
          // eslint-disable-next-line no-underscore-dangle
          if (isFinishDateOverCurrentDate(start._seconds, finish._seconds)) {
            return true;
          }

          return acc;
        }, false);

        if (!hasTimerFinished) {
          timeoutId = setTimeout(() => evalFinishedTimers(missionsTimersArg), 1000);
        } else {
          const currentModal = modalService.getCurrentModal();

          if (currentModal?.props.isPurchase) { // TODO find better way to discern between modals
            modalService.closeModal();
          }

          dispatch(missionsExtraReducers.getMissions.reducer(null));
        }
      };

      evalFinishedTimers(missionsTimers);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [stringifiedMissionTimers]);
};
