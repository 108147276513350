import React from 'react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import GenericButton from '../GenericButton';

export default () => {
  const { openConnectModal } = useConnectModal();

  return (
    <>
      <div
        className="w-[80%] aspect-[4] max-w-[24rem] mb-12 mx-4"
        style={{
          backgroundImage: 'url(\'/logo.png\')',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div className="flex flex-col gap-2 text-white mb-12 text-center text-lg mx-4">
        <div className="font-bold">Welcome to our new bArtio 🐻⛓ Testnet Season!</div>
        <div>Your wallet should be able to add, and connect the testnet automatically.</div>
        <div>If not, please visit <a
          className={`
           pr-1
           text-blue-500 hover:text-blue-700
           drop-shadow-inheritColorExtraSmall
          `}
          href="https://docs.berachain.com/learn/connect-to-berachain#how-to-connect-a-wallet-with-berachain-%F0%9F%94%8C"
          target="_blank"
          rel="noreferrer"
        >
          How To Connect A Wallet With Berachain
        </a>
          🔌
        </div>
      </div>
      <GenericButton
        label={'Connect wallet'}
        onClick={openConnectModal}
        buttonStyle="generic"
      />
    </>
  );
};
