import React from 'react';
import { ExternalLinkIconSvg } from '../SvgIcons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { uiSlice } from '../../store/slices/ui/uiSlice';

const labelStyle = 'text-white text-sm sm:text-[1rem] truncate ...';

export default () => {
  const dispatch = useAppDispatch();
  const shouldPlayBgMusic = useAppSelector((state) => state.ui.shouldPlayBgMusic);

  return (
    <div className="flex flex-row items-start justify-around sm:justify-center sm:gap-4 bg-mainBg sm:bg-opacity-40 py-1">
      <div
        className={`
          ${labelStyle}
          cursor-pointer
          hover:opacity-50
        `}
        onClick={() => {
          dispatch(uiSlice.actions.setShouldPlayBgMusicOnInit(!shouldPlayBgMusic));
        }}
      >
        Music {shouldPlayBgMusic ? '🔊' : '🔇'}
      </div>
      {[
        {
          link: 'https://beramonium-chronicles.gitbook.io/gemhunters',
          label: 'Game manual',
          labelMobile: 'Manual',
        },
        {
          link: 'https://beta.beramarket.xyz/collections/0x46B4b78d1Cd660819C934e5456363A359fde43f4',
          label: 'Get Beras',
        },
        {
          link: 'https://www.beramonium.io/staking',
          label: 'Staking',
        },
        {
          link: 'https://discord.gg/beramonium',
          label: 'Discord',
        },
        {
          link: 'https://twitter.com/Beramonium',
          label: 'Twitter',
        },
      ].map(({ link, label, labelMobile }) => (
        <a
          key={label}
          className="flex flex-row items-center break-keep w-max hover:opacity-50"
          target="_blank"
          href={link}
          rel="noreferrer"
        >
          <div className={`sm:hidden ${labelStyle}`}>{labelMobile ?? label}</div>
          <div className={`hidden sm:inline ${labelStyle}`}>{label}</div>
          <div className="ml-0.5 sm:ml-1 w-2.5 sm:w-3.5 h-2.5 sm:h-3.5">
            <ExternalLinkIconSvg />
          </div>
        </a>
      ))}
    </div>
  );
};
