import React from 'react';
import { MISSION_LABEL, MISSION_TYPE } from '../../../utils/consts';
import modalService from '../../../modalService/ModalService';
import MissionPurchaseModal from '../../modals/MissionPurchaseModal';
import GradientBorderWrapper from '../../GradientBorderWrapper';
import GenericButton from '../../GenericButton';
import { useAppSelector } from '../../../store/hooks';
import MissionTimer from './MissionTimer';
import useGetMissionPrice from '../../../utils/useGetMissionPrice';

export default ({
  missionType,
}: {
  missionType: keyof typeof MISSION_TYPE,
}) => {
  const ownedGems = useAppSelector((state) => state.account.ownedGems);
  const missionsTimers = useAppSelector((state) => state.missions.missionsTimers);

  const missionTimer = missionsTimers && missionsTimers[missionType];

  const cost = useGetMissionPrice({
    missionType,
  });

  return (
    <GradientBorderWrapper extraClassname="flex min-h-[222px]">
      <div className="flex flex-col justify-center items-center bg-mainBg rounded-lg text-white gap-4 text-center w-full h-auto">
        <div className="text-xl font-bold">There is no {MISSION_LABEL[missionType]} available</div>
        <div className="text-center">Mission and price reset in: {missionTimer ? <MissionTimer missionTimer={missionTimer}/> : '???'}</div>
        <GenericButton
          onClick={() => modalService.pushModal(<MissionPurchaseModal
            isPurchase
            missionType={missionType}
          />, {
            shouldDisplayCloseButton: false,
          })}
          disabled={!ownedGems || ownedGems < cost}
          label={`Buy for ${cost} gems`}
          icon={<img
            src="/gemSm.png"
            alt="gems"
            className="h-6 object-contain drop-shadow-gems"
          />}
        />
      </div>
    </GradientBorderWrapper>
  );
};
