import React from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';

export default ({
  onConfirmClick,
}: {
	onConfirmClick: () => void
}) => (
  <GenericModalLayout
    titleLabel={'Confirm disenchanting?'}
    onConfirm={() => onConfirmClick()}
    onDecline={() => modalService.closeModal()}
    onConfirmLabel={'Close'}
  >
    <div className="max-w-[30rem] text-center flex flex-col">
      <div>You are about to disenchant selected items!</div>
      <div>Your items will be turned into Dust, and this CANNOT BE UNDONE!</div>
    </div>
  </GenericModalLayout>
);
