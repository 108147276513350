import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';

export default ({
  children,
}: {
	children: React.ReactNode
}) => {
  const navigate = useNavigate();
  const walletAddress = useAppSelector((state) => state.account.walletAddress);
  const isSignedIn = useAppSelector((state) => state.account.isSignedIn);

  useEffect(() => {
    if (!walletAddress || !isSignedIn) {
      navigate('/welcome', {
        state: {
          redirectUrlAfterAuth: window.location.href.replace(window.location.origin, ''),
        },
      });
    }
  }, [walletAddress, isSignedIn]);

  if (!walletAddress || !isSignedIn) {
    return null;
  }

  return children;
};
