import React, {
  useRef, useEffect, useState,
} from 'react';
import { createPortal } from 'react-dom';
import useTailwindBreakpoint from '../utils/useTailwindBreakpoint';
import { eventOpts } from '../utils/consts';

const moveTooltipContainer = ({
  posX, posY, tooltipContainer, tooltipRoot,
}: {
  posX: number,
  posY: number,
  tooltipContainer: HTMLElement,
  tooltipRoot: HTMLElement
}) => {
  const { width, height } = tooltipContainer.getBoundingClientRect();
  const { width: rootWidth, height: rootHeight } = tooltipRoot.getBoundingClientRect();

  const diffX = rootWidth - (posX + width);
  const diffY = rootHeight - (posY + height);

  tooltipContainer.style.left = `${posX + ((diffX < 0) ? diffX : 0)}px`;
  tooltipContainer.style.top = `${posY + ((diffY < 0) ? diffY : 0)}px`;
};

const preventDefaultAction = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
  e.stopImmediatePropagation();

  return false;
};

export default ({
  children,
  content,
  extraClassname,
}: {
  children: React.ReactNode
  content: React.ReactNode
  extraClassname?: string
}) => {
  const tooltipRootRef = useRef(document.getElementById('tooltip-root'));
  const [shouldDisplayTooltip, setShouldDisplayTooltip] = useState<{
    posX: number, posY: number
  } | false>(false);

  const breakpoint = useTailwindBreakpoint();
  const isMobile = breakpoint && ['sm'].includes(breakpoint);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let handleMoveTooltipContainer: (e: any) => void;
    // eslint-disable-next-line no-unused-vars
    let handlePreventDefaultAction: (e: any) => void;

    if (shouldDisplayTooltip) {
      const tooltipContainer = document.getElementById('tooltip-container') as HTMLElement;

      // initial position setup
      if (tooltipContainer && tooltipRootRef.current) {
        moveTooltipContainer({
          posX: shouldDisplayTooltip.posX,
          posY: shouldDisplayTooltip.posY,
          tooltipContainer,
          tooltipRoot: tooltipRootRef.current,
        });
      }

      if (!isMobile) {
        // function ref for removeEventListener
        handleMoveTooltipContainer = ({ x: posX, y: posY }) => {
          moveTooltipContainer({
            posX,
            posY,
            tooltipContainer,
            tooltipRoot: tooltipRootRef.current as HTMLElement, // for some reason it only needs assertion here 🤷‍
          });
        };

        window.addEventListener('mousemove', handleMoveTooltipContainer);
      } else {
        // function ref for removeEventListener
        handlePreventDefaultAction = (e) => {
          preventDefaultAction(e);
          setShouldDisplayTooltip(false);
        };

        window.addEventListener('click', handlePreventDefaultAction, true);
        window.addEventListener('touchstart', handlePreventDefaultAction, eventOpts);
      }
    }

    return () => {
      if (shouldDisplayTooltip) {
        window.removeEventListener('mousemove', handleMoveTooltipContainer);
        window.removeEventListener('click', handlePreventDefaultAction, true);
        window.removeEventListener('touchstart', handlePreventDefaultAction, eventOpts);
      }
    };
  }, [shouldDisplayTooltip, isMobile]);

  return (
    <div
      className={`relative ${extraClassname}`}
      onMouseEnter={({ clientX, clientY }) => {
        if (!isMobile && tooltipRootRef.current) {
          setShouldDisplayTooltip({ posX: clientX, posY: clientY });
        }
      }}
      onMouseLeave={() => {
        if (!isMobile && tooltipRootRef.current) {
          setShouldDisplayTooltip(false);
        }
      }}
    >
      {isMobile && (
        <div
          className={`
            absolute -top-2 -right-2
            z-[1] rounded-full font-bold flex justify-center items-center p-2.5 w-4 h-4
            border-dashed border-[1px] border-transparent border-t-yellow-500 border-r-yellow-500
            text-white bg-black bg-opacity-20 backdrop-blur-md cursor-pointer
          `}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            const { clientX, clientY } = e;

            setShouldDisplayTooltip({ posX: clientX, posY: clientY });
          }}
        >
          <span className="text-xs rotate-12">
            ❕
          </span>
        </div>
      )}
      {children}
      {shouldDisplayTooltip && tooltipRootRef.current && (
        createPortal(
          <div
            className="absolute p-4"
            id="tooltip-container"
          >
            <div className='p-2 bg-black bg-opacity-80 z-50 text-white backdrop-blur-lg rounded-md max-w-[24rem] overflow-hidden'>
              {/* TODO to be removed? */}
              {/* {isMobile && ( */}
              {/*  <div className="flex items-center justify-center border-dashed border-[1px] border-yellow-500 w-fit px-1 rounded-md ml-auto mb-2"> */}
              {/*    <span className="text-xs font-bold italic">Click anywhere to close</span> */}
              {/*    <span className="text-lg font-bold px-1 rotate-[25deg]">!</span> */}
              {/*  </div> */}
              {/* )} */}
              {content}
            </div>
          </div>,
          tooltipRootRef.current,
        )
      )}
    </div>
  );
};
