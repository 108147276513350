import React from 'react';

export default ({
  children,
  extraClassname,
  disabled,
  shouldChangeBorderColorOnHover,
  shouldIncreaseScaleOnHover,
  shouldDisplayPointerCursor,
  borderWidthInPx = 3,
  withImageBgGlow,
  onClick,
}: {
  children: React.ReactNode
  extraClassname?: string
  disabled?: boolean
  shouldChangeBorderColorOnHover?: boolean
  shouldIncreaseScaleOnHover?: boolean
  shouldDisplayPointerCursor?: boolean
  borderWidthInPx?: number,
  withImageBgGlow?: string
  onClick?: () => void
}) => (
  <div className={`
      relative
      bg-gradient-to-b from-gray-300/70 to-gray-700/70 rounded-lg shadow-lg
      ${!disabled && shouldChangeBorderColorOnHover && 'hover:from-gray-200 hover:to-gray-200'}
      ${shouldDisplayPointerCursor && 'cursor-pointer'}
      ${shouldIncreaseScaleOnHover && 'hover:scale-110 transition-transform'} 
      ${extraClassname}
    `}
    style={{
      padding: `${borderWidthInPx}px`,
    }}
    onClick={onClick}
  >
    {children}
    {withImageBgGlow && (
      <div
        style={{
          backgroundImage: `url('${withImageBgGlow}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          // transform: 'scale(1.2)',
        }}
        className="absolute left-0 top-0 right-0 bottom-0 bg-cover blur-2xl -z-10"
      />
    )}
  </div>
);
