import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { BERA_SPECS, BERA_SPEC_COLOR_MAP, SEARCH_PARAMS } from '../../../utils/consts';

const classDescriptionMap = {
  [BERA_SPECS.tank]: 'warrior with a sword and a shield',
  [BERA_SPECS.berserker]: 'warrior with a 2-handed weapon',
  [BERA_SPECS.healer]: 'mage with a staff',
  [BERA_SPECS.spellcaster]: 'mage with a book of spells',
  [BERA_SPECS.marksman]: 'hunter with a bow',
  [BERA_SPECS.rogue]: 'hunter with daggers',
  [BERA_SPECS.unique]: '1 of 1',
};

export default () => {
  const [filterSearchParams] = useSearchParams();
  const filterSearchParamsValue = filterSearchParams.get(SEARCH_PARAMS.filter);

  return (
    <div className="flex flex-col justify-center items-center text-white h-full text-2xl gap-6 py-4 my-auto">
      <div className="flex flex-row">
        {filterSearchParamsValue ? (
          <>
            No
            <div
              className="text-2xl font-bold px-1.5 drop-shadow-inheritColorExtraSmall first-letter:uppercase"
              style={{
                color: BERA_SPEC_COLOR_MAP[BERA_SPECS[filterSearchParamsValue as keyof typeof BERA_SPECS]],
              }}
            >
              {filterSearchParamsValue}
            </div>
            available...
          </>
        ) : (
          <>
            No Beras available...
          </>
        )}
      </div>
      <div className="flex flex-col items-center text-xl gap-2">
        {filterSearchParamsValue ? (
          <>
            Look for a
            <div
              className="text-xl font-bold px-1.5 drop-shadow-inheritColorExtraSmall break-words"
            >
              {classDescriptionMap[BERA_SPECS[filterSearchParamsValue as keyof typeof BERA_SPECS]]}
            </div>
            <div className="flex flex-row">
              on
              <a
                className="text-xl font-bold px-1.5 text-blue-200 hover:text-blue-400 drop-shadow-inheritColorExtraSmall first-letter:uppercase"
                target="_blank"
                href={`https://opensea.io/collection/beramonium-chronicles-genesis?search[stringTraits][0][name]=Spec&search[stringTraits][0][values][0]=${filterSearchParamsValue}`}
                rel="noreferrer"
              >OpenSea</a>
              !
            </div>
          </>
        ) : (
          <div className="flex flex-row">
            Go get some on
            <a
              className="text-xl font-bold px-1.5 text-blue-200 hover:text-blue-400 drop-shadow-inheritColorExtraSmall first-letter:uppercase"
              target="_blank"
              href="https://beta.beramarket.xyz/collections/0x46B4b78d1Cd660819C934e5456363A359fde43f4"
              rel="noreferrer"
            >Beramarket</a>
            !
          </div>
        )}
      </div>
      <div className="text-center w-[20rem] text-xl">
        Remember to stake your Beras on bArtio @
        <a
          className={`
           text-white text-xl text-center
           font-bold
           px-1.5
           drop-shadow-inheritColorExtraSmall
           text-orange-100
           hover:text-orange-300
         `}
          target="_blank"
          href="https://www.beramonium.io/staking" rel="noreferrer"
        >beramonium.io/staking</a>
      </div>
    </div>
  );
};
