import React from 'react';
import { createExtraReducer, ExtraReducers } from '../../parseExtraReducersCallbacks';
import { ExtraReducerGenericArg, GetLeaderboardEntriesAPIResponse } from '../../../utils/additionalTypes';
import { fetchGemhuntersApi } from '../../../utils/fetcher';
import fetcherReducerErrorHandler from '../../../utils/fetcherReducerErrorHandler';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../../components/modals/ErrorModal';
import endpointUrl from '../../../utils/endpointUrl';
import { LeaderboardState } from './leaderboardSlice';
import { MESSAGE_VALUES } from '../../../utils/consts';

// eslint-disable-next-line import/prefer-default-export
export const leaderboardExtraReducers: ExtraReducers = {
  getLeaderboardEntries: createExtraReducer<LeaderboardState, LeaderboardState, ExtraReducerGenericArg>({
    reducer: {
      name: 'leaderboard/getLeaderboardEntries',
      callbackFn: async (extraReducerGenericArg, { dispatch, rejectWithValue }) => {
        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/leaderboard`, {
            method: 'GET',
          }) as GetLeaderboardEntriesAPIResponse;

          const {
            Leaderboard, currentUser, totalCount, message,
          } = res;

          if (Array.isArray(Leaderboard) && currentUser && message === MESSAGE_VALUES.success) { // TODO better validation
            return {
              leaderboardEntries: Leaderboard,
              currentUserEntry: currentUser,
              totalPlayerCount: totalCount,
              isFetchingData: false,
            };
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          if (extraReducerGenericArg?.failImplicit) {
            return rejectWithValue(null);
          }

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload }) => ({
      ...payload,
    }),
    pendingCallback: (state) => {
      state.isFetchingData = true;
    },
    rejectedCallback: (state, { payload }) => {
      state.isFetchingData = false;

      if (payload) {
        modalService.pushModal(<ErrorModal error={payload} />);
      }
    },
  }),
};
