import React from 'react';
import { BerasToDisplay, BeraToDisplay, Mission } from '../../../utils/additionalTypes';
import GradientBorderWrapper from '../../GradientBorderWrapper';
import MissionChallengeItem from '../MissionChallengeItem';
import BeraCardSmall from './BeraCardSmall';
import { LOOT_IMAGE_MAP, MISSION_LABEL } from '../../../utils/consts';
import { missionsExtraReducers } from '../../../store/slices/missions/missionsReducers';
import { useAppDispatch } from '../../../store/hooks';
import evalMissionSuccessChance from '../../../utils/evalMissionSuccessChance';
import getCounteredChallenges from '../../../utils/getCounteredChallenges';
import MissionStartButton from './MissionStartButton';
import getMissionBgUrl from '../../../utils/getMissionBgUrl';
import getMissionSuccessChanceColor from '../../../utils/getMissionSuccessChanceColor';

export default ({
  displayRewardAndStartButton,
  selectedBeras,
  removeFromSelectedBeras,
  mission,
}: {
  displayRewardAndStartButton?: boolean
  selectedBeras: BerasToDisplay
  // eslint-disable-next-line no-unused-vars
  removeFromSelectedBeras: (arg: BeraToDisplay) => void
  mission: Mission
}) => {
  const dispatch = useAppDispatch();
  const selectedBeraIds = selectedBeras.map(({ id }) => id);
  const missionBgImage = getMissionBgUrl(mission.title);
  const missionSuccessChance = evalMissionSuccessChance({
    mission,
    selectedBeras,
  });

  return (
    <div className="flex flex-col items-center lg:mt-8 lg:mr-8 lg:mb-8">
      <GradientBorderWrapper>
        <div
          className="relative rounded-md before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 before:bg-black before:bg-opacity-40 before:rounded-md"
          style={{
            backgroundImage: `url(${missionBgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="relative flex flex-col rounded-lg p-4 text-white gap-4 lg:w-96 z-10">
            <div>
              <span className="text-2xl font-title font-bold uppercase">{MISSION_LABEL[mission.type]}:</span>
              <span className="text-2xl font-title"> {mission.title}</span>
            </div>
            <div className="text-lg font-bold">Team</div>
            <div className="flex flex-row w-full justify-between self-center max-w-md">
              {selectedBeras.map((bera) => (
                <BeraCardSmall
                  key={bera.id}
                  bera={bera}
                  additionalProps={{
                    className: '!w-[4.2rem] !h-[4.2rem] sm:!w-[4.6rem] sm:!h-[4.6rem]',
                    onClick: () => removeFromSelectedBeras(bera),
                  }}
                />
              ))}
              {selectedBeras.length < 4 && Array(4 - selectedBeras.length).fill(0).map((_, index) => (
                <GradientBorderWrapper
                  key={index}
                  disabled
                >
                  <div className={`
                    bg-gray-900 rounded-md
                    w-[4.2rem] h-[4.2rem] sm:w-[4.6rem] sm:h-[4.6rem]
                  `}
                  />
                </GradientBorderWrapper>
              ))}
            </div>
            <div className="text-lg font-bold">Mission Enemies</div>
            <div className="flex flex-row gap-4">
              {getCounteredChallenges({
                selectedBeras,
                missionChallenges: mission.Challenges,
              }).map((missionChallenge, index) => (
                <MissionChallengeItem
                  key={index}
                  missionChallenge={missionChallenge}
                  extraClassname="w-16 h-16"
                />
              ))}
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex-grow">
                <div className="text-lg font-bold">Info</div>
                <div className="text-sm line-clamp-2">
                  {mission.description}
                </div>
              </div>
              <div className="flex-grow min-w-[7.5rem]">
                <div className="text-lg font-bold">Success Chance</div>
                <div className={`
                  text-4xl font-bold drop-shadow-inheritColorExtraSmall
                  ${getMissionSuccessChanceColor(missionSuccessChance)}
                `}
                >
                  {missionSuccessChance}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </GradientBorderWrapper>
      {displayRewardAndStartButton && (
        <div className="flex flex-col h-full w-full overflow-hidden">
          <div className="flex flex-col justify-center items-center w-24 h-full w-full mt-6 sm:mt-4">
            <span className="font-bold text-yellow-500 text-4xl font-title uppercase z-20 drop-shadow-inheritColorSmall">REWARD</span>
            <div className="relative w-full h-full min-h-[60px] max-h-[150px]">
              <div
                style={{
                  backgroundImage: `url('/lootSm/${LOOT_IMAGE_MAP[mission.reward as keyof typeof LOOT_IMAGE_MAP]}')`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className="absolute w-full h-full bg-cover z-10 animate-increaseScaleLoop100110"
              />
              <div
                style={{
                  backgroundImage: `url('/lootSm/${LOOT_IMAGE_MAP[mission.reward as keyof typeof LOOT_IMAGE_MAP]}')`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  transform: 'scale(1.4)',
                }}
                className="absolute w-full h-full bg-cover blur-md"
              />
            </div>
            <span className="font-bold text-lg text-green-500 z-20 drop-shadow-inheritColorSmall">{mission.reward}</span>
            <MissionStartButton
              disabled={!selectedBeraIds.length}
              onClick={() => dispatch(missionsExtraReducers.startMission.reducer({
                missionId: mission.id,
                beraIds: selectedBeraIds,
              }))}
            />
          </div>
        </div>
      )}
    </div>
  );
};
