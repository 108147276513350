import React from 'react';
import GenericModalLayout from './GenericModalLayout';

export default ({
  switchNetworkCallback,
}: {
  switchNetworkCallback: () => void
}) => (
  <GenericModalLayout
    titleLabel={'Incorrect network selected!'}
    onConfirm={switchNetworkCallback}
    onConfirmLabel={'Switch to bArtio 🐻⛓'}
  >
    <div className="max-w-[34rem] text-center flex flex-col gap-2">
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <div className={'font-bold'}>It seems that you've disconnected from the bArtio testnet.</div>
      <div>To ensure correct operation of Gemhunters, please switch back to bArtio testnet!</div>
    </div>
  </GenericModalLayout>
);
