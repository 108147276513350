import React from 'react';

export default ({
  label,
  variantSmall,
}: {
    label: string | number
    variantSmall?: boolean
}) => (
  <div className="w-auto flex items-center justify-center">
    {!variantSmall && (
      <>
        <div className="basis-full h-1 bg-gradient-to-r from-transparent to-gray-100/40" />
        <div className="h-[2rem] aspect-square border-gray-100/40 border-l-2 rounded-full -mr-4" />
      </>
    )}
    <div className={`
        min-w-max font-title text-white ${variantSmall ? 'text-lg' : 'text-xl'} drop-shadow-inheritColorExtraSmall
    `}
    >
      {label}
    </div>
    <div className={`
        ${variantSmall ? 'h-[1.5rem] ml-[-0.6rem]' : 'h-[2rem] -ml-4'} 
        aspect-square border-gray-100/40 border-r-2 rounded-full 
    `}
    />
    <div className="basis-full h-1 bg-gradient-to-r from-gray-100/40 to-transparent" />
  </div>
);
