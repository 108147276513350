import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from './consts';

export default function <T extends Array<Record<string, any>>> (arrayToSort: T, {
  sortBy,
}: {
  sortBy: string
}): T {
  const [sortBySearchParams] = useSearchParams();
  const sortBySearchParamsValue = sortBySearchParams.get(SEARCH_PARAMS.sortBy);
  const sortByOrder = sortBySearchParamsValue?.split('.')[1];

  if (sortByOrder) {
    arrayToSort.sort((record, newRecord) => {
      const value = record[sortBy];
      const newValue = newRecord[sortBy];

      if (sortByOrder === 'ascending') {
        return value - newValue;
      }

      if (sortByOrder === 'descending') {
        return newValue - value;
      }

      return 0;
    });
  }

  return arrayToSort;
}
