import React from 'react';

export default ({
  onClick,
  label,
}: {
	onClick: () => void
	label: string
}) => (
  <div
    className={`
    	relative
    	max-w-[160px]
    	flex justify-center items-center w-full
    	px-4 py-1 bg-gradient-to-r from-transparent from-1% via-amber-100/50 group-hover:via-amber-200 to-99% to-transparent
    	bg-blur
    	cursor-pointer
    	rounded
    	before:opacity-0 group-hover:before:opacity-100 before:-z-10
    	before:absolute before:absolute before:w-8 before:h-8
    	before:blur-lg
    	before:bg-amber-400
    `}
    onClick={onClick}
  >
    <div className="flex flex-row items-center justify-evenly p-0.5 bg-mainBg rounded-lg w-full text-center text-white font-title">
      <img
        src="/gemSm.png"
        alt="Gems"
        className="h-4 object-contain drop-shadow-gems"
      />
      <div className="text-sm">𝑥</div>
      <div>{label}</div>
    </div>
  </div>
);
