import React from 'react';
import { LeaderboardEntry as LeaderboardEntryType } from '../../../utils/additionalTypes';
import LeaderboardEntry from './LeaderboardEntry';

export default ({
  currentUserEntry,
  overNthCutoffValue,
}: {
  currentUserEntry: LeaderboardEntryType
  overNthCutoffValue: number
}) => {
  if (!currentUserEntry) {
    return null;
  }

  const shouldDisplayOver50Entry = currentUserEntry.rank > overNthCutoffValue;

  if (!shouldDisplayOver50Entry) {
    return null;
  }

  const shouldDisplayDivider = currentUserEntry.rank > overNthCutoffValue + 1;

  return (
    <>
      {shouldDisplayDivider && (
        <>
          <div className="text-xl text-white py-2 px-4">⬇</div>
          <div className="text-xl text-white py-2"/>
          <div className="text-xl text-white py-2 px-4 text-right">⬇</div>
        </>
      )}
      <LeaderboardEntry
        index={currentUserEntry.rank - 1}
        leaderboardEntry={currentUserEntry}
      />
    </>
  );
};
