import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { Bera, InventoryItem, ShopItem } from '../../utils/additionalTypes';

const style = 'text-white text-xl sm:text-3xl text-center px-4';

export default () => {
  const isSignedIn = useAppSelector((state) => state.account.isSignedIn);

  const ownedBeras = useAppSelector<Array<Bera> | null>((state) => state.beras.ownedBeras);
  const ownedItems = useAppSelector((state) => state.inventory.ownedItems) as Array<InventoryItem>;
  const availableMissions = useAppSelector((state) => state.missions.availableMissions);
  const shopItems = useAppSelector((state) => state.shop.shopItems) as Array<ShopItem>;

  const dataToLoad = [ownedBeras, ownedItems, availableMissions, shopItems];
  const loadingProgress = (dataToLoad.filter((data) => data).length / dataToLoad.length) * 100;

  if (!isSignedIn) {
    return <div className={style}>{'In a moment you\'ll be asked to sign-in with your wallet!'}</div>;
  }

  return (
    <div className="flex flex-col items-center w-full px-8 max-w-[64rem]">
      <div
        className="w-[80%] aspect-[4] max-w-[24rem] mb-12 mx-4"
        style={{
          backgroundImage: 'url(\'/logo.png\')',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div className={style}>Loading!</div>
      <div className={`
        relative
        mt-8 sm:mt-16
        h-12 w-full
        bg-gradient-to-r from-transparent from-1% via-gray-100/40 to-99% to-transparent
      `}
      >
        <div
          className={`
              transition-all
              absolute left-0 top-0 bottom-0
              bg-gradient-to-r from-transparent from-1% via-95% via-violet-600 to-99% to-transparent
              drop-shadow-loadingBar
          `}
          style={{
            width: `${loadingProgress}%`,
          }}
        />
      </div>
    </div>
  );
};
