import React from 'react';

export default ({
  headerElement,
  children,
  extraClassName,
}: {
	headerElement: React.ReactNode,
	children: React.ReactNode
	extraClassName?: string
}) => (
  <div className="flex flex-col w-full max-w-6xl min-w-[330px] justify-center">
    {headerElement}
    <div className={`
		flex flex-col min-h-0 w-full h-full
		border border-2 border-t-0 border-white border-opacity-20 rounded-b-lg rounded-t-[0.4rem]
		bg-mainBg bg-opacity-60
		overflow-auto
		sm:h-[50rem] sm:min-h-[43rem]
		${extraClassName}
	`}
    >
      {children}
    </div>
  </div>
);
