import React from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';

export default () => (
  <GenericModalLayout
    titleLabel="Squad retired!"
    // titleRibbonStyle="green"
    onConfirm={() => modalService.closeModal()}
    onConfirmLabel="Continue"
  >
    <div className="text-lg text-center">{'Your warriors are back in camp and awaiting orders!'}</div>
  </GenericModalLayout>
);
