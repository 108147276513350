import React from 'react';
import { BeraNewAttributes, SetBeraNewAttributeType } from './useBeraAttributes';
import { InventoryItem } from '../../../utils/additionalTypes';
import InventoryGridItem from '../Inventory/InventoryGridItem';
import { EQUIPMENT_ATTRIBUTES } from '../../../utils/consts';

const InventorySlot = ({
  slotLabel,
  extraClassname,
  itemToDisplay,
  onClick,
}: {
  slotLabel: string
  extraClassname?: string
  itemToDisplay: InventoryItem | null
  onClick: () => void
}) => (
  <div className={`
    flex flex-col items-center gap-2
    ${extraClassname}
  `}
  >
    {itemToDisplay ? (
      <div
        className="w-[3.5rem] h-[3.5rem] cursor-pointer"
        onClick={onClick}
      >
        <InventoryGridItem item={itemToDisplay} />
      </div>
    ) : (
      <div className={`
        bg-gray-900 rounded-md opacity-10
        w-[3.5rem] h-[3.5rem]
        inset
      `}
      />
    )}
    <div className="text-white font-bold font-title text-xs uppercase drop-shadow-blackBackground">{slotLabel}</div>
  </div>
);

export default ({
  getBeraNewAttributes,
  setBeraNewAttribute,
}: {
  getBeraNewAttributes: () => BeraNewAttributes
  setBeraNewAttribute: SetBeraNewAttributeType
}) => (
  <div
    className="absolute top-0 right-0 bottom-0 left-0 flex justify-between"
  >
    <div className="flex flex-col justify-evenly lg:justify-around">
      <InventorySlot
        extraClassname="ml-6"
        slotLabel="Helmet"
        itemToDisplay={getBeraNewAttributes()[EQUIPMENT_ATTRIBUTES.Helmet]}
        onClick={() => setBeraNewAttribute({
          type: EQUIPMENT_ATTRIBUTES.Helmet,
          value: null,
        })}
      />
      <InventorySlot
        slotLabel="Gauntlets"
        itemToDisplay={getBeraNewAttributes()[EQUIPMENT_ATTRIBUTES.Gauntlets]}
        onClick={() => setBeraNewAttribute({
          type: EQUIPMENT_ATTRIBUTES.Gauntlets,
          value: null,
        })}
      />
      <InventorySlot
        extraClassname="ml-6"
        slotLabel="Pants"
        itemToDisplay={getBeraNewAttributes()[EQUIPMENT_ATTRIBUTES.Pants]}
        onClick={() => setBeraNewAttribute({
          type: EQUIPMENT_ATTRIBUTES.Pants,
          value: null,
        })}
      />
    </div>
    <div className="flex flex-col justify-evenly lg:justify-around">
      <InventorySlot
        extraClassname="mr-6"
        slotLabel="Chest"
        itemToDisplay={getBeraNewAttributes()[EQUIPMENT_ATTRIBUTES.Chest]}
        onClick={() => setBeraNewAttribute({
          type: EQUIPMENT_ATTRIBUTES.Chest,
          value: null,
        })}
      />
      <InventorySlot
        slotLabel="Weapon"
        itemToDisplay={getBeraNewAttributes()[EQUIPMENT_ATTRIBUTES.Weapon]}
        onClick={() => setBeraNewAttribute({
          type: EQUIPMENT_ATTRIBUTES.Weapon,
          value: null,
        })}
      />
      <InventorySlot
        extraClassname="mr-6"
        slotLabel="Boots"
        itemToDisplay={getBeraNewAttributes()[EQUIPMENT_ATTRIBUTES.Boots]}
        onClick={() => setBeraNewAttribute({
          type: EQUIPMENT_ATTRIBUTES.Boots,
          value: null,
        })}
      />
    </div>
  </div>
);
