import { PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { initialState, UIState } from './uiSlice';
import bgMusicService from '../../../utils/BgMusicService';
import { PLAY_BG_MUSIC_ON_INIT_COOKIE } from '../../../utils/consts';

// eslint-disable-next-line import/prefer-default-export
export const uiReducers = {
  setShownSeasonPassModalTrue: (state: UIState) => {
    state.hasShownSeasonPassModal = true;
  },
  displayMobileMenu: (state: UIState) => {
    state.shouldDisplayMobileMenu = true;
  },
  hideMobileMenu: (state: UIState) => {
    state.shouldDisplayMobileMenu = false;
  },
  setShouldPlayBgMusicOnInit: (state: UIState, { payload }: PayloadAction<boolean>) => {
    if (payload) {
      Cookies.set(PLAY_BG_MUSIC_ON_INIT_COOKIE, 'true', {
        expires: 365,
      });

      bgMusicService.playBgMusic();
    } else {
      Cookies.set(PLAY_BG_MUSIC_ON_INIT_COOKIE, 'false');

      bgMusicService.stopBgMusic();
    }

    state.shouldPlayBgMusic = payload;
  },
  reset: () => initialState,
};
