import React from 'react';
import { useSignTypedData, useChainId } from 'wagmi';
import GenericButton from '../../GenericButton';
import { useAppDispatch } from '../../../store/hooks';
import { inventoryExtraReducers } from '../../../store/slices/inventory/inventoryReducers';
import modalService from '../../../modalService/ModalService';
import DisenhchantmentConfirmationModal from '../../modals/DisenhchantmentConfirmationModal';
import trackJSService from '../../../utils/trackJSService';

export default ({
  selectedItems,
  allSelected,
  onSelectAll,
  onDeselectAll,
  hasItemsToDisplay,
}: {
    selectedItems: Array<string>
    allSelected: boolean
    onSelectAll: () => void
    onDeselectAll: () => void
    hasItemsToDisplay: boolean
}) => {
  const dispatch = useAppDispatch();
  const chainId = useChainId();

  const itemsToDisenchant = selectedItems.map((itemId) => ({ ItemID: itemId }));

  const { signTypedData } = useSignTypedData({
    mutation: {
      onSuccess: async (data) => {
        dispatch(inventoryExtraReducers.disenchantItems.reducer({
          itemsToDisenchant,
          signature: data,
        }));
        onDeselectAll();
      },
      onError: trackJSService.trackWithUserInfo('disenchant'),
    },
  });

  const onDisenchantClick = () => {
    modalService.pushModal(<DisenhchantmentConfirmationModal onConfirmClick={() => signTypedData({
      domain: {
        name: 'Gemhunters',
        version: '1',
        chainId,
      },
      types: {
        Disenchant: [
          { name: 'items', type: 'DisenchantItem[]' },
        ],
        DisenchantItem: [
          { name: 'ItemID', type: 'string' },
        ],
      },
      primaryType: 'Disenchant',
      message: {
        items: itemsToDisenchant,
      },
    })}
    />);
  };

  return (
    <div className="flex flex-row gap-4">
      <GenericButton
        label={hasItemsToDisplay && allSelected ? 'Deselect all' : 'Select all'}
        onClick={allSelected ? onDeselectAll : onSelectAll}
        disabled={!hasItemsToDisplay}
      />
      <GenericButton
        label={`Disenchant selected (${selectedItems.length})`}
        buttonStyle="gold"
        disabled={!hasItemsToDisplay}
        onClick={onDisenchantClick}
      />
    </div>
  );
};
