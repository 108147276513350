const convertHexToRGBA = (hexCode: string, opacity: number = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    // eslint-disable-next-line no-param-reassign
    opacity /= 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export default (str: string, opacity: number) => {
  // Generate hash from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to a hexadecimal color
  let color = '#';
  for (let j = 0; j < 3; j++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (j * 8)) & 0xFF; // Extract a byte
    color += (`00${value.toString(16)}`).substr(-2); // Convert to hex and append
  }

  return convertHexToRGBA(color, opacity);
};
