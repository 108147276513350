import React from 'react';
import GenericModalLayout from './GenericModalLayout';
import modalService from '../../modalService/ModalService';
import { useAppDispatch } from '../../store/hooks';
import { uiSlice } from '../../store/slices/ui/uiSlice';

export default () => {
  const dispatch = useAppDispatch();

  return (
    <GenericModalLayout
      titleLabel="♫ Background music ♬"
      onConfirm={() => {
        dispatch(uiSlice.actions.setShouldPlayBgMusicOnInit(true));
        modalService.closeModal();
      }}
      onDecline={() => {
        dispatch(uiSlice.actions.setShouldPlayBgMusicOnInit(false));
        modalService.closeModal();
      }}
      extraClassname="text-center gap-3 max-w-[24rem]"
    >
      <div>Would you care for some atmospheric background music?</div>
      <div>{'If so, make sure that your device volume\'s up! 🔊'}</div>
      <div className="font-bold">Your selection will be saved and will automatically load when the game is launched again!</div>
      <div>You can turn the music on and off at any time, by clicking the Music 🔊 button on the bottom of the screen</div>
      <img
        src="/musicTutorial.png"
        alt="Gems"
        className="mr-1 h-20 object-contain rounded"
      />
    </GenericModalLayout>
  );
};
