import React from 'react';
import modalService from '../../modalService/ModalService';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { shopExtraReducers } from '../../store/slices/shop/shopReducers';
import GenericModalLayout from './GenericModalLayout';
import GenericGridItem from '../GenericItemGrid/GenericGridItem';
import { ShopItem } from '../../utils/additionalTypes';

export default ({
  item,
}: {
  item: ShopItem
}) => {
  const dispatch = useAppDispatch();
  const ownedGems = useAppSelector((state) => state.account.ownedGems);
  const notEnoughGems = ownedGems ? item.ItemPrice > ownedGems : true;

  return (
    <GenericModalLayout
      titleLabel={`Buy ${item.ItemName} ?`}
      onConfirm={() => dispatch(shopExtraReducers.buyItem.reducer(item))}
      onDecline={() => modalService.closeModal()}
      isConfirmDisabled={notEnoughGems}
    >
      <div className="h-36 w-36 mb-6">
        <GenericGridItem
          title={item.ItemName}
          imgSrc={item.ItemImage.kind === 'name' ? `/inventoryItems/${item.ItemImage.value}.jpeg` : item.ItemImage.value}
          tooltipContent={<div>{item.ItemName}</div>}
          withGlow
        />
      </div>
      <div className="flex flex-row gap-2 items-center">
        <div className="text-lg">Cost: {item.ItemPrice} gems</div>
        <img
          src="/gemSm.png"
          alt="Gems"
          className="h-6 object-contain drop-shadow-gems"
        />
      </div>
      {notEnoughGems && <div className="text-lg font-bold mt-6">Not enough gems...</div>}
    </GenericModalLayout>
  );
};
