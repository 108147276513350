import { TrackJS } from 'trackjs';
import { getWalletClient } from '@wagmi/core';
import wagmiConfig from './wagmiConfig';

const TrackJSService = () => ({
  installTrackJS: () => {
    // eslint-disable-next-line no-constant-condition
    if (process.env.REACT_APP_VERCEL_ENV === 'production' || 'preview') {
      TrackJS.install({
        token: 'ad56296b08c7404792d979d562a2aaf8',
        application: 'production',
      });
    }
  },
  trackWithUserInfo: (label?: string, metadata?: any) => async (...error: any[]) => {
    const client = await getWalletClient(wagmiConfig);
    const { account, chain: { id } } = client;

    TrackJS.track({
      label,
      metadata,
      account,
      chainId: id,
      error,
    });
  },
});

const trackJSService = TrackJSService();

export default trackJSService;
