import React from 'react';
import { BeraToDisplay } from '../../../utils/additionalTypes';
import GradientBorderWrapper from '../../GradientBorderWrapper';
import ImageWithLoader from '../../ImageWithLoader';
import TooltipTrigger from '../../TooltipTrigger';
import {
  BERA_SPECS,
  BERA_SPEC_COLOR_MAP,
  BERA_CLASS_ICON_MAP,
  BERA_UNIQUE_CLASS_ICON,
  ITEM_RARITY,
  ITEM_RARITY_COLOR_MAP, EQUIPMENT_ATTRIBUTES,
} from '../../../utils/consts';

export default ({
  bera,
  additionalProps,
}: {
  bera: BeraToDisplay
  additionalProps?: React.ComponentProps<any>
}) => (
  <TooltipTrigger
    extraClassname="w-full h-full"
    content={<div className="relative flex flex-col max-w-lg text-sm">
      <div className="flex flex-row items-center">
        <span className="mr-1 font-bold">Class: </span>
        <span className="whitespace-nowrap truncate mr-1">
          {bera.attributes.Class.toString()}
        </span>
        <div className="flex items-center">
          <div
            className="w-3.5 h-3.5"
            style={{
              backgroundImage: `url(${BERA_CLASS_ICON_MAP[bera.attributes.Class.toString()] ?? BERA_UNIQUE_CLASS_ICON}`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
      </div>
      <div className="flex items-center">
        <span className="font-bold mr-1">Gear score: </span>
        <span className="mr-1">{bera.gearScore}</span>
        <div
          className="w-3 h-3"
          style={{
            backgroundImage: 'url(\'/pngIcons/crossedSwords.png\')',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </div>
      {Object.values(EQUIPMENT_ATTRIBUTES).map((trait) => (
        <div key={trait}>
          <span className="font-bold">{trait}: </span>
          <span
            style={{
              color: ITEM_RARITY_COLOR_MAP[ITEM_RARITY[bera.attributes[trait]] as keyof typeof ITEM_RARITY_COLOR_MAP],
            }}
          >{bera.attributes[trait]}</span>
        </div>
      ))}
    </div>}
  >
    <GradientBorderWrapper
      extraClassname={`w-full h-full ${bera.inMission && 'opacity-30'}`}
      disabled={bera.inMission}
      shouldIncreaseScaleOnHover={!bera.inMission && !bera.isUnique}
      shouldDisplayPointerCursor={!bera.inMission && !bera.isUnique}
      borderWidthInPx={0}
    >
      <div
        {...additionalProps}
        className={`
          relative w-full h-full 
          border-[1px] border-white border-b-transparent
          overflow-hidden rounded-md
          ${additionalProps?.className}
        `}
      >
        <ImageWithLoader src={bera.imageUrl}/>
        <div className={`
          absolute top-1 left-1 right-1
          flex flex-row items-center justify-between
          border-[1px] border-white
          text-white backdrop-blur-lg cursor-pointer
          bg-black bg-opacity-20
          p-1 rounded shadow-md
          text-[1.1rem]
        `}
        >
          <span className="text-white pb-0.5 whitespace-nowrap">Beramonium </span>
          <span className="font-bold uppercase text-white pb-0.5 whitespace-nowrap">#{bera.id}</span>
        </div>
        <div className={`
          absolute bottom-0 left-1 right-1 flex flex-col
          border-dashed border-[1px] border-white border-b-transparent
          text-white backdrop-blur-lg cursor-pointer
          bg-black bg-opacity-30 shadow-md
          px-2 py-1 rounded rounded-b-none z-10
          text-[0.9rem] text-white
        `}
        >
          <div className="flex flex-row items-center justify-between">
            <span className="mr-1">Spec: </span>
            <span className="font-bold whitespace-nowrap truncate ml-auto mr-2">
              {bera.attributes.Spec}
            </span>
            <div
              className="w-3 h-3 aspect-square rounded-full border-[1px] border-gray-600"
              style={{
                backgroundColor: BERA_SPEC_COLOR_MAP[bera.attributes.Spec as ValueOf<typeof BERA_SPECS>],
              }}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <span>Gear score: </span>
            <span className="font-bold ml-auto mr-2">{bera.gearScore}</span>
            <div className="flex justify-center items-center w-2 h-2">
              <div
                className="absolute w-3 h-3"
                style={{
                  backgroundImage: 'url(\'/ pngIcons/crossedSwords.png\')',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </GradientBorderWrapper>
  </TooltipTrigger>
);
