import { createSlice } from '@reduxjs/toolkit';
import { missionsExtraReducers } from './missionsReducers';
import parseExtraReducersCallbacks from '../../parseExtraReducersCallbacks';
import { LoggedMission, Mission, MissionsTimers } from '../../../utils/additionalTypes';

export type MissionsState = {
  availableMissions: Mission[] | null;
  activeMissions: Mission[] | null;
  missionsTimers: MissionsTimers | null;
  missionLogs: LoggedMission[] | null;
};

export const initialState = {
  availableMissions: null,
  activeMissions: null,
  missionsTimers: null,
  missionLogs: null,
} as MissionsState;

const missionsReducers = {
  reset: () => initialState,
};

export const missionsSlice = createSlice({
  name: 'missions',
  initialState,
  reducers: missionsReducers,
  extraReducers: parseExtraReducersCallbacks(missionsExtraReducers),
});

export default missionsSlice.reducer;
