import React from 'react';
import { createRoot } from 'react-dom/client';
import ModalWrapper from './ModalWrapper';
import ReduxProvider from '../store/ReduxProvider';

export type RenderModalOptions = {
  canClose?: boolean
  shouldDisplayCloseButton?: boolean
  wrapperStyle?: 'purple' | 'gold' | 'orange' | 'generic'
  onClose?: () => void
}

// eslint-disable-next-line no-unused-vars
type pushModalFnType = (modalElement: React.ReactElement, options?: RenderModalOptions) => void

type ModalServiceType = {
    getCurrentModal: () => React.ReactElement | null
	pushModal: pushModalFnType
	closeModal: () => void
}

const ModalService = (): ModalServiceType => {
  let currentModal: React.ReactElement | null = null;
  let currentModalOnClose: (() => void) | undefined;

  const modalRootContainer = document.getElementById('modal-root') as HTMLElement;
  const modalRoot = createRoot(modalRootContainer);

  const renderModal = (options?: RenderModalOptions) => {
    currentModalOnClose = options?.onClose;

    modalRoot.render(currentModal &&
    <ReduxProvider>
      <ModalWrapper
        /* eslint-disable-next-line no-use-before-define */
        closeModal={() => closeModal(options?.onClose)}
        {...options}
      >
        {currentModal}
      </ModalWrapper>
    </ReduxProvider>);
  };

  const pushModal: pushModalFnType = (modalElement, options) => {
    currentModal = modalElement;

    renderModal(options);
  };

  const closeModal = (onClose?: () => void) => {
    onClose?.();

    currentModal = null;
    currentModalOnClose = undefined;

    renderModal();
  };

  const getCurrentModal = () => currentModal;

  return {
    getCurrentModal,
    pushModal,
    closeModal: () => closeModal(currentModalOnClose),
  } as ModalServiceType;
};

const modalService = ModalService();

export default modalService;
