import { configureStore, combineReducers } from '@reduxjs/toolkit';
import uiSlice from './slices/ui/uiSlice';
import accountSlice from './slices/account/accountSlice';
import missionsSlice from './slices/missions/missionsSlice';
import berasSlice from './slices/beras/berasSlice';
import inventorySlice from './slices/inventory/inventorySlice';
import shopSlice from './slices/shop/shopSlice';
import leaderboardSlice from './slices/leaderboard/leaderboardSlice';

const rootReducer = (state: any, action: any) => { // TODO improve typing
  if (action.type === 'account/logOut') { // reset store on logout
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return combineReducers({
    ui: uiSlice,
    account: accountSlice,
    missions: missionsSlice,
    beras: berasSlice,
    inventory: inventorySlice,
    shop: shopSlice,
    leaderboard: leaderboardSlice,
  })(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
