import { Dispatch } from '@reduxjs/toolkit';
import { accountSlice } from '../store/slices/account/accountSlice';
import { FetcherError } from './additionalTypes';

export default ({
  error,
  dispatch,
}: {
  error: unknown
  dispatch: Dispatch
}) => {
  const assertedFetcherError = error as FetcherError;

  if (assertedFetcherError.status === 401) {
    dispatch(accountSlice.actions.logOut());

    // TODO probably handle it cleaner, does the job for time being
    window.location.href = '/';
  }
};
