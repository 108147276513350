import React from 'react';
import { useEnsName } from 'wagmi';
import { Address } from 'viem';
import { LeaderboardEntry } from '../../../utils/additionalTypes';
import getTrunacatedWalletAddress from '../../../utils/getTrunacatedWalletAddress';
import getColorFromString from '../../../utils/getColorFromString';
import { useAppSelector } from '../../../store/hooks';

export default ({
  leaderboardEntry,
  index,
}: {
	leaderboardEntry: LeaderboardEntry
    index: number
}) => {
  const walletAddress = useAppSelector((state) => state.account.walletAddress);

  const ensName = useEnsName({
    address: leaderboardEntry.walletAddress as Address,
  });

  const rankByIndex = index + 1;

  const truncatedAddress = getTrunacatedWalletAddress(leaderboardEntry.walletAddress as string, 10);
  const bgColor = getColorFromString(leaderboardEntry.walletAddress as string, 0.8);

  const isOwnedWalletAddress = walletAddress === leaderboardEntry.walletAddress;

  return (
    <>
      <div className={`
        font-title text-xl text-white px-4 py-2
        ${index % 2 ? 'bg-mainBg' : 'bg-mainBgLite'}
      `}
      >
        {rankByIndex}
      </div>
      <div
        className={`
          py-2 min-w-0
          ${index % 2 ? 'bg-mainBg' : 'bg-mainBgLite'}
        `}
      >
        <div
          className="relative flex justify-center items-center text-center text-xl text-white h-[28px]"
        >
          <div className="absolute z-10 truncate ...">
            {isOwnedWalletAddress ? 'You 👋' : (ensName.data || truncatedAddress)}
          </div>
          <div
            className={`
            absolute
            top-0 bottom-0 left-0 right-0
            opacity-100
          `}
            style={{
              maskImage: 'linear-gradient(90deg, transparent 5%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 1) 75%, transparent 95%)',
              backgroundColor: `${bgColor}`,
            }}
          />
        </div>
      </div>
      <div className={`
        flex items-center justify-end text-right text-white text-xl pr-4
        ${index % 2 ? 'bg-mainBg' : 'bg-mainBgLite'}
      `}
      >
        {leaderboardEntry.totalGemsEarned}
        <img
          src="/gemSm.png"
          alt="Gems"
          className="ml-4 h-6 object-contain drop-shadow-gems"
        />
      </div>
    </>
  );
};
