import React from 'react';
import GenericButton from '../GenericButton';

export default ({
  onConfirm,
  isConfirmDisabled,
  onDecline,
}: {
    onConfirm: () => void
    isConfirmDisabled?: boolean
    onDecline: () => void
}) => (
  <div className="flex justify-center gap-4 flex-row">
    <GenericButton
      onClick={onConfirm}
      label="Confirm"
      buttonStyle="green"
      {...(isConfirmDisabled && {
        extraClassname: 'opacity-50 pointer-events-none',
      })}
    />
    <GenericButton
      onClick={onDecline}
      label="Decline"
      buttonStyle="red"
    />
  </div>
);
