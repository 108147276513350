import React from 'react';
import csvDownload from 'json-to-csv-export';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';
import OverflowWithMaskWrapper from '../../OverflowWithMaskWrapper';
import { useAppSelector } from '../../../store/hooks';
import {
  Bera, LoggedMission,
} from '../../../utils/additionalTypes';
import HorizontalDivider from '../../HorizontalDivider';
import getMMDDFormatDate from '../../../utils/getMMDDFormatDate';
import BeraCardSmall from '../MissionBriefing/BeraCardSmall';
import GradientBorderWrapper from '../../GradientBorderWrapper';
import getHHMMFromatTime from '../../../utils/getHHMMFromatTime';
import getMissionSuccessChanceColor from '../../../utils/getMissionSuccessChanceColor';
import { MISSION_LABEL, MISSION_TYPE } from '../../../utils/consts';
import GenericSerchParamsTabs from '../../GenericSerchParamsTabsWithSort';
import filterArrayBySearchParams from '../../../utils/filterArrayBySearchParams';
import sortArrayBySearchParams from '../../../utils/sortArrayBySearchParams';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';

export default () => {
  const missionLogs = useAppSelector<Array<LoggedMission> | null>((state) => state.missions.missionLogs);
  const ownedBeras = useAppSelector<Array<Bera> | null>((state) => state.beras.ownedBeras);

  if (!missionLogs) {
    return null;
  }

  const filteredMissionLogsToSort = filterArrayBySearchParams<Array<LoggedMission>>(missionLogs, {
    filterBy: ['questType'],
  });

  const sortedMissionLogs = sortArrayBySearchParams<Array<LoggedMission>>([...filteredMissionLogsToSort], { // copy array to unfreeze
    sortBy: 'startTimeInSeconds',
  });

  return (
    <ScaleFadeInWrapper>
      <GenericMainScreenContainer
        headerElement={<GenericMainScreenHeader
          goBackToLabel="Export CSV"
          goBackToOnClick={() => {
            if (missionLogs) {
              csvDownload({
                data: missionLogs,
                filename: 'beramonium-mission-logs',
              });
            }
          }}
          goBackTo="/logs"
          tabs={[{
            label: 'MISSION LOG',
          }]}
        />}
      >
        <GenericSerchParamsTabs
          extraClassname="mt-4 lg:px-4"
          tabsToDisplay={[ // TODO move these to external definition?
            ...Object.entries(MISSION_TYPE).map(([key, value]) => (
              {
                label: MISSION_LABEL[value],
                searchParam: key.toLowerCase(),
              }
            )),
          ]}
          sortByTabsToDisplay={[
            {
              label: 'Start date',
              searchParam: 'startTime',
            },
          ]}
        />
        <OverflowWithMaskWrapper
          gradientCutoffEnabled
          withExtraPadding
          extraClassname="mt-2 px-4 sm:px-8"
        >
          {sortedMissionLogs?.map((mission) => (
            <div key={mission.missionId}>
              <HorizontalDivider label={`${MISSION_LABEL[mission.questType].toUpperCase()}: ${mission.title}`} />
              <div className="flex justify-between items-center mt-6">
                <div className="flex flex-col text-white items-start sm:text-lg">
                  <div>Start:</div>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <div className="font-bold">{getMMDDFormatDate(mission.startTimeInSeconds)}</div>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <div className="font-bold">{getHHMMFromatTime(mission.startTimeInSeconds)}</div>
                </div>
                <div className={`
                    text-2xl
                    font-bold uppercase
                    ${mission.success ? 'text-emerald-500' : 'text-rose-600'}
                `}
                >
                  {mission.success ? 'Success 🏆' : 'Failed ☠️'}
                </div>
                <div className="flex flex-col text-white items-end sm:text-lg">
                  <div>End:</div>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <div className="font-bold">{getMMDDFormatDate(mission.endTimeInSeconds)}</div>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <div className="font-bold">{getHHMMFromatTime(mission.endTimeInSeconds)}</div>
                </div>
              </div>
              <div className="flex flex-row justify-between sm:justify-center sm:gap-8 text-white text-lg mt-4 mb-8">
                <div className="flex flex-col sm:flex-row flex-wrap">
                  <div>Success chance:{'\u00A0'}</div>
                  <div className={`
                    ${getMissionSuccessChanceColor(mission.successChance)}
                    font-bold
                  `}
                  >{mission.successChance}%</div>
                </div>
                <div className="flex flex-col sm:flex-row flex-wrap justify-end">
                  <div>Gems earned:{'\u00A0'}</div>
                  <div className="flex flex-row justify-end font-bold items-center">
                    {mission.gemsEarned}
                    <img
                      src="/gemSm.png"
                      alt="Gems"
                      className="ml-2 h-6 object-contain drop-shadow-gems"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2 sm:gap-4 justify-center mb-8">
                {mission.beraIds.map((beraId) => {
                  if (ownedBeras) {
                    const beraToDisplay = ownedBeras.find((bera) => bera.id === beraId);

                    return <div
                      key={beraId}
                      className="w-[4.4rem] h-[4.4rem] md:w-[6rem] md:h-[6rem] lg:w-[8rem] lg:h-[8rem] aspect-square"
                    >
                      {beraToDisplay ?
                        <BeraCardSmall
                          bera={{ ...beraToDisplay, inMission: false }}
                        /> :
                        <div className="flex flex-col h-full text-white justify-center items-center text-center">
                          This Bera has been unstaked!
                        </div>
                      }
                    </div>;
                  }
                  return null;
                })}
                {mission.beraIds.length < 4 && Array(4 - mission.beraIds.length).fill(0).map((_, index) => (
                  <GradientBorderWrapper
                    key={index}
                    extraClassname="w-[4.4rem] h-[4.4rem] md:w-[6rem] md:h-[6rem] lg:w-[8rem] lg:h-[8rem] aspect-square opacity-25"
                    disabled
                  >
                    <div className="bg-gray-900 rounded-md w-full h-full"/>
                  </GradientBorderWrapper>
                ))}
              </div>
            </div>
          ))}
        </OverflowWithMaskWrapper>
      </GenericMainScreenContainer>
    </ScaleFadeInWrapper>
  );
};
