import { createSlice } from '@reduxjs/toolkit';
import parseExtraReducersCallbacks from '../../parseExtraReducersCallbacks';
import { LeaderboardEntry } from '../../../utils/additionalTypes';
import { leaderboardExtraReducers } from './leaderboardReducers';

export type LeaderboardState = {
	leaderboardEntries: Array<LeaderboardEntry> | null;
    currentUserEntry: LeaderboardEntry | null;
    totalPlayerCount: number;
    isFetchingData: boolean;
}

export const initialState = {
  leaderboardEntries: null,
  currentUserEntry: null,
  totalPlayerCount: 0,
  isFetchingData: false,
} as LeaderboardState;

const leaderboardReducers = {
  reset: () => initialState,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: leaderboardReducers,
  extraReducers: parseExtraReducersCallbacks(leaderboardExtraReducers),
});

export default leaderboardSlice.reducer;
