import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import MissionBriefing from './MissionBriefing';
import { Bera, BeraToDisplay, BerasToDisplay } from '../../../utils/additionalTypes';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../modals/ErrorModal';
import { MissionsState } from '../../../store/slices/missions/missionsSlice';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';

export default () => {
  const { missionId } = useParams();

  const [selectedBeras, setSelectedBeras] = useState<BerasToDisplay>([]);

  const ownedBeras = useAppSelector<Array<Bera> | null>((state) => state.beras.ownedBeras);
  const berasInQuest = useAppSelector((state) => state.beras.berasInQuest);
  const missions = useAppSelector<MissionsState>((state) => state.missions);

  useEffect(() => {
    if (!ownedBeras || !berasInQuest) {
      modalService.pushModal(<ErrorModal error={new Error('empty beras!!')} />); // TODO better error handling
    }
  }, [ownedBeras, berasInQuest]);

  if (!ownedBeras || !berasInQuest) {
    return null;
  }

  // fallback to empty arrays, there can be no missions available/active ones etc.
  const mission = [...missions.availableMissions || [], ...missions.activeMissions || []].filter(({ id }) => id === missionId)[0];

  // But there must always be a mission to display ;)
  if (!mission) {
    return <Navigate to="/missions/available" />;
  }

  const addToSelectedBeras = (arg: BeraToDisplay) => {
    if (selectedBeras.length < 4) {
      setSelectedBeras([...selectedBeras, arg]);
    }
  };

  const removeFromSelectedBeras = (arg: BeraToDisplay) => setSelectedBeras(selectedBeras.filter((bera) => arg.id !== bera.id));

  const berasToDisplay: BerasToDisplay = ownedBeras.reduce((acc, bera) => {
    if (selectedBeras.findIndex((selectedBera) => bera.id === selectedBera.id) !== -1) {
      return acc;
    }

    return [
      ...acc,
      {
        ...bera,
        inMission: berasInQuest.includes(bera.id),
      },
    ];
  }, [] as BerasToDisplay);

  return (
    <ScaleFadeInWrapper>
      <MissionBriefing
        berasToDisplay={berasToDisplay}
        selectedBeras={selectedBeras}
        addToSelectedBeras={addToSelectedBeras}
        removeFromSelectedBeras={removeFromSelectedBeras}
        mission={mission}
      />
    </ScaleFadeInWrapper>
  );
};
