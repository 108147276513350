import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import NavMainSidebar from '../layouts/NavMainSidebar';
import NavigationSidebar from '../components/MainScreen/NavigationSidebar';
import MissionBoardContainer from '../components/MainScreen/MissionBoard/MissionBoardContainer';
import MissionLogsContainer from '../components/MainScreen/MissionLogs/MissionLogsContainer';
import Inventory from '../components/MainScreen/Inventory/InventoryContainer';
import NavigationBar from '../components/MainScreen/NavigationBar/NavigationBar';
import Root from '../components/Root';
import ContentCenter from '../layouts/ContentCenter';
import WelcomeScreenContainer from '../components/WelcomeScreen/WelcomeScreenContainer';
import MissionBriefingContainer from '../components/MainScreen/MissionBriefing/MissionBriefingContainer';
import LeaderboardContainer from '../components/MainScreen/Leaderboard/LeaderboardContainer';
import RaffleContainer from '../components/MainScreen/Raffle/RaffleContainer';
import ArmoryContainer from '../components/MainScreen/Armory/ArmoryContainer';
import InitScreenContainer from '../components/InitScreen/InitScreenContainer';
import AuthRoute from './AuthRoute';
import FakeSidebar from '../components/MainScreen/FakeSidebar';
import Footer from '../components/MainScreen/Footer';
import GemShop from '../components/MainScreen/Shop/ShopContainer';

export default createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <div>404</div>,
    children: [
      {
        path: '',
        element: <Navigate to="/welcome" replace={true} />,
        errorElement: <div>404</div>,
      },
      {
        path: 'welcome',
        element: <ContentCenter mainComponent={<WelcomeScreenContainer />}/>,
        errorElement: <div>404</div>,
      },
      {
        path: 'init',
        element: <ContentCenter mainComponent={<InitScreenContainer />}/>,
        errorElement: <div>404</div>,
      },
      {
        path: 'missions/:tab?',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<MissionBoardContainer />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>404</div>,
      },
      {
        path: 'mission/:missionId?',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<MissionBriefingContainer />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>404</div>,
      },
      {
        path: 'logs',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<MissionLogsContainer />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>404</div>,
      },
      {
        path: 'inventory/:tab?',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<Inventory />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>404</div>,
      },
      {
        path: 'gemstore/:tab?',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<GemShop />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>404</div>,
      },
      {
        path: 'raffle',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<RaffleContainer />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>404</div>,
      },
      {
        path: 'leaderboard',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<LeaderboardContainer />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>404</div>,
      },
      {
        path: 'armory/:beraId?',
        element: <AuthRoute>
          <NavMainSidebar
            navigationComponent={<NavigationBar />}
            mainComponent={<ArmoryContainer />}
            sidebarRightComponent={<NavigationSidebar />}
            sidebarLeftComponent={<FakeSidebar />}
            footerComponent={<Footer />}
          />
        </AuthRoute>,
        errorElement: <div>Invalid beraId!</div>,
      },
    ],
  },
]);
