import Cookies from 'js-cookie';
import { AUTH_COOKIE } from './consts';

export const fetcher = (...args) => fetch(...args).then(async (res) => {
  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
});

export const fetchGemhuntersApi = (url, params) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const authToken = Cookies.get(AUTH_COOKIE);

  if (authToken) { // it's okay for some API calls to **not** have the auth header set
    headers.append('authorization', authToken);
  }

  return fetcher(url, { headers, ...params });
};

export default fetcher;
