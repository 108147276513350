import React from 'react';
import { useLocation } from 'react-router-dom';
import GenericSerchParamsTabs from '../../GenericSerchParamsTabsWithSort';
import { GEAR_RARITY, GEAR_RARITY_TYPES, NFT_COLLECTIONS_SEASON_1 } from '../../../utils/consts';
import InventoryItemsGridContainer from './InventoryItemsGridContainer';
import { tabs } from './InventoryContainer';

const itemClassSubTabs = [
  {
    label: 'Hunter',
    searchParam: 'hunter',
  },
  {
    label: 'Mage',
    searchParam: 'mage',
  },
  {
    label: 'Warrior',
    searchParam: 'warrior',
  },
];

const disenchantmentFilterTabs = [
  {
    label: GEAR_RARITY_TYPES.Common,
    searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Common].toString(),
    subTabs: itemClassSubTabs,
  },
  {
    label: GEAR_RARITY_TYPES.Rare,
    searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Rare].toString(),
    subTabs: itemClassSubTabs,
  },
  {
    label: GEAR_RARITY_TYPES.Epic,
    searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Epic].toString(),
    subTabs: itemClassSubTabs,
  },
  {
    label: GEAR_RARITY_TYPES.Legendary,
    searchParam: GEAR_RARITY[GEAR_RARITY_TYPES.Legendary].toString(),
    subTabs: itemClassSubTabs,
  },
];

const inventoryFilterTabs = [
  {
    label: 'Equipment',
    searchParam: 'equipment',
    subTabs: [
      {
        label: 'Hunter',
        searchParam: 'hunter',
      },
      {
        label: 'Mage',
        searchParam: 'mage',
      },
      {
        label: 'Warrior',
        searchParam: 'warrior',
      },
    ],
  },
  {
    label: 'Collectibles',
    searchParam: 'collectable',
  },
  {
    label: 'NFTs',
    searchParam: 'nft',
    subTabs: NFT_COLLECTIONS_SEASON_1.map((collectionName) => ({
      label: collectionName,
      searchParam: collectionName.toLowerCase(),
    })),
  },
];

export default () => {
  const { pathname } = useLocation();
  const isDisenchantmentActive = pathname.includes(tabs.disenchanting);

  return (
    <>
      <GenericSerchParamsTabs
        extraClassname="mt-4 lg:px-4"
        tabsToDisplay={isDisenchantmentActive ? disenchantmentFilterTabs : inventoryFilterTabs}
      />
      <InventoryItemsGridContainer />
    </>
  );
};
