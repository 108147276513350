import React from 'react';

export default ({
  mainComponent,
}: {
  mainComponent: React.ReactElement
}) => (
  <div className="flex flex-col h-[100svh] sm:h-screen w-screen justify-center items-center">
    {mainComponent}
  </div>
);
