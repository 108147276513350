import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../utils/consts';
import { matchFilterSearchParam } from '../utils/filterArrayBySearchParams';

const styles = {
  tab: 'text-white text-lg pt-1 cursor-pointer border-2 border-transparent hover:border-b-white/50',
  activeTab: 'text-white text-lg font-bold pt-1 cursor-pointer border-2 border-transparent border-b-white',
  tabSmall: 'text-white text-md pt-1 cursor-pointer border-2 border-transparent hover:border-b-white/50',
  tabSmallActive: 'text-white font-bold text-md pt-1 cursor-pointer border-2 border-transparent border-b-white',
};

type Tab = {
  label: string
  searchParam: string
  subTabs?: Array<Tab>
}

function generatePathObject(arr: Tab[], parentPath: string = ''): { [key: string]: Tab[] } {
  const result: { [key: string]: Tab[] } = {};

  if (parentPath === '') {
    result['top'] = arr;
  }

  arr.forEach((item) => {
    const currentPath = parentPath ? `${parentPath}.${item.searchParam}` : item.searchParam;

    if (item.subTabs && item.subTabs.length > 0) {
      result[currentPath] = item.subTabs;
      const nestedResult = generatePathObject(item.subTabs, currentPath);
      Object.assign(result, nestedResult);
    }
  });

  return result;
}

const getCycledSortBySearchParamValue = (sortBySearchParamValue: string | null) => {
  if (sortBySearchParamValue) {
    const sortByOrder = sortBySearchParamValue?.split('.')[1];
    return {
      ascending: 'descending',
      descending: null,
    }[sortByOrder];
  }

  return 'ascending';
};

export default ({
  tabsToDisplay,
  sortByTabsToDisplay,
  extraClassname,
}: {
  tabsToDisplay: Array<Tab>
  sortByTabsToDisplay?: Array<Tab>
  extraClassname?: string
}) => {
  const [shouldDisplayExtraTabs, setShouldDisplayExtraTabs] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const filterSearchParamsValue = searchParams.get(SEARCH_PARAMS.filter);
  const sortBySearchParamsValue = searchParams.get(SEARCH_PARAMS.sortBy); // only singular sort for now

  const updateSearchParams = useCallback((newKeyValuePair: Record<string, any>) => {
    let newSearchParams: Record<string, any> = {
      ...Array.from(searchParams).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }), {}),
    };

    const [newKey, newValue] = Object.entries(newKeyValuePair)[0];

    if (newValue) {
      newSearchParams = {
        ...newSearchParams,
        ...newKeyValuePair,
      };
    } else {
      delete newSearchParams[newKey];
    }

    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams]);

  const filtersPathObject = useMemo(() => generatePathObject(tabsToDisplay), [tabsToDisplay]);
  const sortByPathObject = useMemo(() => {
    if (sortByTabsToDisplay) {
      return generatePathObject(sortByTabsToDisplay);
    }
    return null;
  }, [sortByTabsToDisplay]);

  return (
    <div className={`relative flex flex-col z-10 ${extraClassname}`}>
      <div className='flex flex-row flex-wrap items-center justify-between gap-x-2 gap-y-4 p-4 shadow-bottom'>
        {sortByPathObject && (
        <div className='flex flex-row flex-wrap gap-x-2'>
          {sortByPathObject.top.map(({ label, searchParam }, index) => {
            const sortBySearchParamValue = sortBySearchParamsValue?.split('.')[1]; // TODO extend if it should support more than one sort param

            return (
              <div
                className="flex flex-row text-white"
                key={label}
              >
                <button
                  onClick={() => {
                    const sortByOrderValue = getCycledSortBySearchParamValue(sortBySearchParamsValue);

                    if (!sortByOrderValue) {
                      updateSearchParams({
                        [SEARCH_PARAMS.sortBy]: null,
                      });
                    } else {
                      updateSearchParams({
                        [SEARCH_PARAMS.sortBy]: `${searchParam}.${sortByOrderValue}`,
                      });
                    }
                  }}
                  className={`
                    ${index === 0 && `
                      relative
                      before:absolute before:content-['Sort_by:']
                      before:left-0 before:top-[-12px] before:w-max
                      before:text-sm before:font-normal
                      ${sortBySearchParamValue && 'font-bold'}
                    `}
                    ${styles.tab}
                  `}
                >
                  {label}
                </button>
                {sortBySearchParamValue && (
                  {
                    ascending: '⬆',
                    descending: '⬇',
                  }[sortBySearchParamValue]
                )}
              </div>
            );
          })}
        </div>
        )}
        <div className='flex flex-row flex-wrap gap-x-2'>
          <button
            onClick={() => updateSearchParams({
              [SEARCH_PARAMS.filter]: null,
            })}
            className={`
              relative
              before:absolute before:content-['Filter_by:']
              before:left-0 before:top-[-12px] before:w-max
              before:text-sm before:font-normal
              ${filterSearchParamsValue ? styles.tab : styles.activeTab}
            `}
          >
            All
          </button>
          {filtersPathObject.top.map(({ label, searchParam }) => (
            <button
              key={label}
              onClick={() => {
                setShouldDisplayExtraTabs(true);
                updateSearchParams({
                  [SEARCH_PARAMS.filter]: searchParam,
                });
              }}
              className={`
                ${filterSearchParamsValue && matchFilterSearchParam(searchParam, filterSearchParamsValue) ?
                styles.activeTab : styles.tab
                }
                relative flex justify-center items-center
              `}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
      {shouldDisplayExtraTabs &&
        Object.entries(filtersPathObject)
        // eslint-disable-next-line no-unused-vars
          .filter(([key, _]) => key !== 'top')
          .map(([key, value]) => {
            if (filterSearchParamsValue?.includes(key)) {
              return (
                <div
                  className='flex flex-row flex-wrap justify-start items-center px-4 gap-x-2'
                  key={key}
                >
                  {value.map(({ label, searchParam }) => (
                    <button
                      onClick={() => updateSearchParams({
                        [SEARCH_PARAMS.filter]: `${key}.${searchParam}`,
                      })}
                      key={label}
                      className={filterSearchParamsValue?.includes(searchParam) ? styles.tabSmallActive : styles.tabSmall} // TODO add proper regex!
                    >
                      {label}
                    </button>
                  ))}
                </div>
              );
            }

            return null;
          })
      }
    </div>
  );
};
