import React, { useEffect } from 'react';
import modalService from '../../modalService/ModalService';
import { InventoryItem, MissionOutcome } from '../../utils/additionalTypes';
import InventoryGridItem from '../MainScreen/Inventory/InventoryGridItem';
import GenericModalLayout from './GenericModalLayout';
import confettiService from '../../utils/ConfettiService';

export default ({
  outcome,
  items,
}: {
  outcome: MissionOutcome
  items: InventoryItem[]
}) => {
  useEffect(() => {
    if (outcome.success) {
      confettiService.addConfetti({
        emojis: ['🐻', '🗡', '⭐️'],
        emojiSize: 100,
        confettiNumber: 100,
      });
    }
  }, [outcome]);

  return (
    <GenericModalLayout
      titleLabel={outcome.success ? 'Mission complete!' : 'Mission failed...'}
      titleRibbonStyle={outcome.success ? 'purple' : 'red'}
      onConfirmLabel={outcome.success ? 'Claim rewards!' : 'Ok...'}
      onConfirm={() => modalService.closeModal()}
      onConfirmStyle={outcome.success ? 'gold' : 'generic'}
    >
      <img
        src="/genericChestOpen.png"
        alt="Gems"
        className="absolute -top-24 h-40 object-contain drop-shadow-gems"
      />
      {outcome.success && outcome.gemsEarned > 0 ? (
        <div className="flex flex-row items-center gap-3 font-bold text-2xl">
          {outcome.gemsEarned}
          <img
            src="/gemSm.png"
            alt="Gems"
            className="h-8 object-contain drop-shadow-gems"
          />
        </div>
      ) : (
        <div className="text-lg">No gems, better luck next time!</div>
      )}
      {items.length > 0 && (
        <div className="w-full">
          <div className="h-32 w-32 mt-10 mx-auto">
            {items.map((item) => (
              <InventoryGridItem
                key={`reward-${item.ItemID}`}
                item={item}
                withGlow
              />
            ))}
          </div>
        </div>
      )}
    </GenericModalLayout>
  );
};
