import isUniqueSpecType from './isUniqueSpecType';
import { GEAR_RARITY, ITEM_RARITY } from './consts';

export default (attributes: {value: string, trait_type: string}[]) => {
  const parsedAttributes = Object.fromEntries(
    attributes.map(({ trait_type, value }) => [trait_type, value]),
  );

  const specValue = parsedAttributes['Spec'];

  return isUniqueSpecType(specValue) ?
  // NOTE: Keep in sync with backend
  // Unique classes have a fixed gear score
    50 :
    Object.values(parsedAttributes).map((attr) => {
      // These do not contribute to gear score
      if (['Aura', 'Body', 'Expression'].includes(attr)) return 0;

      const rarity = ITEM_RARITY[attr];
      // Ignore unknown items; unique is handled above.
      if (rarity == null || rarity === 'Unique') return 0;

      return GEAR_RARITY[rarity] as number;
    }).reduce((acc, value) => acc + value, 0);
};
