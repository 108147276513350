import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSignTypedData, useChainId } from 'wagmi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Bera, BeraAttributes, InventoryItem } from '../../../utils/additionalTypes';
import { BERA_ATTRIBUTES } from '../../../utils/consts';
import { inventoryExtraReducers } from '../../../store/slices/inventory/inventoryReducers';
import trackJSService from '../../../utils/trackJSService';

export type BeraNewAttributes = {
  [BERA_ATTRIBUTES.Pants]: InventoryItem | null
  [BERA_ATTRIBUTES.Boots]: InventoryItem | null
  [BERA_ATTRIBUTES.Gauntlets]: InventoryItem | null
  [BERA_ATTRIBUTES.Chest]: InventoryItem | null
  [BERA_ATTRIBUTES.Helmet]: InventoryItem | null
  [BERA_ATTRIBUTES.Weapon]: InventoryItem | null
}

export type SetBeraNewAttributeType = ({
  // eslint-disable-next-line no-unused-vars
  type,
  // eslint-disable-next-line no-unused-vars
  value,
}: {
  type: keyof BeraNewAttributes,
  value: InventoryItem | null
}) => void

const beraDefaultNewAttributes: BeraNewAttributes = {
  Pants: null,
  Boots: null,
  Gauntlets: null,
  Chest: null,
  Helmet: null,
  Weapon: null,
};

export default () => {
  const dispatch = useAppDispatch();
  const { beraId } = useParams();
  const chainId = useChainId();
  const navigate = useNavigate();

  const ownedBeras = useAppSelector<Array<Bera> | null>((state) => state.beras.ownedBeras) as Bera[];
  const berasInQuest = useAppSelector((state) => state.beras.berasInQuest);

  const [shouldRender, setShouldRender] = useState(false);
  const [beraNewAttributes, setBeraNewAttribute] = useState<BeraNewAttributes>(beraDefaultNewAttributes);
  const [currentlyHoveredItem, setCurrentlyHoveredItem] = useState<InventoryItem | null>(null);

  const selectedBera = ownedBeras.filter((ownedBera) => ownedBera.id === beraId)[0];

  const inMission = berasInQuest?.includes(beraId as string);
  const isUnique = selectedBera.attributes.Spec === 'Unique';

  useEffect(() => {
    if (inMission || isUnique) {
      navigate('/inventory/team?sortBy=gearScore.descending');
    } else {
      setShouldRender(true);
    }
  }, [inMission, isUnique]);

  const selectedBeraOriginalAttributes = selectedBera.attributes;

  const getItemsToEquip = () => Object.values(beraNewAttributes).filter((attr) => attr).map((attr) => ({
    ItemID: attr?.ItemID as string, // assume type, ripe for refactor
  }));

  const resetBeraNewAttributes = () => {
    setBeraNewAttribute(beraDefaultNewAttributes);
  };

  const { signTypedData } = useSignTypedData({
    mutation: {
      onSuccess: async (data) => {
        await dispatch(inventoryExtraReducers.equipItems.reducer({
          beraId,
          itemsToEquip: getItemsToEquip(),
          signature: data,
        }));

        resetBeraNewAttributes();
      },
      onError: trackJSService.trackWithUserInfo('equip gear'),
    },
  });

  const equipNewAttributes = async () => {
    signTypedData({
      domain: {
        name: 'Gemhunters',
        version: '1',
        chainId,
      },
      types: {
        Equip: [
          { name: 'tokenId', type: 'string' },
          { name: 'items', type: 'EquipItem[]' },
        ],
        EquipItem: [
          { name: 'ItemID', type: 'string' },
        ],
      },
      primaryType: 'Equip',
      message: {
        tokenId: beraId as string,
        items: getItemsToEquip(),
      },
    });
  };

  return {
    getBeraId: () => beraId as string,
    currentlyHoveredItem,
    setCurrentlyHoveredItem,
    getCurrentlyHoveredItem: () => currentlyHoveredItem,
    getBeraOriginalAttributes: () => selectedBeraOriginalAttributes as BeraAttributes,
    getBeraNewAttributes: () => beraNewAttributes as BeraNewAttributes,
    resetBeraNewAttributes,
    equipNewAttributes,
    setBeraNewAttribute: ({
      type,
      value,
    }: {
      type: keyof BeraNewAttributes,
      value: InventoryItem | null
    }) => {
      setCurrentlyHoveredItem(null);
      setBeraNewAttribute({
        ...beraNewAttributes,
        [type]: value,
      });
    },
    shouldRender,
  };
};
