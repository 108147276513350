import React from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';

export default ({
  equipNewAttributes,
  shouldDisplayNothingToEquipModal,
}: {
  equipNewAttributes: () => void
  shouldDisplayNothingToEquipModal: boolean
}) => (
  <GenericModalLayout
    titleLabel={shouldDisplayNothingToEquipModal ?
      'No items equipped!' :
      'Equip selected items?'
    }
    onConfirm={shouldDisplayNothingToEquipModal ?
      () => modalService.closeModal() :
      () => equipNewAttributes()
    }
    {...(!shouldDisplayNothingToEquipModal && {
      onDecline: () => modalService.closeModal(),
    })}
    onConfirmLabel={'Close'}
  >
    <div className="max-w-[30rem] text-center">
      {shouldDisplayNothingToEquipModal ?
        'No items equipped... Make sure to select something from your inventory! Nothing to wear? Send your Beras on a quest for fresh gear and glory!' :
        'You are about to update your NFT with the selected items. Remember: REPLACED (OLD) GEAR IS BURNED, YOU WON\'T GET IT BACK!'}
    </div>
  </GenericModalLayout>
);
