/* eslint-disable no-restricted-syntax */
import { MissionChallenge, BerasToDisplay } from './additionalTypes';
import {
  RegularSpec, UniqueSpec,
} from './consts';

import isUniqueSpecType from './isUniqueSpecType';
import isRegularSpecType from './isRegularSpecType';

// NOTE: Keep in sync with backend; this was copied more or less as-is
/**
 * For a given quest and party, return the indices of the challenges that are fully countered.
 * @param quest A quest containing challenges to be countered
 * @param party A party composition to counter the challenges
 */
export function CounteredChallenges(
  mission: { Challenges: MissionChallenge[] },
  party: Array<UniqueSpec | RegularSpec>,
): number[] {
  // If the party has a unique class, all challenges are countered by default
  if (party.some(isUniqueSpecType)) {
    return Array.from({ length: mission.Challenges.length }).map((_, idx) => idx);
  }

  const counteredArr: number[] = [];

  // Initialize a helper structure that keeps track of available classes in the party
  const partyClasses = (() => {
    const available = new Map<string, number>();
    party.forEach((cls) => {
      available.set(cls, (available.get(cls) ?? 0) + 1);
    });

    return {
      available: (cls: RegularSpec): number => available.get(cls) ?? 0,
      markUsed: (cls: RegularSpec): void => {
        available.set(cls, (available.get(cls) ?? 0) - 1);
      },
    };
  })();

  // Partition the challenges into zero-or-single-challenge and two-challenge enemies
  const trivialChallenges: Array<[MissionChallenge, number]> = [];
  const doubleChallenges: Array<[MissionChallenge, number]> = [];
  mission.Challenges.forEach((challenge, origIndex) => {
    if (challenge.counters.length === 0 || challenge.counters.length === 1) {
      trivialChallenges.push([challenge, origIndex]);
    } else {
      doubleChallenges.push([challenge, origIndex]);
    }
  });

  // Either trivially counterable or have a single counter (need to use a party member)
  for (const [trivial, origIdx] of trivialChallenges) {
    const [counter] = trivial.counters;

    if (counter == null) {
      counteredArr.push(origIdx);
    } else if (counter != null && partyClasses.available(counter) > 0) {
      counteredArr.push(origIdx);
      partyClasses.markUsed(counter);
    }
  }

  // Greedily try to counter remaining two-class challenges
  for (const [challenge, origIdx] of doubleChallenges) {
    const usedClasses = [] as RegularSpec[];
    for (const counter of challenge.counters) {
      // If the class was already considered, then we need at least 2 in the party
      const requiredClassCount = 1 + Number(usedClasses.includes(counter));

      if (partyClasses.available(counter) >= requiredClassCount) {
        usedClasses.push(counter);
      }
    }

    // Fully countered
    if (usedClasses.length === challenge.counters.length) {
      counteredArr.push(origIdx);
      partyClasses.markUsed(usedClasses[0]);
      partyClasses.markUsed(usedClasses[1]);
    }
  }

  counteredArr.sort((a, b) => a - b);
  return counteredArr;
}

export default ({
  selectedBeras,
  missionChallenges,
}: {
  selectedBeras: BerasToDisplay,
  missionChallenges: Array<MissionChallenge>
}) => {
  const selectedBeraClasses = selectedBeras.map((bera) => {
    const value = bera.attributes['Spec'];
    if (!isUniqueSpecType(value) && !isRegularSpecType(value)) {
      throw new Error(`Invalid spec: ${value}`);
    }

    return value;
  });

  const counteredIndices = CounteredChallenges({ Challenges: missionChallenges }, selectedBeraClasses);

  return missionChallenges.map((missionChallenge, index) => ({
    ...missionChallenge,
    isCountered: counteredIndices.includes(index),
  }));
};
