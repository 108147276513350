import React, { useEffect } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import { berachainTestnetbArtio } from 'wagmi/chains';
import modalService from '../modalService/ModalService';
import IncorrectNetworkModal from './modals/IncorrectNetworkModal';

export default () => {
  const { chain, isConnected } = useAccount();
  const { switchChain } = useSwitchChain();

  useEffect(() => {
    if (isConnected) {
      const isBArtioTestnetSelected = chain?.id === berachainTestnetbArtio.id;

      if (!isBArtioTestnetSelected) {
        modalService.pushModal(
          <IncorrectNetworkModal switchNetworkCallback={() => switchChain({
            chainId: berachainTestnetbArtio.id,
          })}
          />,
          {
            canClose: false,
            shouldDisplayCloseButton: false,
          },
        );
      } else {
        modalService.closeModal();
      }
    }
  }, [isConnected, chain, switchChain]);

  return null;
};
