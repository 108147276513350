import React from 'react';
import { StartButtonSvg } from '../../SvgIcons';

export default ({
  disabled,
  onClick,
}: {
    disabled?: boolean
    onClick: () => void
}) => {
  const linearGradientUniqueId = crypto.randomUUID(); // yup xD

  return (
    <div
      className={`
        relative flex justify-center items-center mt-4
        ${disabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer hover:drop-shadow-missionStartButton'}
        -translate-y-[3px]
      `}
      onClick={onClick}
    >
      <StartButtonSvg
        linearGradientUniqueId={linearGradientUniqueId }
      />
      <div
        className="absolute font-title font-bold text-lg text-white uppercase"
        style={{
          letterSpacing: '1px',
        }}
      >
        Start Mission!
      </div>
    </div>
  );
};
