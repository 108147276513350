export default (successChance: number) => {
  if (successChance > 75) {
    return 'text-green-500';
  }

  if (successChance > 25) {
    return 'text-yellow-500';
  }

  if (successChance > 10) {
    return 'text-orange-500';
  }

  return 'text-red-500';
};
