import React from 'react';

export default ({
  heightInRem = 1,
}: {
  heightInRem?: number
}) => <div
  className='absolute left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-gray-800'
  style={{
    height: `${heightInRem}rem`,
  }}
/>;
