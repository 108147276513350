import React from 'react';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  Bera, BeraAttributes, BerasAPIResponse, ExtraReducerGenericArg,
} from '../../../utils/additionalTypes';
import { createExtraReducer, ExtraReducers } from '../../parseExtraReducersCallbacks';
import { BerasState } from './berasSlice';
import { fetchGemhuntersApi } from '../../../utils/fetcher';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../../components/modals/ErrorModal';
import { RootState } from '../../store';
import fetcherReducerErrorHandler from '../../../utils/fetcherReducerErrorHandler';
import endpointUrl from '../../../utils/endpointUrl';
import getGearScore from '../../../utils/getGearScore';

export const berasReducers = {
  setBerasInQuest: (state: BerasState, { payload }: PayloadAction<Array<string>>) => {
    state.berasInQuest = payload;
  },
};

export const berasExtraReducers: ExtraReducers = {
  getBeras: createExtraReducer<BerasState, Array<Bera>, ExtraReducerGenericArg>({
    reducer: {
      name: 'beras/getBeras',
      callbackFn: async (extraReducerGenericArg, { dispatch, getState, rejectWithValue }) => {
        const {
          account: { walletAddress },
        } = getState() as RootState;

        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/beras`, {
            method: 'POST',
            body: JSON.stringify({ walletAddress }),
          }) as BerasAPIResponse;

          const { ownedNfts } = res.data;

          if (ownedNfts) { // TODO parse more elegantly?
            return ownedNfts.map(({
              title, media, tokenId, metadata: { attributes },
            }) => {
              const parsedAttributes = Object.fromEntries(
                attributes.map(({ trait_type, value }) => [trait_type, value]),
              ) as unknown as BeraAttributes;

              const { gateway } = media[0];
              // eslint-disable-next-line no-nested-ternary
              const imageUrl = gateway.includes('/ipfs/') ?
                // We use Pinata gateway, so let's resize using the param
                `${gateway}?img-width=600` :
                // If it's Supabase-hosted image, use their image transformation API
                gateway.includes('storage/v1/object/public/') ?
                  media[0].gateway.replace('storage/v1/object/public/', 'storage/v1/render/image/public/')
                    .concat('?width=600&quality=90&resize=contain') :
                // Otherwise, fallback to the actual gateway URL
                  gateway;

              return ({
                title,
                id: tokenId,
                gearScore: getGearScore(attributes),
                imageUrl,
                attributes: parsedAttributes,
              });
            });
          }

          return rejectWithValue(new Error('Missing data in response'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          if (extraReducerGenericArg?.failImplicit) {
            return rejectWithValue(null);
          }

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload }) => {
      state.ownedBeras = payload;
    },
    rejectedCallback: (_, { payload }) => {
      if (payload) {
        modalService.pushModal(<ErrorModal error={payload} />);
      }
    },
  }),
};
