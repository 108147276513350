import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { MissionsState } from '../store/slices/missions/missionsSlice';
import getMissionBgUrl from '../utils/getMissionBgUrl';

export default () => {
  const [bgImage, setBgImage] = useState<string>();
  const { missionId } = useParams();
  const missions = useAppSelector<MissionsState>((state) => state.missions);

  useEffect(() => {
    if (missionId) {
      const mission = [...missions.availableMissions || [], ...missions.activeMissions || []].filter(({ id }) => id === missionId)[0];

      if (mission) {
        setBgImage(getMissionBgUrl(mission.title));
      }
    } else {
      setBgImage('/responsiveBg/mainBg.jpg');
    }
  }, [missions, missionId]);

  return (
    <div
      className="-z-20 fixed -top-20 -right-20 -bottom-20 -left-20 bg-black"
    >
      <div
        key={bgImage}
        className="absolute bg-black top-0 right-0 bottom-0 left-0 blur-2xl animate-bgOpacity30FadeIn"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </div>
  );
};
