import React, { useEffect } from 'react';
import modalService from '../../../modalService/ModalService';
import SeasonPassModal from '../../modals/SeasonPassModal';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import GenericSerchParamsTabs from '../../GenericSerchParamsTabsWithSort';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useTailwindBreakpoint from '../../../utils/useTailwindBreakpoint';
import { uiSlice } from '../../../store/slices/ui/uiSlice';
import GemShopItemsGridContainer from './ShopItemsGridContainer';
import SeasonPassItemContainer from './SeasonPassItemContainer';
import { NFT_COLLECTIONS_SEASON_1 } from '../../../utils/consts';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';

export default () => {
  const dispatch = useAppDispatch();
  const hasShownSeasonPassModal = useAppSelector((state) => state.ui.hasShownSeasonPassModal);

  const breakpoint = useTailwindBreakpoint();
  const shouldOpenSeasonPassModal = breakpoint && ['sm', 'md', 'lg'].includes(breakpoint);

  useEffect(() => {
    if (!hasShownSeasonPassModal && shouldOpenSeasonPassModal) {
      dispatch(uiSlice.actions.setShownSeasonPassModalTrue());

      modalService.pushModal(<SeasonPassModal />, {
        wrapperStyle: 'orange', // TODO move this to modal definition itself instead of pushModal arg
      });
    }
  }, [breakpoint, hasShownSeasonPassModal]);

  return (
    <ScaleFadeInWrapper>
      <GenericMainScreenContainer
        headerElement={<GenericMainScreenHeader
          goBackTo={shouldOpenSeasonPassModal && '/gemstore'}
          goBackToLabel={'Main prize'}
          goBackToOnClick={() => modalService.pushModal(<SeasonPassModal />, {
            wrapperStyle: 'orange', // TODO move this to modal definition itself instead of pushModal arg
          })}
          tabs={[{
            label: 'GEM STORE',
          }]}
        />}
        extraClassName="!flex-row"
      >
        <div
          className="hidden lg:flex lg:min-w-[320px] bg-center flex-col justify-center items-center"
          style={{
            backgroundImage: 'url(\'shopBgSmall.jpg\')',
            backgroundSize: 'cover',
          }}
        >
          <SeasonPassItemContainer />
        </div>
        <div className="flex flex-col flex-grow">
          <GenericSerchParamsTabs
            extraClassname="mt-4 lg:px-4"
            tabsToDisplay={[ // TODO move these to external definition?
              {
                label: 'NFTs',
                searchParam: 'nft',
                subTabs: NFT_COLLECTIONS_SEASON_1.map((collectionName) => ({
                  label: collectionName,
                  searchParam: collectionName.toLowerCase(),
                })),
              },
              // {
              //   label: 'Collectibles',
              //   searchParam: 'collectable',
              // },
            ]}
          />
          <GemShopItemsGridContainer />
        </div>
      </GenericMainScreenContainer>
    </ScaleFadeInWrapper>
  );
};
