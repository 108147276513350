import React from 'react';
import GenericModalLayout from './GenericModalLayout';
import modalService from '../../modalService/ModalService';
import SeasonPassItem from '../MainScreen/Shop/SeasonPassItemContainer';
import { useAppSelector } from '../../store/hooks';
import { ShopItem } from '../../utils/additionalTypes';

// eslint-disable-next-line react/prop-types
export default () => {
  const shopItems = useAppSelector((state) => state.shop.shopItems) as Array<ShopItem>;

  const shopItemMainPrize = shopItems.filter((shopItem) => shopItem.IsMainShopPrize)[0];

  return (
    <GenericModalLayout
      titleLabel={'Main prize'}
      onConfirm={() => modalService.closeModal()}
      onConfirmLabel={'Close'}
      titleRibbonStyle="orange"
    >
      <div className={`${shopItemMainPrize && 'h-[12.5rem]'}`}>
        <SeasonPassItem />
      </div>
    </GenericModalLayout>
  );
};
