import React from 'react';

export default ({
  src,
  extraClassName,
}: {
  src: string
  extraClassName?: string
}) => <div
  className={`w-full h-full aspect-square ${extraClassName}`}
  style={{
    backgroundImage: 'url(\'/spinningLoader.gif\')',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  <img
    src={src}
    className='w-full h-full object-cover'
    onError={({ target }) => { // hide on error 🤷‍
      target.style.display = 'none';
    }}
  />
</div>;
