import React, { useEffect } from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';
import { ShopItem } from '../../utils/additionalTypes';
import GenericGridItem from '../GenericItemGrid/GenericGridItem';
import confettiService from '../../utils/ConfettiService';
import { ITEM_TYPES } from '../../utils/consts';

export default ({
  item,
}: {
  item: ShopItem
}) => {
  useEffect(() => {
    confettiService.addConfetti({
      emojis: ['🎁', '💸', '⭐️'],
      emojiSize: 100,
      confettiNumber: 100,
    });
  }, []);

  return (
    <GenericModalLayout
      titleLabel="Purchase sucessful!"
      titleRibbonStyle="gold"
      onConfirm={() => modalService.closeModal()}
      onConfirmLabel="Claim item!"
      onConfirmStyle="gold"
    >
      <div className="h-36 w-36 mb-6">
        <GenericGridItem
          title={item.ItemName}
          imgSrc={item.ItemImage.kind === 'name' ? `/inventoryItems/${item.ItemImage.value}.jpeg` : item.ItemImage.value}
          tooltipContent={<div>{item.ItemName}</div>}
          withGlow
        />
      </div>
      <div className="flex flex-row gap-1 items-center text-lg text-center">
        <div className="font-bold">{item.ItemName}</div>
        <div>is yours!!!</div>
      </div>
      {(item.ItemType === ITEM_TYPES.NFT || item.ItemType === ITEM_TYPES.BeramoniumNFT) ?
        <div className="text-lg text-center mt-4 w-[16rem]">
          This item will be delivered to you manually within 48 hours from the purchase time. Open Discord support ticket if you need further assistance.
        </div> :
        null}
    </GenericModalLayout>
  );
};
