import React from 'react';

export default ({
  children,
  extraClassname,
}: {
	children: React.ReactNode
	extraClassname?: string
}) => (
  <div className={`
  	animate-scaleFadeIn w-full h-full flex justify-center
  	${extraClassname}
  `}
  >
    {children}
  </div>
);
