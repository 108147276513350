import React from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';
import { FetcherError } from '../../utils/additionalTypes';

// eslint-disable-next-line react/prop-types
export default ({
  error,
}: {
  error: unknown,
}) => {
  const errorStatus = (error as FetcherError)?.status;
  const errorMessage = (error as FetcherError)?.info?.message ?? (error as Error).message;

  return (
    <GenericModalLayout
      titleLabel="Something went wrong!"
      onConfirm={() => modalService.closeModal()}
      onConfirmLabel="Close"
    >
      {!!errorStatus && <div className="mb-4 font-title text-2xl">HTTP res: {errorStatus}</div>}
      <div>{errorMessage}</div>
    </GenericModalLayout>
  );
};
