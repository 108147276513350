import React, { useRef, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import Cookies from 'js-cookie';
import { Mission, MissionTimer } from '../../../utils/additionalTypes';
import getMissionRemainingSeconds from '../../../utils/getMissionRemainingSeconds';
import { TEST_USER_COOKIE } from '../../../utils/consts';

type DHMSType = {
  d?: string
  h: string
  m: string
  s?: string
}

const getMissionTimerRemainingSeconds = (timer: MissionTimer) => {
  const isTestUser = Cookies.get(TEST_USER_COOKIE);

  // If the user is "testing", make everything x2000 faster
  // eslint-disable-next-line no-underscore-dangle
  let duration = timer.finish._seconds - timer.start._seconds;
  if (isTestUser) {
    duration = Math.floor(duration / 2000);
  }

  // eslint-disable-next-line no-underscore-dangle
  const endTimestampSeconds = timer.start._seconds + duration;

  return Math.ceil(endTimestampSeconds - Date.now() / 1000);
};

const formatRemainingSeconds = (seconds: number): DHMSType => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsRemaining = seconds % 60;

  // const paddedDays = days.toString().padStart(2, '0');
  // const paddedHours = hours.toString().padStart(2, '0');
  // const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = secondsRemaining.toString().padStart(2, '0');

  if (days > 0) {
    return {
      d: days.toString(),
      h: hours.toString(),
      m: minutes.toString(),
    };
  }

  return {
    h: hours.toString(),
    m: minutes.toString(),
    s: paddedSeconds.toString(),
  };
};

const getFormattedRemainingTimeHTMLString = (dhmsTime: DHMSType) => renderToString(
  <div className="flex flex-row justify-center items-end text-3xl">
    {dhmsTime.d ?
      <>
        <div className="font-bold">{dhmsTime.d}</div>
        <div className="text-2xl mr-1">d</div>
      </> : null}
    <div className="font-bold">{dhmsTime.h}</div>
    <div className="text-2xl mr-1">h</div>
    <div className="font-bold">{dhmsTime.m}</div>
    <div className="text-2xl mr-1">m</div>
    {dhmsTime.s ?
      <>
        <div className="font-bold">{dhmsTime.s}</div>
        <div className="text-2xl">s</div>
      </> : null}
  </div>,
);

export default ({
  mission,
  missionTimer,
  onFinishCallback,
  extraClassname,
}: {
  mission?: Mission
  missionTimer?: MissionTimer,
  onFinishCallback?: () => void
  extraClassname?: string,
}) => {
  if (!mission && !missionTimer) {
    throw Error('missing mission or missionTimer!');
  }

  const timerElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const updateTimerElement = () => {
      const secondsRemaining = mission ? getMissionRemainingSeconds(mission) : getMissionTimerRemainingSeconds(missionTimer as MissionTimer);

      if (secondsRemaining >= 0) {
        if (timerElementRef.current) {
          timerElementRef.current.innerHTML = getFormattedRemainingTimeHTMLString(formatRemainingSeconds(secondsRemaining));
        }

        timeoutId = setTimeout(() => updateTimerElement(), 1000);
      } else {
        // eslint-disable-next-line no-unused-expressions
        onFinishCallback && onFinishCallback();
        clearTimeout(timeoutId);
      }
    };

    if ((mission ? getMissionRemainingSeconds(mission) : getMissionTimerRemainingSeconds(missionTimer as MissionTimer)) >= 0) {
      updateTimerElement();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <div
      ref={timerElementRef}
      className={`text-white ${extraClassname}`}
    >
      --:--:--
    </div>
  );
};
