import React from 'react';
import { ShopItem } from '../../../utils/additionalTypes';
import modalService from '../../../modalService/ModalService';
import ItemPurchaseModal from '../../modals/ItemPurchaseModal';
import BuyButton from './BuyButton';
import NftGridItem from '../../NftGridItem';

export default ({
  shopItem,
}: React.ComponentProps<any> & {
  shopItem: ShopItem
}) => (
  <div
    className={`
      flex flex-col items-center w-full h-full gap-3 mb-2 group cursor-pointer
      ${shopItem.isSold && 'pointer-events-none opacity-50'}
    `}
    onClick={() => modalService.pushModal(<ItemPurchaseModal
      item={shopItem}
    />)}
  >
    <NftGridItem
      title={shopItem.ItemName}
      imgSrc={shopItem.ItemImage.kind === 'name' ? `/inventoryItems/${shopItem.ItemImage.value}.jpeg` : shopItem.ItemImage.value}
      description={shopItem.ItemDescription}
      openSeaLink={shopItem.OpenSeaLink}
    />
    <BuyButton
      onClick={() => {
        if (!shopItem.isSold) {
          modalService.pushModal(<ItemPurchaseModal
            item={shopItem}
          />);
        }
      }}
      label={shopItem.ItemPrice.toLocaleString()}
    />
  </div>
);
