import { createSlice } from '@reduxjs/toolkit';
import { accountReducers, accountExtraReducers } from './accountReducers';
import parseExtraReducersCallbacks from '../../parseExtraReducersCallbacks';

export type AccountState = {
  walletAddress: string | null;
  isSignedIn: boolean;
  ownedGems: number | null;
};

export const initialState = {
  walletAddress: null,
  isSignedIn: false,
  ownedGems: null,
} as AccountState;

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: accountReducers,
  extraReducers: parseExtraReducersCallbacks(accountExtraReducers),
});

export default accountSlice.reducer;
