import React from 'react';

const NavMainSidebar = ({
  navigationComponent,
  mainComponent,
  sidebarLeftComponent,
  sidebarRightComponent,
  footerComponent,
}: {
  navigationComponent: React.ReactElement
  mainComponent: React.ReactElement
  sidebarLeftComponent?: React.ReactElement
  sidebarRightComponent?: React.ReactElement
  footerComponent: React.ReactElement
}) => (
  <div className="flex flex-col h-[100svh] sm:h-screen w-screen">
    <div className="w-full">
      {navigationComponent}
    </div>
    <div className={`
        flex flex-col sm:flex-row flex-grow overflow-hidden max-h-full
        sm:min-h-[47rem] // TODO dirty fix for small screens
    `}
    >
      {sidebarLeftComponent}
      <div className="flex overflow-auto p-2 sm:px-4 justify-center items-start flex-grow max-h-full sm:overflow-hidden">
        {mainComponent}
      </div>
      {sidebarRightComponent}
    </div>
    <div className="w-full">
      {footerComponent}
    </div>
  </div>
);

export default NavMainSidebar;
