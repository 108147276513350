import React from 'react';
import { BeraToDisplay } from '../../../utils/additionalTypes';
import GenericGridItem from '../../GenericItemGrid/GenericGridItem';
import { EQUIPMENT_ATTRIBUTES, ITEM_RARITY, ITEM_RARITY_COLOR_MAP } from '../../../utils/consts';

export default ({
  bera,
  additionalProps,
}: {
  bera: BeraToDisplay;
  additionalProps?: React.ComponentProps<any>;
}) => (
  <div className="w-auto">
    <GenericGridItem
      key={bera.id}
      title={bera.attributes.Spec}
      tooltipContent={<div className="relative flex flex-col max-w-lg">
        <div>
          <span className="font-bold text-sm">Gear score: </span>
          <span className="text-sm">{bera.gearScore}</span>
        </div>
        {Object.values(EQUIPMENT_ATTRIBUTES).map((trait) => (
          <div key={trait}>
            <span className="font-bold text-sm">{trait}: </span>
            <span
              className="text-sm"
              style={{
                color: ITEM_RARITY_COLOR_MAP[ITEM_RARITY[bera.attributes[trait]] as keyof typeof ITEM_RARITY_COLOR_MAP],
              }}
            >{bera.attributes[trait]}</span>
          </div>
        ))}
      </div>}
      imgSrc={bera.imageUrl}
      gradientWrapperAdditionalProps={{
        disabled: bera.inMission,
        shouldChangeBorderColorOnHover: true,
        shouldIncreaseScaleOnHover: !bera.inMission,
        shouldDisplayPointerCursor: true,
      }}
      additionalProps={additionalProps}
    />
  </div>
);
