import {
  AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk,
} from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { CallbackDefaultVariadic } from '../utils/additionalTypes';

type ExtraReducer<ReducerState, ReturnedState, ThunkArg> = {
  reducer: {
    name: string
    callbackFn: AsyncThunkPayloadCreator<ReturnedState, ThunkArg, AsyncThunkConfig>
  }
    // eslint-disable-next-line no-unused-vars
  pendingCallback?: (state: ReducerState) => void,
    // eslint-disable-next-line no-unused-vars
  fulfilledCallback?: (state: ReducerState, payload: { payload: ReturnedState}) => void,
    // eslint-disable-next-line no-unused-vars
  rejectedCallback?: (state: ReducerState, error: { payload: unknown }) => void,
}

export const createExtraReducer =
 <ReducerState, ReturnedState, ThunkArg>(arg: ExtraReducer<ReducerState, ReturnedState, ThunkArg>): {
   reducer: AsyncThunk<any, any, AsyncThunkConfig>, // TODO improve typing
   pendingCallback?: CallbackDefaultVariadic,
   fulfilledCallback?: CallbackDefaultVariadic,
   rejectedCallback?: CallbackDefaultVariadic,
 } => ({
    ...arg,
    reducer: createAsyncThunk<ReturnedState, ThunkArg>(
      arg.reducer.name,
      arg.reducer.callbackFn,
    ),
  });

export type ExtraReducers = Record<string, ReturnType<typeof createExtraReducer>>

export default (extraReducers: ExtraReducers) => (builder: {
  addCase: any // TODO improve typing
}) => {
  Object.values(extraReducers).forEach((extraReducer) => {
    const {
      reducer,
      fulfilledCallback,
      pendingCallback,
      rejectedCallback,
    } = extraReducer;

    if (fulfilledCallback) {
      builder.addCase(reducer.fulfilled, fulfilledCallback);
    }

    if (pendingCallback) {
      builder.addCase(reducer.pending, pendingCallback);
    }

    if (rejectedCallback) {
      builder.addCase(reducer.rejected, rejectedCallback);
    }
  });
};
