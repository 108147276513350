import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import Cookies from 'js-cookie';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { accountSlice } from '../../store/slices/account/accountSlice';
import WelcomeScreen from './WelcomeScreen';
import { AUTH_COOKIE, PLAY_BG_MUSIC_ON_INIT_COOKIE } from '../../utils/consts';
import modalService from '../../modalService/ModalService';
import BgMusicModal from '../modals/BgMusicModal';
import { uiSlice } from '../../store/slices/ui/uiSlice';

export default () => {
  const walletAddress = useAppSelector((state) => state.account.walletAddress);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { address, isConnected } = useAccount();

  const shouldDisplayBgMusicModal = Cookies.get(PLAY_BG_MUSIC_ON_INIT_COOKIE) === undefined;

  useEffect(() => {
    if (isConnected && address) {
      dispatch(accountSlice.actions.setWalletAddress(address));

      const authToken = Cookies.get(AUTH_COOKIE);

      if (authToken) {
        dispatch(accountSlice.actions.setSignedIn(true));
      }
    }
  }, [address]);

  useEffect(() => {
    if (walletAddress) {
      if (shouldDisplayBgMusicModal) {
        modalService.pushModal(<BgMusicModal />, {
          onClose: () => {
            navigate('/init', {
              state,
            });
          },
        });
      } else {
        dispatch(uiSlice.actions.setShouldPlayBgMusicOnInit(Cookies.get(PLAY_BG_MUSIC_ON_INIT_COOKIE) === 'true'));

        navigate('/init', {
          state,
        });
      }
    }
  }, [walletAddress, shouldDisplayBgMusicModal]);

  return <WelcomeScreen />;
};
