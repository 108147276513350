import React from 'react';
import { Link } from 'react-router-dom';

export type GenericButtonStyle = 'generic' | 'green' | 'red' | 'gold'

const style = `
  group
  bg-gradient-to-b
  text-white font-bold
  border border-b-0 border-t-0
  rounded-genericButton
  shadow-md
`;

const innerStyle = `
  relative overflow-hidden
  bg-mainBg bg-opacity-[25%] py-1.5 px-4 my-[.3rem] mx-1.5
  shadow-genericButton
  rounded-genericButton
  break-keep
  min-w-max
  flex flex-row items-center justify-center gap-2
  font-title
`;

const buttonStyleMap = {
  generic: 'from-gray-300/100 to-gray-700/100 border-gray-400',
  green: 'from-emerald-300/100 to-emerald-700/100 border-emerald-400',
  red: 'from-rose-300/100 to-rose-700/100 border-rose-400',
  gold: 'from-yellow-300/100 to-yellow-700/100 border-yellow-400',
};

export default ({
  label,
  onClick,
  linkTo,
  icon,
  extraClassname,
  buttonStyle = 'generic',
  disabled,
}: {
  label: string
  onClick?: () => void
  linkTo?: string
  icon?: React.ReactNode
  extraClassname?: string
  buttonStyle?: GenericButtonStyle
  disabled?: boolean
}) => {
  if (linkTo) {
    return (
      <Link to={linkTo}>
        <button
          onClick={onClick}
          className={`
            ${style} ${extraClassname}
            ${buttonStyleMap[buttonStyle]}
            ${disabled && 'pointer-events-none opacity-50'}
          `}
        >
          <div className={innerStyle}>
            {label}
            {icon}
          </div>
        </button>
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        ${style} ${extraClassname}
        ${buttonStyleMap[buttonStyle]}
        ${disabled && 'pointer-events-none opacity-50'}
      `}
    >
      <div className={`
          ${innerStyle}
          ${disabled ? 'text-gray-400 user-select-none' : 'text-white group-hover:bg-opacity-[35%]'}
        `}
      >
        {label}
        {icon}
      </div>
    </button>
  );
};
