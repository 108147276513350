import React from 'react';
import {
  BERA_ATTRIBUTES,
  BERA_CLASS_ICON_MAP,
  BERA_SPEC_COLOR_MAP,
  BERA_UNIQUE_CLASS_ICON, GEAR_RARITY,
  WEAPON_SPECS_MAP,
} from '../../../utils/consts';
import { BeraAttributes } from '../../../utils/additionalTypes';
import { BeraNewAttributes } from './useBeraAttributes';
import externalItemData from '../../../utils/externalItemData';

export default ({
  originalAttributes,
  newAttributes,
  beraId,
}: {
  originalAttributes: BeraAttributes,
  newAttributes: BeraNewAttributes,
  beraId: string
}) => {
  const beraClass = originalAttributes[BERA_ATTRIBUTES.Class];
  const beraSpec = WEAPON_SPECS_MAP[newAttributes.Weapon?.ItemWeaponType as keyof typeof WEAPON_SPECS_MAP] ?? originalAttributes[BERA_ATTRIBUTES.Spec];
  const originalGearScore = originalAttributes[BERA_ATTRIBUTES.GearScore];

  const totalGearScore = Object.keys(newAttributes).reduce((acc, key) => {
    const currentAttributeValue = newAttributes[key as keyof BeraNewAttributes]?.ItemName ?? originalAttributes[key as keyof BeraAttributes];
    const currentAttributeRarity = Object.values(externalItemData).find(({ Name }) => Name === currentAttributeValue)?.Rarity;

    if (currentAttributeRarity) {
      const currentAttributeGearScore = GEAR_RARITY[currentAttributeRarity as keyof typeof GEAR_RARITY];

      return acc + currentAttributeGearScore;
    }

    return acc;
  }, 0 as number);

  const isNewGearScoreDifferent = originalGearScore !== totalGearScore;
  const isNewGearScoreBetter = totalGearScore > originalGearScore;

  return (
    <>
      <div className="flex flex-row text-3xl">
        <span className="text-white pb-0.5 whitespace-nowrap mr-2">Beramonium</span>
        <span className="font-bold uppercase text-white pb-0.5 whitespace-nowrap">#{beraId}</span>
      </div>
      <div className="flex flex-row justify-around w-full ml-[.5rem]">
        <div className="basis-1/3 flex flex-col items-center">
          <div className="whitespace-nowrap">Gear Score:</div>
          <div className="relative flex flex-row items-center gap-2 ml-4">
            {/* eslint-disable-next-line no-nested-ternary */}
            {originalGearScore !== totalGearScore ?
              originalGearScore < totalGearScore ?
                <div className="-left-[1.3rem] absolute text-green-500">⬆</div> :
                <div className="-left-[1.3rem] absolute text-rose-600">⬇</div> :
              null
            }
            {/* eslint-disable-next-line no-nested-ternary */}
            <div className={`font-bold ${isNewGearScoreDifferent ? isNewGearScoreBetter ? 'text-green-500' : 'text-rose-500' : 'text-white'}`}>{totalGearScore}</div>
            <div
              className="w-3.5 h-3.5"
              style={{
                backgroundImage: 'url(\'/pngIcons/crossedSwords.png\')',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
        <div className="basis-1/3 flex flex-col items-center">
          <div>Class:</div>
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold">{beraClass.toString()}</div>
            <div
              className="w-3.5 h-3.5"
              style={{
                backgroundImage: `url(${BERA_CLASS_ICON_MAP[beraClass.toString()] ?? BERA_UNIQUE_CLASS_ICON}`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
        <div className="basis-1/3 flex flex-col items-center">
          <div>Spec:</div>
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold">{beraSpec}</div>
            <div
              className="w-3 h-3 aspect-square rounded-full"
              style={{
                backgroundColor: BERA_SPEC_COLOR_MAP[beraSpec as keyof typeof BERA_SPEC_COLOR_MAP],
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
