import React from 'react';
import { useSearchParams } from 'react-router-dom';
import MissionBriefingInfo from './MissionBriefingInfo';
import { Mission, BeraToDisplay, BerasToDisplay } from '../../../utils/additionalTypes';
import GenericItemGrid from '../../GenericItemGrid/GenericItemGrid';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import BeraCardBig from './BeraCardBig';
import useTailwindBreakpoint from '../../../utils/useTailwindBreakpoint';
import { BERA_SPECS, SEARCH_PARAMS } from '../../../utils/consts';
import GenericSerchParamsTabs from '../../GenericSerchParamsTabsWithSort';
import filterArrayBySearchParams from '../../../utils/filterArrayBySearchParams';
import sortArrayBySearchParams from '../../../utils/sortArrayBySearchParams';
import OverflowWithMaskWrapper from '../../OverflowWithMaskWrapper';
import EmptyBerasOpenseaTip from './EmptyBerasOpenseaTip';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';

export default ({
  berasToDisplay,
  selectedBeras,
  addToSelectedBeras,
  removeFromSelectedBeras,
  mission,
}: {
  berasToDisplay: BerasToDisplay
  selectedBeras: BerasToDisplay
  // eslint-disable-next-line no-unused-vars
  addToSelectedBeras: (arg: BeraToDisplay) => void
  // eslint-disable-next-line no-unused-vars
  removeFromSelectedBeras: (arg: BeraToDisplay) => void
  mission: Mission
}) => {
  const breakpoint = useTailwindBreakpoint();
  const shouldDisplayGridCutoffGradient = !breakpoint || ['xl', '2xl'].includes(breakpoint);
  const [filterSearchParams] = useSearchParams();
  const filterSearchParamsValue = filterSearchParams.get(SEARCH_PARAMS.filter);

  const filteredBerasToSort = filterArrayBySearchParams<BerasToDisplay>(berasToDisplay, {
    filterBy: ['attributes.Spec'],
  });

  const sortedBerasToSort = sortArrayBySearchParams<BerasToDisplay>(filteredBerasToSort, {
    sortBy: 'gearScore',
  });

  const availableBerasFirstToDisplay = sortedBerasToSort.sort((a, b) => {
    if (a.inMission && !b.inMission) {
      return 1;
    } if (!a.inMission && b.inMission) {
      return -1;
    }
    return 0;
  });

  const shouldDisplayEmptyBerasInfo = !availableBerasFirstToDisplay.length && !selectedBeras.filter(({ attributes }) => filterSearchParamsValue === attributes['Spec'].toLowerCase()).length;

  return (
    <GenericMainScreenContainer
      headerElement={<GenericMainScreenHeader
        tabs={[
          {
            label: 'MISSION BRIEFING',
          },
        ]}
        goBackTo={'/missions/available'}
      />}
      extraClassName="overflow-auto lg:flex-row"
    >
      <OverflowWithMaskWrapper extraClassname="overflow-auto w-full lg:flex lg:flex-row mt-6 mb-4 px-2 lg:m-0 lg:p-0">
        <div className="lg:hidden">
          <MissionBriefingInfo
            displayRewardAndStartButton
            selectedBeras={selectedBeras}
            removeFromSelectedBeras={removeFromSelectedBeras}
            mission={mission}
          />
        </div>
        <div className="flex flex-col flex-grow">
          <div className="text-3xl text-white font-bold px-2 pt-6 lg:px-8 lg:pt-8 uppercase">
            Set your team
          </div>
          <GenericSerchParamsTabs
            extraClassname="-mx-2 lg:m-0 lg:px-4 mt-2"
            tabsToDisplay={[ // TODO move these to external definition?
              ...Object.entries(BERA_SPECS).map(([key, value]) => (
                {
                  label: value,
                  searchParam: key,
                }
              )),
            ]}
            sortByTabsToDisplay={[
              {
                label: 'Gear score',
                searchParam: 'gearScore',
              },
            ]}
          />
          {shouldDisplayEmptyBerasInfo ? (
            <EmptyBerasOpenseaTip />
          ) : (
            <GenericItemGrid
              extraClassname="overflow-visible lg:overflow-auto mt-6 lg:mt-2 lg:mb-8 px-2 lg:px-8"
              aspectRatio={'1/1.5'}
              minItemSizeInRem={9.8}
              withGradientCutoff={shouldDisplayGridCutoffGradient}
              itemsToDisplay={availableBerasFirstToDisplay.map((bera) => (
                <BeraCardBig
                  key={bera.id}
                  bera={bera}
                  additionalProps={{
                    onClick: () => {
                      if (!bera.inMission) {
                        addToSelectedBeras(bera);
                      }
                    },
                  }}
                />
              ))}
            />
          )}
        </div>
        <div className="hidden lg:flex">
          <MissionBriefingInfo
            displayRewardAndStartButton
            selectedBeras={selectedBeras}
            removeFromSelectedBeras={removeFromSelectedBeras}
            mission={mission}
          />
        </div>
      </OverflowWithMaskWrapper>
    </GenericMainScreenContainer>
  );
};
