import React, { useEffect } from 'react';
import modalService from '../../modalService/ModalService';
import GenericModalLayout from './GenericModalLayout';
import confettiService from '../../utils/ConfettiService';

export default ({
  beraId,
}: {
  beraId: string
}) => {
  useEffect(() => {
    confettiService.addConfetti({
      emojis: ['🐻', '🗡', '⭐️'],
      emojiSize: 100,
      confettiNumber: 100,
    });
  }, []);

  return (
    <GenericModalLayout
      titleLabel={'New gear equipped!'}
      titleRibbonStyle={'purple'}
      onConfirmLabel={'Close'}
      onConfirm={() => modalService.closeModal()}
    >
      Beramonium #{beraId} has been successfully updated!
    </GenericModalLayout>
  );
};
