import React from 'react';
import { Link, useParams } from 'react-router-dom';
import AvailableMissionCard from './AvailableMissionCard';
import NoMissionAvailableCard from './NoMissionAvailableCard';
import { Mission } from '../../../utils/additionalTypes';
import { MISSION_TYPE } from '../../../utils/consts';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import ActiveMissionCard from './ActiveMissionCard';
import OverflowWithMaskWrapper from '../../OverflowWithMaskWrapper';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';

const tabs = {
  available: 'available',
  started: 'started',
};

const MissionBoard = ({
  availableMissions,
  activeMissions,
}: {
  availableMissions: Mission[],
  activeMissions: Mission[],
}) => {
  const { tab } = useParams();

  return (
    <GenericMainScreenContainer
      headerElement={
        <GenericMainScreenHeader
          tabs={[
            {
              label: 'Missions available',
              goTo: '/missions/available',
            },
            {
              label: 'Missions started',
              goTo: '/missions/started',
            },
          ]}
        />
     }
    >
      <OverflowWithMaskWrapper
        key={tab}
        extraClassname={'mt-7 mb-4 px-4 sm:mb-6 sm:px-6'}
      >
        {!availableMissions.length && (
        <div className="flex flex-col items-center text-center text-white text-lg mt-6 mb-12 px-2 gap-4 max-w-[400px] mx-auto">
          <div>
            {'No more missions available? Well done Bera warrior, go and get yourself some more!'}
          </div>
          <div className="flex flex-row flex-wrap items-center justify-center gap-1">
            Inspect your team at the
            <div className="flex flex-row gap-1">
              <Link
                to='/inventory/team?sortBy=gearScore.descending'
                className="font-bold text-blue-200 hover:text-blue-400 drop-shadow-inheritColorExtraSmall"
              >
                Armory
              </Link>
              screen!
            </div>
          </div>
        </div>
        )}
        <div
          className="flex flex-col gap-6 h-full"
        >
          {tab === tabs.available && (
          <>
            {Object.keys(MISSION_TYPE).map((missionType, index) => {
              const availableMissionsOfType = availableMissions.filter((mission) => mission.type === missionType);

              if (availableMissionsOfType.length) {
                return availableMissionsOfType.map((mission) => (
                  <AvailableMissionCard
                    key={mission.id}
                    mission={mission}
                  />
                ));
              }

              return <NoMissionAvailableCard
                key={`${missionType}${index}`}
                missionType={missionType as keyof typeof MISSION_TYPE}
              />;
            })}
          </>
          )}
          {tab === tabs.started && activeMissions
            // eslint-disable-next-line no-underscore-dangle
            .toSorted((a, b) => (a.startedTime._seconds + a.duration) - (b.startedTime._seconds + b.duration))
            .map((mission) => (
              <ActiveMissionCard key={mission.id} mission={mission} />
            ))}
          {tab === tabs.started && !activeMissions.length && (
            <div className="flex justify-center items-center h-full text-gray-300 font-bold text-3xl">No active missions!</div>
          )}
        </div>
      </OverflowWithMaskWrapper>
    </GenericMainScreenContainer>
  );
};

export default MissionBoard;
