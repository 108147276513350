import React from 'react';
import { MissionChallenge } from '../../utils/additionalTypes';
import {
  BERA_SPEC_COLOR_MAP,
  MISSION_CHALLENGE_FALLBACK_BORDER_COLOR,
} from '../../utils/consts';
import TooltipTrigger from '../TooltipTrigger';

export default ({
  missionChallenge,
  extraClassname,
}: {
  missionChallenge: MissionChallenge,
  extraClassname?: string
}) => {
  const {
    title, description, Image, counters,
  } = missionChallenge;

  const bgImageSrc = `/enemiesSm/${Image}.jpeg`;
  const bgImageLgSrc = `/enemiesLg/${Image}.jpeg`;
  let borderStyle;

  if (counters.length === 2) { // TODO support more challenges?
    const colorTop = BERA_SPEC_COLOR_MAP[counters[1]];
    const colorBottom = BERA_SPEC_COLOR_MAP[counters[0]];
    borderStyle = {
      borderTopColor: colorTop,
      borderRightColor: colorTop,
      borderBottomColor: colorBottom,
      borderLeftColor: colorBottom,
    };
  } else {
    borderStyle = {
      borderColor: BERA_SPEC_COLOR_MAP[counters[0]] || MISSION_CHALLENGE_FALLBACK_BORDER_COLOR,
    };
  }

  return (
    <TooltipTrigger
      content={
        <div className="flex flex-row max-w-lg items-stretch">
          <div className="flex flex-col gap-1 self-start basis-2/3 mr-4">
            <span className="font-bold">{title}</span>
            {counters.length ? (
              <div className="flex flex-row text-sm gap-1">
                <span className="whitespace-nowrap">Countered by: </span>
                {counters.map((counter, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-1"
                  >
                    <span
                      className="font-bold"
                      style={{
                        color: BERA_SPEC_COLOR_MAP[counter] || MISSION_CHALLENGE_FALLBACK_BORDER_COLOR,
                      }}
                    >{counter}</span>
                    {index !== counters.length - 1 && <span>and</span>}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-sm">
                <span>Countered by: </span>
                <span
                  className="font-bold"
                  style={{
                    color: MISSION_CHALLENGE_FALLBACK_BORDER_COLOR,
                  }}
                >All</span>
              </div>
            )}
            <span className="text-xs">{description}</span>
          </div>
          <div
            className="aspect-square basis-1/3 min-h-[8rem] -mt-2 -mr-2 -mb-2 rounded-missionChallenge"
            style={{
              backgroundImage: `url('${bgImageLgSrc}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transform: 'scale(1.1)',
            }}
          />
        </div>
      }
    >
      <div className={`
          relative border border-[0px] rounded-full drop-shadow-missionChallenge shadow-md h-12 w-12
          flex justify-center items-center
          ${extraClassname} 
        `}
      >
        <div
          style={{
            ...borderStyle,
          }}
          className="border border-[3px] rounded-full w-full h-full"
        >
          <div
            style={{
              backgroundImage: `url('${bgImageSrc}')`,
            }}
            className="border border-[1px] border-gray-700 rounded-full bg-cover w-full h-full"
          >
            {missionChallenge.isCountered && (
            <div
              className={`
                absolute -bottom-2 -left-1
                z-[1] rounded-full font-bold flex justify-center items-center p-2.5 w-4 h-4
                border-dashed border-[1px] border-transparent border-b-green-500 border-l-green-500
                text-white bg-black bg-opacity-20 backdrop-blur-md
              `}
            >
              <span className="text-sm rotate-12 text-green-500">
                ✓
              </span>
            </div>
            )}
          </div>
        </div>
      </div>
    </TooltipTrigger>
  );
};
