import React from 'react';

import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';
import NftGridItem from '../../NftGridItem';
import RafflePrizePool from './rafflePrizePool';
import OverflowWithMaskWrapper from '../../OverflowWithMaskWrapper';
import { Prize } from '../../../utils/additionalTypes';
import HorizontalDivider from '../../HorizontalDivider';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';
import LeaderboardPrizeItem from '../Leaderboard/LeaderboardPrizeItem';

const getPositionLabel = (position: number) => `${position}${{ 1: 'st', 2: 'nd', 3: 'rd' }[position] || 'th'}`;

export default () => (
  <ScaleFadeInWrapper>
    <GenericMainScreenContainer
      headerElement={<GenericMainScreenHeader
        tabs={[{
          label: 'RAFFLE',
        }]}
      />}
    >
      <OverflowWithMaskWrapper
        gradientCutoffEnabled
        withExtraPadding
      >
        <div className="p-2 py-4 pb-8">
          <div className="flex justify-center mb-2 gap-1.5 sm:gap-2">
            <div className="font-title text-2xl sm:text-3xl text-white">
              Welcome to our
            </div>
            <div className="font-title text-2xl sm:text-3xl text-white drop-shadow-inheritColorExtraSmall">
              Grand Raffle
            </div>
          </div>
          <div className="mx-auto text-center max-w-[22rem] sm:max-w-[27rem] text-sm sm:text-lg text-white">
            Buy Raffle tickets to enter the great NFT draw, that will conclude live, on-stream, at the end of Season 1!
          </div>
        </div>
        <div className="flex flex-col gap-5 pb-6 mx-4 sm:mx-8 justify-center">
          {RafflePrizePool.slice(0, 10).map((prizePoolElement, index) => {
            if (Array.isArray(prizePoolElement)) {
              return (
                <>
                  <HorizontalDivider label={getPositionLabel(index + 1)}/>
                  <div
                    key={index}
                    className="flex flex-wrap gap-4 border-1 border-white justify-center"
                  >
                    {prizePoolElement.map((prize) => (
                      <div
                        key={`${prize.name}${index}`}
                        className={'h-fit w-36'}
                      >
                        {'openSeaLink' in prize ? (
                          <NftGridItem
                            title={prize.name}
                            description={prize.description}
                            imgSrc={prize.imageLink}
                            openSeaLink={prize.openSeaLink}
                          />
                        ) : (
                          <LeaderboardPrizeItem
                            title={prize.name}
                            description={prize.description}
                            imgSrc={prize.imageLink}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </>
              );
            }
            return (
              <>
                <HorizontalDivider label={getPositionLabel(index + 1)}/>
                <div
                  key={index}
                  className="h-fit w-36 m-auto"
                >
                  {'openSeaLink' in prizePoolElement ? (
                    <NftGridItem
                      title={prizePoolElement.name}
                      description={prizePoolElement.description}
                      imgSrc={prizePoolElement.imageLink}
                      openSeaLink={prizePoolElement.openSeaLink}
                    />
                  ) : (
                    <LeaderboardPrizeItem
                      title={prizePoolElement.name}
                      description={prizePoolElement.description}
                      imgSrc={prizePoolElement.imageLink}
                    />
                  )}
                </div>
              </>
            );
          })}
          <div className="flex flex-row flex-wrap justify-center gap-4">
            {(RafflePrizePool as Array<Prize>).slice(10, RafflePrizePool.length).map((prizePoolElement, index) => (
              <div
                className="flex flex-col gap-5"
                key={index}
              >
                <HorizontalDivider label={getPositionLabel(index + 11)}/>
                <div
                  className="h-fit w-36 m-auto"
                >
                  {'openSeaLink' in prizePoolElement ? (
                    <NftGridItem
                      title={prizePoolElement.name}
                      description={prizePoolElement.description}
                      imgSrc={prizePoolElement.imageLink}
                      openSeaLink={prizePoolElement.openSeaLink}
                    />
                  ) : (
                    <LeaderboardPrizeItem
                      title={prizePoolElement.name}
                      description={prizePoolElement.description}
                      imgSrc={prizePoolElement.imageLink}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </OverflowWithMaskWrapper>
    </GenericMainScreenContainer>
  </ScaleFadeInWrapper>
);
