import React from 'react';
import GenericGridItem from './GenericItemGrid/GenericGridItem';
import { OpenSeaIconSvg } from './SvgIcons';

export default ({
  title,
  imgSrc,
  description,
  additionalTooltipContent,
  openSeaLink,
}: {
	title: string
	imgSrc: string
	description: string
	additionalTooltipContent?: React.ReactNode
	openSeaLink?: string | undefined
}) => (
  <div className="relative w-full h-full">
    {openSeaLink && (
    <div
      className="absolute z-10 bg-[#0086FF] left-0 top-0 w-6 h-6 rounded-br rounded-tl shadow-md cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        window.open(openSeaLink as string, '_blank');
      }}
    >
      <OpenSeaIconSvg />
    </div>
    )}
    <GenericGridItem
      title={title}
      tooltipContent={
        <div className="flex flex-col max-w-[12rem]">
          <span className="font-bold">{title}</span>
          <span className="text-sm">{description}</span>
          {additionalTooltipContent}
        </div>}
      imgSrc={imgSrc ?? './inventoryItems/Chest.jpeg'}
    />
  </div>
);
