import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchGemhuntersApi } from './fetcher';
import endpointUrl from './endpointUrl';
import { useAppSelector } from '../store/hooks';
import { MESSAGE_VALUES } from './consts';
import GenericNotificationModal from '../components/modals/GenericNotificationModal';
import modalService from '../modalService/ModalService';

const omitNotificationsAtPath = [
  'welcome',
  '/init',
];

type Notification = {
  data: {
    message: string
    timestamp: {
      _seconds: number,
      _nanoseconds: number
    }
  }
  id: string
}

export default () => {
  const { pathname } = useLocation();
  const [notificationsQueue, setNotificationsQueue] = useState<Array<Notification | any>>([]);

  const isSignedIn = useAppSelector((state) => state.account.isSignedIn);
  const walletAddress = useAppSelector((state) => state.account.walletAddress);

  const shouldDisplayNotificationsAtPath = !omitNotificationsAtPath.includes(pathname);

  useEffect(() => {
    (async () => {
      if (isSignedIn) {
        try {
          const notificationsResponse = await fetchGemhuntersApi(`${endpointUrl}/api/notifications`, {
            method: 'GET',
          }) as { message: ValueOf<typeof MESSAGE_VALUES>, notifications: Array<Notification> };

          if (notificationsResponse.message === MESSAGE_VALUES.success) {
            setNotificationsQueue([...notificationsResponse.notifications]);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('notifications fetch failed');
        }
      }
    })();
  }, [isSignedIn]);

  useEffect(() => {
    if (notificationsQueue.length && shouldDisplayNotificationsAtPath) {
      modalService.pushModal(<GenericNotificationModal
        message={notificationsQueue[0].data.message}
      />, {
        onClose: () => {
          notificationsQueue.shift();

          if (!notificationsQueue.length) {
            fetchGemhuntersApi(`${endpointUrl}/api/notifications/clear`, {
              method: 'POST',
              body: JSON.stringify({ walletAddress }),
            });
          }

          setNotificationsQueue([...notificationsQueue]);
        },
      });
    }
  }, [notificationsQueue, shouldDisplayNotificationsAtPath]);
};
