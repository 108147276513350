import { useAppSelector } from '../store/hooks';
import { MISSION_PURCHASE_PRICES, MISSION_TYPE } from './consts';

export default ({
  missionType,
}: {
  missionType: keyof typeof MISSION_TYPE,
}) => {
  const activeMissions = useAppSelector((state) => state.missions.activeMissions);
  const missionsTimers = useAppSelector((state) => state.missions.missionsTimers);

  const missionTimer = missionsTimers && missionsTimers[missionType];

  const missionsStartedAfterTimer = missionTimer ? (activeMissions ?? []).filter(
    // eslint-disable-next-line no-underscore-dangle
    (mission) => mission.type === missionType && mission.startedTime._seconds > missionTimer?.start._seconds,
  ).length : 0;

  if (MISSION_PURCHASE_PRICES[missionType].length <= missionsStartedAfterTimer) {
    return MISSION_PURCHASE_PRICES[missionType].slice(-1)[0];
  }

  // We asserted that value can't be undefined above
  return MISSION_PURCHASE_PRICES[missionType][missionsStartedAfterTimer]!;
};
