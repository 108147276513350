import JSConfetti from 'js-confetti';

const ConfettiService = () => {
  const jsConfetti = new JSConfetti();

  return {
    addConfetti: ({
      emojis,
      emojiSize,
      confettiNumber,
    }: {
      emojis: Array<string>
      emojiSize: number
      confettiNumber: number
    }) => {
      jsConfetti.addConfetti({
        emojis,
        emojiSize,
        confettiNumber,
      });
    },
  };
};

const confettiService = ConfettiService();

export default confettiService;
